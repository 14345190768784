<template>
  <v-dialog
    v-model="activo"
    :width="$vuetify.breakpoint.sm ? '700' : 'auto'"
    :persistent="load"
    scrollable
  >
    <v-card
      flat
      class="pt-1 pb-2"
    >
      <v-card-title
        :class="$vuetify.breakpoint.sm ? 'd-flex justify-center' : ''"
        class="pb-1 pt-2"
      >
        <v-icon small color="info" class="ma-2">fas fa-search</v-icon> Buscar Cliente
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Contenido -->
      <v-card-text class="pt-5">
        <!-- Filtros -->
        <v-row  class="pb-0">
          <v-col cols="12" sm="5" md="5" class="py-1">
            Nombre
            <v-text-field
              v-model="clienteNombre"
              hide-details
              outlined
              dense
              type="text"
              @keypress.enter="buscarCliente()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Nro. Documento
            <v-text-field
              v-model="clienteDni"
              hide-details
              outlined
              dense
              type="number"
              @keypress.enter="buscarCliente()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="mt-2" align="end">
            <v-btn title="Buscar" color="info" @click="buscarCliente()" :loading="load"><v-icon class="ma-2" small>fas fa-search</v-icon> Buscar</v-btn>
          </v-col>
        </v-row>
        <!-- Listado de Clientes Disponibles-->
        <v-row class="mt-0">
          <v-col cols="12">
            <v-data-table
              sort-by="vendedor_codigo"
              class="cebra elevation-2 mt-2"
              :headers="[{ text: 'Código', align: 'center', value: 'vendedor_codigo' },
                        { text: 'Nombre', align: 'center', value: 'vendedor_nombre' },
                        { text: 'Numero Documento', align: 'center', value: ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? 'numero_documento' : 'documento_numero'},
                        { text: 'Teléfono', align: 'center', value: ruta == 'mayoristaCobranzas/obtenerClientesMayoristas' ? 'telefono' : 'vendedor_telefono' },
                        { text: 'Acciones', align: 'center', value: 'acciones' },]"
              :items="clientes"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="3">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  max-width="400"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin clientes para mostrar.
                </v-alert>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="success" @click="seleccionarCliente(item)" v-on="on">
                      <v-icon class="mr-2" small>fas fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar Cliente</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearBuscarClienteM', value)
      }
    },
    ejecutivo_codigo: {
      get() {
        return this.datos.ejecutivo_codigo
      },
    },
    ruta:{
      get(){
        return this.datos.ruta;
      }
    }
  },
  data() {
    return {
      clienteNombre: '',
      clienteDni: '',
      load: false,
      headers: [
        { text: 'Código', align: 'center', value: 'vendedor_codigo' },
        { text: 'Nombre', align: 'center', value: 'vendedor_nombre' },
        { text: 'Numero Documento', align: 'center', value: 'documento_numero'},
        { text: 'Teléfono', align: 'center', value: 'vendedor_telefono' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      clientes: [],
      search: ''
    }
  },
  methods: {
    async buscarCliente(){
      if ((this.clienteNombre != null && this.clienteNombre != undefined && this.clienteNombre.toString().length > 0) ||
      (this.clienteDni != null && this.clienteDni != undefined && this.clienteDni.toString().length > 0)){
        this.clientes = []
        this.search = ''
        let nombre = ''
        if (this.clienteNombre != null && this.clienteNombre != undefined && this.clienteNombre.toString().length > 0) nombre = this.clienteNombre.toString()
        let documento = 0
        if (this.clienteDni != null && this.clienteDni != undefined && this.clienteDni.toString().length > 0) documento = this.clienteDni.toString()
        this.load = true
        let clientesPeticion = await this.$store.dispatch(this.ruta, {ejecutivo_codigo: this.ejecutivo_codigo, codigo: 0, nombre: nombre, nroDocumento: documento})
        this.load = false
        if (clientesPeticion.resultado == 1){
          this.clientes = clientesPeticion.clientes
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientesPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese algún filtro para iniciar la búsqueda.',
          color: 'info',
        })
      }
    },
    seleccionarCliente(item){
      this.$emit('setearCliente', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.clienteNombre = ''
        this.clienteDni = ''
        this.clientes = []
        this.search = ''
      }
    }
  },
}
</script>

<style>

</style>