<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <!-- Titulo del Formulario -->
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        Cambio Directo
        <v-spacer></v-spacer>
      </v-col>
      <!-- Contenido -->
      <v-col cols="12" class="pt-0">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Datos de la Venta -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                  :loading="load"
                >
                  <v-card-title>
                    Datos de la Venta
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-form @submit.prevent="buscar()">
                      <!-- Venta Id, Imei, Fecha y Con Falla -->
                      <v-row :no-gutters="$vuetify.breakpoint.xs">
                        <v-col cols="12" sm="2" md="2" class="py-0">
                          <v-text-field
                            v-model="ventaId"
                            outlined
                            dense
                            type="number"
                            hide-details
                            label="Venta Id"
                            :readonly="lockVenta"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0" v-if="imeis.length <= 1">
                          <v-text-field
                            v-model="imei"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Imei Buscado"
                            :readonly="lockVenta"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0" v-if="imeis.length > 1">
                          <v-autocomplete
                            v-model="selectedImei"
                            item-text="serie"
                            :items="imeis"
                            hide-details
                            outlined
                            dense
                            return-object
                            clearable
                            :disabled="imeis.length == 0 || imeis.length == 1"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" class="py-0">
                          <v-text-field
                            v-model="fecha"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Fecha"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="2" md="2" class="py-0">
                          <v-checkbox
                            v-model="conFalla"
                            label="Con Falla"
                            dense
                            hide-details
                            :true-value="1"
                            :false-value="0"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <!-- Sucursal y Pto. de Vta. -->
                      <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-3">
                        <v-col cols="12" sm="6" md="6" class="py-0">
                          <v-text-field
                            v-model="sucursal"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Sucursal"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0">
                          <v-text-field
                            v-model="ptoVta"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Punto de Venta"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Documento, Apellido, Nombre y Teléfono del Cliente -->
                      <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-3">
                        <v-col cols="12" sm="3" md="3" class="py-0">
                          <v-text-field
                            v-model="clienteDni"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Cliente Documento"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" class="py-0">
                          <v-text-field
                            v-model="clienteApeNom"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Cliente Apellido y Nombre"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3" class="py-0">
                          <v-text-field
                            v-model="clienteTelefono"
                            outlined
                            dense
                            type="text"
                            hide-details
                            label="Cliente Teléfono"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <BtnFiltro
                            :loading="load"
                            @clear="limpiar()"
                            clase="mt-0"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- Imei a Reemplazar -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12">
                <v-card
                  elevation="2"
                  outlined
                >
                  <v-card-title>
                    Imei a Reemplazar
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text class="pt-1">
                    <!-- Nuevo Imei y botón Reemplazar -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs">
                      <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-text-field
                          v-model="nuevoImei"
                          outlined
                          dense
                          type="text"
                          hide-details
                          label="Nuevo Imei"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" class="py-0">
                        <v-btn
                          color="success" 
                          @click="confirmar()" 
                          v-if="lockVenta == true && nuevoImei != null && nuevoImei != undefined && nuevoImei.toString().length > 0"
                          :disabled="lockBoton"
                        >
                          <v-icon class="ma-2">fas fa-exchange-alt</v-icon> Reemplazar
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Motivo de Reemplazo -->
                    <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
                      <v-col cols="12" class="py-0">
                        <v-textarea
                          v-model="observacion"
                          hide-details
                          auto-grow
                          outlined
                          filled
                          dense
                          label="Motivo del Cambio"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="dialogConf"
      :texto="msjConfirmaReemplazo"
      @action="reemplazar()"
      @setearModalConfAccion="setModalAsociar"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue';
import ModalConfirmar from '../../components/util/ModalConfirmar.vue';
import moment from 'moment';
import download from 'downloadjs';
import { facturaA4, facturaTermica } from '../../util/plantillas/pdfs';
export default {
  data() {
    return {
      ventaId: '',
      imei: '',
      selectedImei: {},
      imeis: [],
      fecha: '',
      conFalla: 1,
      sucursal: '',
      ptoVta: '',
      clienteDni: '',
      clienteApeNom: '',
      clienteTelefono: '',
      load: false,
      nuevoImei: '',
      observacion: '',
      lockVenta: false,
      msjConfirmaReemplazo: '',
      dialogConf: false,
      datosReemplazo: {},
      lockBoton: false,
      impresora_termica: 0
    }
  },
  components: {
    BtnFiltro,
    ModalConfirmar
  },
  methods: {
    async buscar(){
      if (Object.keys(this.selectedImei).length == 0 && (this.imei == null || this.imei == undefined || this.imei.toString().length == 0) &&
      (this.ventaId == null || this.ventaId == undefined || this.ventaId.toString().length == 0)){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese la serie o el id de la venta para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.load = true
      // venta id
      let venta_id = 0
      if (this.ventaId != null && this.ventaId != undefined && this.ventaId.toString().length > 0) venta_id = this.ventaId
      // imei buscado
      let imei_bus = ''
      if (Object.keys(this.selectedImei).length > 0) imei_bus = this.selectedImei.serie
      if (imei_bus.toString().length == 0 && this.imei != null && this.imei != undefined && this.imei.toString().length > 0) imei_bus = this.imei
      let datosPeticion = await this.$store.dispatch('cambioDirecto/buscarVenta', {
        venta_id: venta_id,
        imei_bus: imei_bus
      })
      this.load = false
      if (datosPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: datosPeticion.msj,
          color: 'error',
        })
        return
      }
      // llegaron los datos que necesito?
      if (datosPeticion.venta.length == 0 || datosPeticion.series.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron los datos de la venta solicitada.',
          color: 'error',
        })
        return
      }
      // paso a completar los datos
      this.ventaId = datosPeticion.venta[0].id
      this.fecha = moment(datosPeticion.venta[0].fecha).format("DD/MM/YYYY")
      this.sucursal = datosPeticion.venta[0].sucursal
      this.ptoVta = datosPeticion.venta[0].local
      this.clienteDni = datosPeticion.venta[0].cuit
      this.clienteApeNom = datosPeticion.venta[0].cliente
      this.clienteTelefono = datosPeticion.venta[0].cliente_telefono
      this.impresora_termica = datosPeticion.venta[0].impresora_termica
      // tengo varias series?
      this.imeis = datosPeticion.series
      if (this.imeis.length > 1 && this.imei != null && this.imei != undefined && this.imei.toString().length > 0){
        let existe = this.imeis.filter(element => element.serie.toString().trim().toUpperCase() == this.imei.toString().trim().toUpperCase())
        if (existe.length > 0) this.selectedImei = existe[0]
        else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar el Imei a intercambiar.',
            color: 'error',
          })
          return
        }
      }
      // tengo una sola serie?
      if (this.imeis.length == 1){
        this.selectedImei = this.imeis[0]
        this.imei = this.imeis[0].serie
      }
      this.lockVenta = true
    },
    limpiar(){
      this.ventaId = ''
      this.imei = ''
      this.selectedImei = {}
      this.imeis = []
      this.fecha = ''
      this.conFalla = 0
      this.sucursal = ''
      this.ptoVta = ''
      this.clienteDni = ''
      this.clienteApeNom = ''
      this.clienteTelefono = ''
      this.load = false
      this.nuevoImei = ''
      this.observacion = ''
      this.lockVenta = false
      this.msjConfirmaReemplazo = ''
    },
    confirmar(){
      if (this.ventaId == null || this.ventaId == undefined || this.ventaId.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El Id de la Venta no está definido. No se puede proceder.',
          color: 'info',
        })
        return
      }
      // obtengo el imei original de la venta
      let imeiVenta = ''
      if (this.imeis.length > 1 && Object.keys(this.selectedImei).length > 0) imeiVenta = this.selectedImei.serie
      if (this.imeis.length <= 1 && this.imei != null && this.imei != undefined && this.imei.toString().length > 0) imeiVenta = this.imei
      let imeiNuevo = ''
      if (this.nuevoImei != null && this.nuevoImei != undefined && this.nuevoImei.toString().length > 0) imeiNuevo = this.nuevoImei
      if (imeiVenta.toString().length == 0 || imeiNuevo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe debe ingresar el imei original de la venta y el nuevo imei para proceder con el reemplazo.',
          color: 'info',
        })
        return
      }
      // armo el msj de confirmación
      this.msjConfirmaReemplazo = `Está reemplazando el imei ${imeiVenta} de la venta ${this.ventaId} por el imei ${imeiNuevo}. ¿Confirma esta acción?`
      if (this.conFalla == 1) this.msjConfirmaReemplazo = `Al indicar que el cambio es con falla estará generando una nueva Venta. ` + this.msjConfirmaReemplazo
      this.dialogConf = true
    },
    async reemplazar(){
      this.datosReemplazo = {}
      if (this.observacion == null || this.observacion == undefined || this.observacion.toString().length == 0 || this.nuevoImei == null || this.nuevoImei == undefined || this.nuevoImei.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el Imei a reemplazar y el Motivo de Cambio para poder crear el reemplazo.',
          color: 'info',
        })
        return
      }
      // obtengo el imei original de la venta
      let imeiVenta = ''
      if (this.imeis.length > 1 && Object.keys(this.selectedImei).length > 0) imeiVenta = this.selectedImei.serie
      if (this.imeis.length <= 1 && this.imei != null && this.imei != undefined && this.imei.toString().length > 0) imeiVenta = this.imei
      let imeiNuevo = this.nuevoImei
      if (imeiVenta.toString().length == 0 || imeiNuevo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe debe ingresar el imei original de la venta y el nuevo imei para proceder con el reemplazo 2.',
          color: 'info',
        })
        return
      }
      // paso a hacer el reemplazo
      this.$store.state.loading = true
      let reemplazoPeticion = await this.$store.dispatch('cambioDirecto/reemplazarImei', {
        venta_id: this.ventaId,
        imei_venta: imeiVenta,
        imei_reemplazar: imeiNuevo,
        motivo: this.observacion,
        falla: this.conFalla
      })
      if (reemplazoPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: reemplazoPeticion.msj,
          color: 'error',
        })
        return
      }
      // reemplazo correcto, paso a facturar? para que no reintente el reemplazo bloqueo el botón
      this.lockBoton = true
      this.datosReemplazo = reemplazoPeticion
      if (parseInt(this.datosReemplazo.id_comp_caja_nc) > 0 && parseInt(this.datosReemplazo.id_comp_caja_fc) > 0) this.getNotaCredito()
      else{
        // exito
        this.$store.dispatch('show_snackbar', {
          text: 'El reemplazo se realizó exitosamente.',
          color: 'success',
        })
        // obtengo los datos de mi factura original
        let fcPdf = await this.$store.dispatch('caja/datos_factura', {
          comp_id: this.datosReemplazo.id_caja_vta_original
        })
        if (fcPdf.exito == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: fcPdf.message,
            color: 'error',
          })
          setTimeout(() => [location.reload()], 3000)
          return
        }
        let pdfBuffer
        // aqui deberia poner la ruta para mostrar la factura de la misma venta con la nueva serie
        if (this.impresora_termica == 0) pdfBuffer = await facturaA4(fcPdf.datos)
        else pdfBuffer = await facturaTermica(fcPdf.datos)
        this.$store.state.loading = false
        download(pdfBuffer, `${fcPdf.datos.encabezado.referencia}-${fcPdf.datos.encabezado.emynum}.pdf`,'application/pdf')
        // refresco la pagina
        setTimeout(() => [location.reload()], 3000)
      }
    },
    setModalAsociar(value){
      this.dialogConf = value
    },
    async getNotaCredito(){
      // paso a generar la nota de crédito
      let ncPeticion = await this.$store.dispatch('afip/getNotaCredito', {
        comprobante_caja_id: this.datosReemplazo.id_comp_caja_nc
      })
      if (ncPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: ncPeticion.msj,
          color: 'error',
        })
        setTimeout(() => [location.reload()], 3000)
        return
      }
      // aqui deberia ir la ruta para imprimir la NC, tenemos que indicar si es termica o A4
      let ncPdf = await this.$store.dispatch('caja/datos_factura', {
        comp_id: this.datosReemplazo.id_comp_caja_nc
      })
      if (ncPdf.exito == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: ncPdf.message,
          color: 'error',
        })
        setTimeout(() => [location.reload()], 3000)
        return
      }
      let pdfBuffer
      if (this.impresora_termica == 0) pdfBuffer = await facturaA4(ncPdf.datos)
      else pdfBuffer = await facturaTermica(ncPdf.datos)
      download(pdfBuffer, `${ncPdf.datos.encabezado.referencia}-${ncPdf.datos.encabezado.emynum}.pdf`,'application/pdf')
      // pasamos a generar la factura
      this.getFactura()
    },
    async getFactura(){
      let fcPeticion = await this.$store.dispatch('afip/getFactura', {
        comprobante_caja_id: this.datosReemplazo.id_comp_caja_fc
      })
      if (fcPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: fcPeticion.msj,
          color: 'error',
        })
        setTimeout(() => [location.reload()], 3000)
        return
      }
      // aqui deberia ir la ruta para imprimir la FC, tenemos que indicar si es termica o A4
      let fcPdf = await this.$store.dispatch('caja/datos_factura', {
        comp_id: this.datosReemplazo.id_comp_caja_fc
      })
      if (fcPdf.exito == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: fcPdf.message,
          color: 'error',
        })
        setTimeout(() => [location.reload()], 3000)
        return
      }
      let pdfBuffer
      if (this.impresora_termica == 0) pdfBuffer = await facturaA4(fcPdf.datos)
      else pdfBuffer = await facturaTermica(fcPdf.datos)
      download(pdfBuffer, `${fcPdf.datos.encabezado.referencia}-${fcPdf.datos.encabezado.emynum}.pdf`,'application/pdf')
      // exito
      this.$store.state.loading = false
      this.$store.dispatch('show_snackbar', {
        text: 'El reemplazo se realizó exitosamente.',
        color: 'success',
      })
      setTimeout(() => [location.reload()], 3000)
    }
  },
  watch: {
    selectedImei: function(){
      if (this.selectedImei == null || this.selectedImei == undefined) this.selectedImei = {}
    }
  },
}
</script>

<style>

</style>