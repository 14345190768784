<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="70%"
      persistent
    >
      <v-card
        flat
        :loading="loadCard"
      >
        <!-- Titulo -->
        <v-card-title class="pb-0">
          Proveedor {{ proveedor_nombre }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <!-- Detalle de Pagos y Resumen de Pagos-->
        <v-card-text>
          <!-- Tarjetas para Detalles de Pagos -->
          <v-card
            elevation="4"
            v-for="(fact, index) in listadoFacturas" 
            :key="index"
            class="my-3"
          >
            <v-card-text class="pt-0">
              <!-- Titulo de la Tarjeta - Indica Datos de la Factura -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="my-1">
                <v-col cols="12" class="py-0">
                  <strong><p style="font-size: 20px">{{ fact.titulo }}</p></strong>
                </v-col>
              </v-row>
              <!-- Agregar un Pago -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                  <FechaPicker
                    v-model="fact.fechaPago"
                    ref="fechaPagoEstimada"
                    @blur="validaFechaPago(fact)"
                    label="Fecha Pago"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                  <v-text-field
                    v-model="fact.porcentajePago"
                    outlined
                    dense
                    type="number"
                    prefix="%"
                    @blur="validaPorcentaje(fact)"
                    label="Porcentaje"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="pt-0 pb-0">
                  <v-text-field
                    v-model="fact.importePago"
                    outlined
                    dense
                    type="number"
                    prefix="$"
                    @blur="validaImportePago(fact)"
                    label="Importe"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="fact.selectedFP"
                    item-text="forma_pago_nombre"
                    :items="formasPago"
                    hide-details
                    outlined
                    dense
                    return-object
                    label="Forma de Pago"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="pt-0">
                  <v-btn color="primary" title="Agregar Pago" @click="addPago(fact)">Agregar</v-btn>
                </v-col>
              </v-row>
              <!-- Listado de Pagos -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" class="py-0">
                  <v-data-table
                    class="cebra elevation-2 mt-2"
                    :headers="fact.headers"
                    :items="fact.pagos"
                    :loading="fact.load"
                    dense
                    :search="fact.search"
                    item-key="indice"
                    hide-default-footer
                  >
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        Sin Pagos ingresados.
                      </v-alert>
                    </template>
                    <!-- Total de Pago -->
                    <template v-slot:[`item.pago_total`]="{ item }">
                      {{ item.moneda + ' ' + Intl.NumberFormat("de-DE").format(item.pago_total) }}
                    </template>
                    <!-- Porcentaje -->
                    <template v-slot:[`item.porcentaje_pago`]="{ item }">
                      {{ item.porcentaje_pago }}%
                    </template>
                    <!-- Acciones -->
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="error" @click="quitarPago(item)" v-on="on">
                            <v-icon small>far fa-times-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Quitar Pago</span>
                      </v-tooltip>
                    </template>
                    <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                    <!-- <template
                      v-for="header in fact.headers.filter((header) => header.hasOwnProperty('formatter'))"
                      v-slot:[`item.${header.value}`]="{ value }"
                    >
                      {{ header.formatter(value) }}
                    </template> -->
                    <!-- ultima row para el total -->
                    <template slot="body.append">
                      <tr class="font-weight-bold">
                        <th></th>
                        <th class="text-center">{{ fact.porcentaje_acumulado }}%</th>
                        <th></th>
                        <th class="text-center">${{ Intl.NumberFormat("de-DE").format(fact.total_acumulado) }}</th>
                        <th></th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Tarjeta para el Resumen de Pagos -->
          <v-card
            elevation="4"
            class="my-3"
          >
            <v-card-text>
              <!-- Titulo, Orden de Pago, Total de Orden de Pago y botón para Subir Archivos -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="my-1">
                <v-col cols="3" class="py-0">
                  <strong><p style="font-size: 20px">Resumen de Pagos</p></strong>
                </v-col>
                <v-col cols="3" class="py-0">
                  <!-- 02-06-2023 por MMURILLO, agregamos la Orden de Pago (OP) -->
                  <v-text-field
                    v-model="ordenPago"
                    :id="ordPago"
                    :ref="ordPago"
                    outlined
                    dense
                    type="number"
                    label="Orden de Pago"
                    hide-details
                    style="max-width: 150px;"
                    @blur="setOP()"
                    @keypress.native.enter="$refs[ordPago].blur()"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0">
                  <v-text-field
                    v-model="totalOrdenPago"
                    outlined
                    dense
                    type="text"
                    label="Total OP"
                    hide-details
                    readonly
                    prefix="$"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0">
                  <!-- 02-06-2023 por MMURILLO, permitimos subir archivos para la Orden de Pago -->
                  <v-btn small color="success" @click="subirArchivos()"><v-icon small class="ma-2">fas fa-plus</v-icon> Subir Archivos</v-btn>
                </v-col>
              </v-row>
              <!-- Observación -->
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="observacion"
                    outlined
                    dense
                    type="text"
                    label="Observación"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="dialogSubirArchivos.archivos.length > 0">
                <v-subheader class="pr-1"><strong>{{ dialogSubirArchivos.archivos.length }} archivo{{ dialogSubirArchivos.archivos.length == 1 ? '' : 's' }} adjunto{{ dialogSubirArchivos.archivos.length == 1 ? '' : 's' }}:</strong></v-subheader>
                <v-subheader class="pl-0">{{ dialogSubirArchivos.archivos.map(a => a.name).join(', ') }}</v-subheader>
              </v-row>
              <!-- Listado con el Resumen de Pagos -->
              <v-row :no-gutters="$vuetify.breakpoint.xs" class="my-1">
                <v-col cols="12">
                  <v-data-table
                    class="cebra elevation-2 mt-2"
                    :headers="headers"
                    :items="resumenes"
                    :loading="load"
                    dense
                    :search="search"
                    hide-default-footer
                  >
                    <!-- Msj que se mostrara si no existen resultados -->
                    <template v-slot:no-data>
                      <v-alert
                        class="mx-auto mt-4"
                        max-width="400"
                        type="warning"
                        border="left"
                        dense
                        text
                      >
                        Sin Resumen para mostrar.
                      </v-alert>
                    </template>
                    <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                    <template
                      v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                      v-slot:[`item.${header.value}`]="{ value }"
                    >
                      {{ header.formatter(value) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="error" class="mr-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar
            :texto="dialogSubirArchivos.archivos.length == 0 ? 'NO SE DETECTARON ARCHIVOS PARA SUBIR. ¿Está seguro de ingresar los pagos para las Facturas seleccionadas?' : '¿Está seguro de ingresar los pagos para las Facturas seleccionadas?'"
            @action="pagarMasivo()"
            color="success"
            nombre="Pagar"
            icono="fas fa-money-check-alt"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal subir archivos -->
    <AdjuntarArchivos 
      :value="dialogSubirArchivos.activo"
      :archivos.sync="dialogSubirArchivos.archivos"
      @input="setDialogArchivos"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import FechaPicker from '../util/FechaPicker.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { format_money, roundNumber, getBase64 } from '../../util/utils'
import moment from 'moment'
import AdjuntarArchivos from '../incidentes/AdjuntarArchivos.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    FechaPicker,
    BtnConfirmar,
    AdjuntarArchivos
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearIngPagoFCComp', value)
      }
    },
    proveedor_codigo: {
      get(){
        return this.datos.proveedor_codigo
      }
    },
    proveedor_nombre: {
      get(){
        return this.datos.proveedor_nombre
      }
    },
    facturas: {
      get(){
        return this.datos.facturas
      }
    }
  },
  data() {
    return {
      listadoFacturas: [],
      formasPago: [],
      loadCard: false,
      headers: [
        { text: 'Forma de Pago', align: 'center', value: 'forma_pago_nombre' },
        { text: 'Total', align: 'center', value: 'total', formatter: format_money }
      ],
      resumenes: [],
      load: false,
      search: '',
      ordenPago: '',
      ordPago: 'ordPago',
      totalOrdenPago: 0,
      observacion: '',
      dialogSubirArchivos: {
        activo: false,
        archivos: []
      }
    }
  },
  methods: {
    async initForm(){
      this.totalOrdenPago = 0
      // obtengo las formas de pago reales
      this.loadCard = true
      let fpsPeticion = await this.$store.dispatch('facturasCompra/get_fp_compras_pago')
      this.loadCard = false
      if (fpsPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: fpsPeticion.msj,
          color: 'error',
        })
        return
      }
      // asigno las formas de pago
      this.formasPago = fpsPeticion.formas_pago
      this.llenarResumen(fpsPeticion.formas_pago)
      // tengo que repetir facturas.length veces el módulo de agregar pago, paso a armar mi propio array con toda la info que necesito
      for (let id = 0; id <= this.facturas.length - 1; id++){
        let unaFactura = {
          headers: [
            { text: 'Fecha', align: 'center', value: 'fecha_pago' },
            { text: 'Porcentaje', align: 'center', value: 'porcentaje_pago' },
            { text: 'Forma de Pago', align: 'center', value: 'forma_pago' },
            { text: 'Orden de Pago', align: 'center', value: 'orden_pago' },
            { text: 'Monto', align: 'center', value: 'pago_total' },
            { text: 'Acciones', align: 'center', value: 'acciones' }
          ],
          pagos: [],
          load: false,
          search: '',
          titulo: this.facturas[id].comprobante + ' - ' + this.facturas[id].abreviatura + ' ' + Intl.NumberFormat("de-DE").format(this.facturas[id].total).toString(),
          fechaPago: moment(new Date()).format('DD/MM/YYYY'),
          porcentajePago: '',
          importePago: '',
          selectedFP: {},
          factura_id: this.facturas[id].factura_id,
          porcentaje_acumulado: 0,
          total_acumulado: 0,
          total: this.facturas[id].total,
          signo: this.facturas[id].signo,
          abreviatura: this.facturas[id].abreviatura
        }
        this.listadoFacturas.push(unaFactura)
        // 02-06-2023 por MMURILLO, acumulo el total de la Orden de Pago
        this.totalOrdenPago = roundNumber(this.totalOrdenPago, 2) + roundNumber(this.facturas[id].total, 2)
      }
      this.totalOrdenPago = roundNumber(this.totalOrdenPago, 2)
      // tengo pagos guardados en localStorage?
      if (localStorage.getItem('pagosFCCompra')){
        // obtengo los dato guardados
        let datosLS = JSON.parse(localStorage.getItem('pagosFCCompra'))
        // comparo los proveedores
        if (datosLS.proveedor_codigo != this.proveedor_codigo) localStorage.removeItem('pagosFCCompra')
        else{
          // 02-06-2023 por MMURILLO, guardamos la orden de pago
          if (datosLS.hasOwnProperty('orden_pago')) this.ordenPago = datosLS.orden_pago
          // cargo los pagos en el modal
          for (let id in this.listadoFacturas){
            // tengo pagos para la factura que estoy recorriendo?
            let pagosLS = datosLS.pagos.filter(element => element.factura_id == this.listadoFacturas[id].factura_id)
            if (pagosLS.length > 0){
              // encontre pagos relacionados a la factura, paso a asignarlos
              for (let jd in pagosLS){
                this.listadoFacturas[id].pagos.push(pagosLS[jd])
              }
            }
          }
          // termino de asignar pagos y paso a indexar
          this.indexarPagos()
          // paso a calcular los subtotales
          this.calcularSubTotales()
        }
      }
    },
    limpiarForm(){
      this.listadoFacturas = []
      this.formasPago = []
      this.loadCard = false
      this.resumenes = []
      this.search = ''
      this.totalOrdenPago = 0
      this.ordenPago = ''
      this.observacion = ''
      this.dialogSubirArchivos.activo = false
      this.dialogSubirArchivos.archivos = []
    },
    llenarResumen(fps){
      for (let id in fps){
        let unFp = {
          forma_pago_id: fps[id].forma_pago_id,
          forma_pago_nombre: fps[id].forma_pago_nombre,
          total: 0
        }
        this.resumenes.push(unFp)
      }
    },
    validaFechaPago(item){
      // obtengo la posicion
      let pos = this.listadoFacturas.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento seleccionado.',
          color: 'warning',
        })
        return
      }
      let det = item
      if (item.fechaPago == '' || item.fechaPago == null || item.fechaPago == undefined){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una fecha válida para poder proseguir.',
          color: 'error',
        })
        det.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        // lo guardo en el listado
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
    },
    validaPorcentaje(item){
      // obtengo la posicion
      let pos = this.listadoFacturas.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento seleccionado.',
          color: 'warning',
        })
        return
      }
      let det = item
      // es vacio el porcentaje?
      if(item.porcentajePago == null || item.porcentajePago == undefined || item.porcentajePago == 0 || item.porcentajePago.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un porcentaje válido para poder proseguir.',
          color: 'error',
        })
        det.importePago = ''
        det.porcentajePago = ''
        // lo guardo en el listado
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
      det.porcentajePago = roundNumber(det.porcentajePago, 2)
      // es negativo o mayor a 100?
      if (det.porcentajePago < 0 || det.porcentajePago > 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje no válido. Debe ser un número entre 1 y 100.',
          color: 'error',
        })
        det.porcentajePago = ''
        det.importePago = ''
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
      let porcentajeFinalAcumulado = roundNumber(det.porcentaje_acumulado, 4)
      let totalPorcentaje = roundNumber(det.porcentajePago, 4) + roundNumber(porcentajeFinalAcumulado, 4)
      // la suma del porcentaje que estoy agregando y el porcentaje total acumulado hasta el momento supera el 100%?
      if (totalPorcentaje > 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje no válido. Excede el total de la Factura.',
          color: 'error',
        })
        det.porcentajePago = ''
        det.importePago = ''
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
      // calculo los totales del detalle
      let porcentaje = roundNumber(roundNumber(det.porcentajePago, 4)/100, 4)
      det.importePago = parseInt(item.signo) * roundNumber(porcentaje * roundNumber(det.total, 2), 2)
      // llegan al 100% del pago?
      if (parseInt(totalPorcentaje) == 100){
        // controlo la suma de los importes
        let sumaTotall = 0
        for (let id in det.pagos){
          sumaTotall = sumaTotall + roundNumber(det.pagos[id].pago_total, 2)
        }
        // le agrego el nuevo importe pago calculado a una nueva variable sumaTotal
        let sumaTotal = sumaTotall + roundNumber(det.importePago, 2)
        // el total calculado sobrepasa al total de la factura?
        if (roundNumber(sumaTotal, 2) > roundNumber(det.total, 2)){
          det.importePago = roundNumber(roundNumber(det.total, 2) - roundNumber(sumaTotall, 2), 2)
        }
      }
      // paso a asignar el importe
      Object.assign(this.listadoFacturas[pos], det)
    },
    validaImportePago(item){
      // obtengo la posicion
      let pos = this.listadoFacturas.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento seleccionado.',
          color: 'warning',
        })
        return
      }
      let det = item
      if (det.importePago == null || det.importePago == undefined || det.importePago == 0 || det.importePago.toString().length == 0 || 
      (parseInt(item.signo) * roundNumber(det.importePago, 2) < 0) || roundNumber(det.importePago, 2) > roundNumber(det.total, 2)){
        this.$store.dispatch('show_snackbar', {
          text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
          color: 'error',
        })
        det.porcentajePago = ''
        det.importePago = ''
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
      let porcentaje = roundNumber(parseInt(item.signo)*(roundNumber(det.importePago, 2)*100)/roundNumber(det.total, 2), 2)
      det.porcentajePago = porcentaje
      // asigno
      Object.assign(this.listadoFacturas[pos], det)
    },
    validarForm(){
      // existen elementos donde no hayan ingresado pagos?
      let sinPagos = this.listadoFacturas.filter(element => element.pagos.length == 0)
      if (sinPagos.length > 0) return {valido: false, msj: 'Existen Facturas que no se ingresaron los pagos. Verificar.'}
      // llegaron al porcentaje esperado?
      let totalesIncorrectos = this.listadoFacturas.filter(element => Math.abs(parseInt(element.porcentaje_acumulado)) != 100)
      if (totalesIncorrectos.length > 0) return {valido: false, msj: 'Existen Facturas donde no se completó el 100% de los Pagos. Verificar.'}
      // el importe acumulado coincide con el total de cada detalle?
      // 02-06-2023 por MMURILLO, controlo que todos los detalles de pago tengan asociada la orden de pago
      let diferencia = 0
      let cont = 0
      for (let id in this.listadoFacturas){
        if (Math.abs(roundNumber(this.listadoFacturas[id].total_acumulado, 2)) != Math.abs(roundNumber(this.listadoFacturas[id].total, 2))) diferencia = 1
        let opCero = this.listadoFacturas[id].pagos.filter(element => element.orden_pago == null || element.orden_pago == undefined || element.orden_pago == 0)
        if (opCero.length > 0) cont = cont + 1
      }
      if (diferencia == 1) return {valido: false, msj: 'Existen Facturas donde el total acumulado de pagos no coincide con el total de la Factura. Verificar.'}
      if (parseInt(cont) > 0) return {valido: false, msj: 'Existen Pagos que no se asociaron a la Orden de Pago. Verificar que todos los detalles de pago tengan asociada la Orden de Pago.'}
      // controlo la orden de pago
      if (this.ordenPago == null || this.ordenPago == undefined || this.ordenPago == 0 || this.ordenPago.toString().length == 0) return {valido: false, msj: 'Debe ingresar una Orden de Pago válida. Verificar.'}
      // todo OK
      return {valido: true, msj: 'OK'}
    },
    async pagarMasivo(){
      let valido = this.validarForm()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let guardarPeticion = await this.$store.dispatch('facturasCompra/guardarPagosFinales', {
        facturas: this.listadoFacturas, 
        orden_pago: this.ordenPago, 
        total_op: this.totalOrdenPago,
        observacion: this.observacion,
        proveedor_codigo: this.proveedor_codigo
      })
      this.$store.state.loading = false
      if (guardarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Se ingresó el pago de las Facturas seleccionadas con éxito.',
          color: 'success',
        })
        // borro de localStorage en caso que hayan quedado pagos guardados
        if (localStorage.getItem('pagosFCCompra')) localStorage.removeItem('pagosFCCompra')
        // 02-06-2023 por MMURILLO, controlo las imagenes que están subiendo
        if (this.dialogSubirArchivos.archivos.length > 0){
          // debo pasar a subir los archivos
          this.enviarArchivosOP(guardarPeticion.orden_pago_id)
          return
        }
        // emito la finalización
        this.$emit('setExitoPagos')
        // cierro el modal
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: guardarPeticion.msj,
          color: 'warning',
        })
      }
    },
    addPago(item){
      // obtengo la posicion
      let pos = this.listadoFacturas.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento seleccionado.',
          color: 'warning',
        })
        return
      }
      let det = item
      // control de valores vacios
      if(det.fechaPago == null || det.fechaPago == undefined || det.fechaPago.toString().length == 0 || 
      det.porcentajePago == null || det.porcentajePago == undefined || det.porcentajePago.toString().length == 0 || 
      det.importePago == null || det.importePago == undefined || det.importePago.toString().length == 0 || Object.keys(det.selectedFP).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar todos los campos de pago para poder agregar.',
          color: 'warning',
        })
        return
      }
      // control de la sumatoria de importes del detalle de pagos y del nuevo importe
      let sumaTotal = 0
      for (let id in det.pagos){
        sumaTotal = roundNumber(sumaTotal, 2) + roundNumber(det.pagos[id].pago_total, 2)
      }
      sumaTotal = roundNumber(sumaTotal, 2) + roundNumber(det.importePago, 2)
      if (roundNumber(sumaTotal, 2) > roundNumber(det.total, 2)){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede agregar ya que la suma de los importes de Pago sobrepasan al Total de la Factura.',
          color: 'error',
        })
        det.porcentajePago = ''
        det.importePago = ''
        Object.assign(this.listadoFacturas[pos], det)
        return
      }
      // 02-06-2023 por MMURILLO, controlo si tengo la orden de pago
      let ordPago = 0
      if (this.ordenPago != null && this.ordenPago != undefined && this.ordenPago.toString().length > 0) ordPago = this.ordenPago
      // paso a agregar
      let unPago = {
        fecha_pago: det.fechaPago,
        porcentaje_pago: roundNumber(det.porcentajePago, 2),
        forma_pago: det.selectedFP.forma_pago_nombre,
        forma_pago_id: det.selectedFP.forma_pago_id,
        pago_total: roundNumber(det.importePago, 2),
        id: 0,
        factura_id: det.factura_id,
        orden_pago: ordPago,
        moneda: this.listadoFacturas[pos].abreviatura
      }
      det.pagos.push(unPago)
      // paso a indexar los pagos
      this.indexarPagos()
      // paso a calcular los subtotales
      this.calcularSubTotales()
      // inicializo el resto de los campos
      det.fechaPago = moment(new Date()).format('DD/MM/YYYY')
      det.porcentajePago = ''
      det.importePago = ''
      det.selectedFP = {}
      Object.assign(this.listadoFacturas[pos], det)
      // guardo en localStorage
      this.guardarLocalStorage()
    },
    indexarPagos(){
      // recorro todas las facturas a pagar
      for (let id in this.listadoFacturas){
        // por cada una de ellas, paso a recorrer los pagos ingresados
        for (let jd in this.listadoFacturas[id].pagos){
          let unPago = this.listadoFacturas[id].pagos[jd]
          // asigno el indice del detalle
          Object.defineProperty(unPago, 'indice', {
            value: parseInt(jd),
            writable: true,
            enumerable: true,
            configurable: true
          })
          // asigno el detalle
          Object.assign(this.listadoFacturas[id].pagos[jd], unPago)
        }
      }
    },
    calcularSubTotales(){
      // calculo los totales para cada una de las facturas
      for (let id in this.listadoFacturas){
        // por cada elemento dentro del listado, calculo el total de importe y de porcentaje
        let totalImporte = 0
        let totalPorcentaje = 0
        for (let jd in this.listadoFacturas[id].pagos){
          totalImporte = totalImporte + roundNumber(this.listadoFacturas[id].pagos[jd].pago_total, 2)
          totalPorcentaje = totalPorcentaje + roundNumber(this.listadoFacturas[id].pagos[jd].porcentaje_pago, 2)
        }
        // paso a actualizar los totales acumulados
        this.listadoFacturas[id].total_acumulado = roundNumber(totalImporte, 2)
        this.listadoFacturas[id].porcentaje_acumulado = roundNumber(totalPorcentaje, 2)
      }
      // ahora completo los totales para el resumen de pagos
      for (let id in this.resumenes){
        let det = this.resumenes[id]
        // por cada tipo de forma de pago, acumulo todos los pagos de ese tipo en cada factura
        let totalTipo = 0
        for (let jd in this.listadoFacturas){
          let mismoTipo = this.listadoFacturas[jd].pagos.filter(element => element.forma_pago_id == det.forma_pago_id)
          if (mismoTipo.length > 0){
            for (let kd in mismoTipo){
              totalTipo = totalTipo + roundNumber(mismoTipo[kd].pago_total, 2)
            }
          }
        }
        // guardo el total acumulado
        det.total = totalTipo
        // lo asigno al resumen
        Object.assign(this.resumenes[id], det)
      }
    },
    quitarPago(item){
      let unItem = this.listadoFacturas.filter(element => element.factura_id == item.factura_id)
      if (unItem.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo encontrar el detalle a quitar dentro del listado de pagos.',
          color: 'error',
        })
        return
      }
      // encuentro la posicion del elemento
      let pos = this.listadoFacturas.indexOf(unItem[0])
      // ahora encuentro la posicion del elemento a quitar
      let posItem = this.listadoFacturas[pos].pagos.indexOf(item)
      if (posItem == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la posición del elemento a quitar.',
          color: 'error',
        })
        return
      }
      // quito el elemento
      this.listadoFacturas[pos].pagos.splice(posItem, 1)
      // paso a indexar los pagos
      this.indexarPagos()
      // paso a recalcular totales
      this.calcularSubTotales()
      // guardo en localStorage
      this.guardarLocalStorage()
    },
    guardarLocalStorage(){
      // quito la clave en caso de existir
      if (localStorage.getItem('pagosFCCompra')) localStorage.removeItem('pagosFCCompra')
      // obtengo todos los pagos de las facturas de compra
      let pagosLS = []
      for (let id in this.listadoFacturas){
        // recorro cada pago
        for (let jd in this.listadoFacturas[id].pagos){
          pagosLS.push(this.listadoFacturas[id].pagos[jd])
        }
      }
      // armo el objeto
      let unObj = {
        proveedor_codigo: this.proveedor_codigo,
        pagos: pagosLS,
        orden_pago: this.ordenPago
      }
      // lo guardo en localStorage
      localStorage.setItem('pagosFCCompra', JSON.stringify(unObj))
    },
    setOP(){
      if (this.listadoFacturas.length > 0 && parseInt(this.ordenPago) > 0){
        for (let id in this.listadoFacturas){
          if (this.listadoFacturas[id].pagos.length > 0){
            for (let jd in this.listadoFacturas[id].pagos){
              this.listadoFacturas[id].pagos[jd].orden_pago = this.ordenPago
            }
          }
        }
        this.guardarLocalStorage()
      }
    },
    subirArchivos(){
      this.dialogSubirArchivos.activo = true
    },
    setDialogArchivos(value){
      this.dialogSubirArchivos.activo = value
    },
    async enviarArchivosOP(orden_pago_id){
      // mando a subir los archivos para la orden de pago y para las facturas que se asociaron, paso los archivos a base 64
      let archivosFinal = []
      for (const file of this.dialogSubirArchivos.archivos) {
        await getBase64(file).then(data => {
          archivosFinal.push({
            file: data.split(',')[1],
            name: file.name
          })
        })
      }
      this.$store.state.loading = true
      let subirPeticion = await this.$store.dispatch('facturasCompra/uploadFilesOP', {
        orden_pago_id: orden_pago_id,
        archivos: archivosFinal
      })
      this.$store.state.loading = false
      if (subirPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: subirPeticion.msj,
          color: 'error',
        })
      }
      // emito la finalización
      this.$emit('setExitoPagos')
      // cierro el modal
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>