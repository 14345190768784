<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-form>
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" md="6">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" md="5" class="py-1">
                    Periodo
                    <FechaPickerMes
                      :fecha.sync="periodo"
                      :clearable="false"
                      @change="get_objetivos(); get_vendedores()"
                    />
                  </v-col>
                  <v-col cols="12" md="7" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="sucursal"
                      item-text="nombre"
                      item-value="id"
                      tabindex="1"
                      :items="$store.state.sucursales"
                      hide-details
                      autofocus
                      outlined
                      dense
                      @change="get_locales()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="7" class="py-1">
                    Punto de venta
                    <v-autocomplete
                      v-model="local"
                      item-text="nombre"
                      item-value="id"
                      tabindex="1"
                      :items="locales"
                      hide-details
                      outlined
                      dense
                      @change="get_objetivos()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5" class="py-1">
                    Familia de objetivos
                    <v-autocomplete
                      v-model="familia"
                      item-text="nombre"
                      item-value="id"
                      tabindex="1"
                      :items="familias"
                      hide-details
                      outlined
                      dense
                      @change="get_objetivos(); get_locales()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      class="cebra"
                      :headers="headers"
                      :items="obj_ptovta"
                      hide-default-footer
                      dense
                    >
                      <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                      <template
                        v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                        v-slot:[`item.${header.value}`]="{ value }"
                      >
                        {{ header.formatter(value) }}
                      </template>
                      <template v-slot:no-data>
                        <v-alert
                          class="mx-auto mt-4"
                          max-width="400"
                          type="warning"
                          border="left"
                          dense
                          text
                        >
                          No existen objetivos para el punto de venta seleccionado
                        </v-alert>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="9">
      <v-row :no-gutters="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12" md="5">
          <v-card :disabled="local == null || !permitir_modif">
            <v-card-title class="px-0 py-1" style="font-size: 18px">
              <v-btn
                icon
                @click="select_all(vendedores_sin_asignar)"
              >
                <v-icon>
                  {{ 
                    selected.find(item => vendedores_sin_asignar.find(i => i.codigo === item.codigo))
                    ? (selected.length === vendedores_sin_asignar.length ? 'fas fa-check-square' : 'fas fa-minus-square')
                    : 'far fa-square'
                  }}
                  </v-icon>
              </v-btn>
              Vendedores sin asignar
            </v-card-title>
            <v-divider></v-divider>
            <v-card
              height="250px"
              style="overflow-y: auto;"
              flat
            >
              <template>
                <v-card-text class="pa-0" style="height: 100%">
                  <drop-list
                    :items="vendedores_sin_asignar"
                    class="list"
                    style="height: 100%"
                    mode="cut"
                    @insert="onInsert($event, 'vendedores_sin_asignar')"
                    @reorder="$event.apply(vendedores_sin_asignar)"
                  >
                    <template v-slot:item="{item}">
                      <drag
                        :key="item.codigo"
                        class="item"
                        :class="{ 'selected' : selected.indexOf(item) > -1 }"
                        :data="selection(item)"
                        go-back
                        @click="toggleSelected(vendedores_sin_asignar, item)"
                        @cut="remove(vendedores_sin_asignar, item)"
                      >
                        {{  item.codigo  }} - {{item.nombre}}
                      </drag>
                    </template>
                    <template v-slot:feedback="{data}">
                      <template v-if="selected.length > 0">
                        <div v-for="f in data" :key="f.nombre" class="item feedback">{{f.nombre}}</div>
                      </template>
                      <template v-else>
                        <div :key="data.nombre" class="item feedback">{{data.nombre}}</div>
                      </template>
                    </template>
                  </drop-list>
                </v-card-text>
              </template>
            </v-card>
          </v-card>
        </v-col>
        <v-col
          cols="12" md="1"
          class="d-flex"
          :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'justify-center'"
        >
          <v-row class="d-flex justify-center text-center" :no-gutters="$vuetify.breakpoint.mdAndUp">
            <v-col cols="2" md="12">
              <v-btn
                color="primary"
                title="Agregrar todos"
                class="mb-md-2"
                :disabled="local == null || !permitir_modif"
                small
                @click="mover_all(vendedores_asignados, vendedores_sin_asignar)"
              >
                <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" md="12">
              <v-btn
                color="primary"
                title="Agregrar seleccionado/s"
                class="mb-md-6"
                :disabled="local == null || !permitir_modif"
                small
                @click="mover(vendedores_asignados, vendedores_sin_asignar)"
              >
                <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" md="12">
              <v-btn
                color="primary"
                title="Quitar seleccionado/s"
                class="mb-md-2"
                :disabled="local == null || !permitir_modif"
                small
                @click="mover(vendedores_sin_asignar, vendedores_asignados)"
              >
                <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="2" md="12">
              <v-btn
                color="primary"
                title="Quitar todos"
                class="mb-md-4"
                :disabled="local == null || !permitir_modif"
                small
                @click="mover_all(vendedores_sin_asignar, vendedores_asignados)"
              >
                <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-card :disabled="local == null || !permitir_modif">
            <v-card-title class="px-2" style="font-size: 18px; padding: 6px 0px">
              Vendedores asignados
              <v-spacer></v-spacer>
              <span class="body-2 pr-7">
                Dedic. hor
              </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card
              height="250px"
              style="overflow-y: auto;"
              flat
            >
              <template>
                <v-card-text class="pa-0" style="height: 100%">
                  <drop-list
                    :items="vendedores_asignados"
                    class="list"
                    style="height: 100%"
                    mode="cut"
                    @insert="onInsert($event, 'vendedores_asignados')"
                    @reorder="$event.apply(vendedores_asignados)"
                  >
                    <template v-slot:item="{item}">
                      <drag
                        :key="item.nombre"
                        class="item"
                        :class="{ 'selected' : selected.indexOf(item) > -1 }"
                        :data="selection(item)"
                        go-back
                        @cut="remove(vendedores_asignados, item)"
                        @click="toggleSelected(vendedores_asignados, item, 1)"
                      >
                        {{  item.codigo  }} - {{item.nombre}}
                        <v-spacer></v-spacer>
                        {{ item.objetivo }}
                        <v-icon
                          :title="item.title ? item.title : 'Estado: Pendiente'"
                          :color="item.color"
                          class="mb-1 ml-3"
                          small
                        >
                          {{ item.icono ? item.icono : 'far fa-clock' }}
                        </v-icon>
                      </drag>
                    </template>
                    <template v-slot:feedback="{data}">
                      <template v-if="selected.length > 0">
                        <div v-for="f in data" :key="f.nombre" class="item feedback">{{f.nombre}}</div>
                      </template>
                      <template v-else>
                        <div :key="data.nombre" class="item feedback">{{data.nombre}}</div>
                      </template>
                    </template>
                  </drop-list>
                </v-card-text>
              </template>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3">
      <v-data-table
        class="cebra pt-md-3"
        :headers="headers_detalle"
        :items="detalle"
        hide-default-footer
        mobile-breakpoint
        dense
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers_detalle.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:no-data>
          <div class="py-2">
            Seleccione un vendedor asignado para ver sus objetivos
          </div>
        </template>
      </v-data-table>
      <div class="d-flex justify-end mt-8">
        <BtnConfirmar
          :disabled="bloquear || obj_ptovta.length == 0 || (vendedores_asignados[0] ? (vendedores_asignados[0].color == 'success' ? true : false) : false)"
          @action="guardar"
        />
      </div>  
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import { Drag, DropList } from "vue-easy-dnd"
import { get_periodo, get_last_periodo, format_money, format_2_decimales } from '../../util/utils'
import FechaPickerMes from '../../components/util/FechaPickerMes'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      meses_modif: 2,
      permitir_modif: false,
      bloquear: false,
      periodo: get_periodo(),
      sucursal: null,
      local: null,
      familia: 3,
      locales: [],
      familias: [],
      selected: [],
      selectedList: 0,
      vendedores_sin_asignar: [],
      vendedores_asignados: [],
      obj_ptovta: [],
      headers: [
        { text: 'Tipo', value: 'tipo_nom', divider: true, sortable: false },
        { text: 'Cant', value: 'cant', align: 'end', sortable: false, formatter: format_money },
        { text: 'Cant Vend', value: 'cant_vend', align: 'end', sortable: false, formatter: format_2_decimales },
        { text: 'Q x Vend', value: 'q_vend', align: 'end', sortable: false, formatter: format_money },
      ],
      detalle: [],
      headers_detalle: [
        { text: 'Tipo', value: 'tipo', sortable: false },
        { text: 'Objetivo', value: 'objetivo', align: 'end', sortable: false, formatter: format_money },
      ],
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    await this.get_data()
    this.$store.state.loading = false
    if (this.$store.state.sucursales.length === 1) {
      this.sucursal = this.$store.state.sucursales[0].id
      this.get_locales()
    }
  },
  computed: {
    ...mapState('vendedores', ['locales_user'])
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
    Drag,
    DropList,
  },
  watch: {
    // observador que se activa cuando cambia la cantidad de items en vendedores asignados
    'vendedores_asignados.length': {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          // esta condicion sirve para no recalcular la primera vez que trae los vendedores que ya se encuentran cargados en la base
          if (!(oldVal == 0 && this.vendedores_asignados[0].color)) {
            // si cambio la cantidad de vendedores debe recalcular todo y poner los iconos en "pendiente"
            this.recalcular_objetivos_ptovta()
          }
        }
      },
      deep: true
    },
    // observador para limpiar el detalle del objetivo del vendedor cada vez que hace click sobre un vendedor sin asignar
    selected (val) {
      if (val.length > 0) {
        if (!val[0].icono) {
          this.detalle = []
        }
      }
    },
  },
  methods: {
    async guardar () {
      // valida que todos los vendedores tengan dedicacion horaria mayor que 0
      for (let index = 0; index < this.vendedores_asignados.length; index++) {
        const vend = this.vendedores_asignados[index]
        if (vend.objetivo <= 0) {
          this.$store.dispatch('show_snackbar', {
            text: 'No puede asignar un vendedor sin dedicación horaria',
            color: 'warning',
          })
          return
        }
      }

      // elimina los objetivos anteriores de los vendedores del ptovta en el periodo seleccionado
      this.$store.state.loading = true
      await this.$store.dispatch('objetivos/eliminar_obj_vend', {
        periodo: this.periodo,
        local: this.local,
      })
        .then(() => {
          // elimino correctamente los objetivos de los vendedores
          
        })
        .catch(error => {
          this.$swal.fire({
            icon: 'warning',
            title: 'No se pudo asignar los objetivos a los vendedores',
            text: error.message
          })
          this.$store.state.loading = false
          return
        })

      // guarda uno por uno todos los vendedores que esten en vendedores asignados
      let errores = 0
      this.bloquear = true
      this.$store.state.loading = true

      for (let index = 0; index < this.vendedores_asignados.length; index++) {
        let vend = this.vendedores_asignados[index]

        await this.$store.dispatch('objetivos/nuevo_obj_vend', {
          periodo: this.periodo,
          vendedor: vend.codigo,
          local: this.local,
          dedic: vend.objetivo,
          familia: this.familia,
        })
          .then(() => {
            // setea la row con un icono de exito
            vend.icono = 'fas fa-check'
            vend.color = 'success'
            vend.title = 'Estado: Asignado'
          })
          .catch(error => {
            // setea la row con una advertencia y el mensaje de error
            vend.icono = 'fas fa-exclamation-triangle'
            vend.color = 'warning'
            vend.title = error.message
            errores += 1
          })
        
      }
      this.selected = []
      this.$store.state.loading = false

      // mensaje personalizado
      let icono = 'success'
      let mensaje = `Objetivos asignados correctamente <p style="font-size: 22px; margin-top: 12px">
                    Se cargaron ${this.vendedores_asignados.length} objetivos para ${this.locales.find(loc => loc.id == this.local).nombre}</p>`
      if (errores > 0) {
        icono = 'warning'
        mensaje = `Carga de objetivos finalizada, revise el estado de cada vendedor
                  <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctos: ${this.vendedores_asignados.length - errores} <br/> Con error: ${errores}</p>`
      }
      this.$swal.fire({
        icon: icono,
        title: mensaje
      })

    },
    async get_detalle_vendedor (vendedor) {/*
      if (vendedor.color == 'success') {
        // si el color del icono es verde significa que lo trajo de la base y por lo tanto busca los valores ahi
        this.$store.state.loading = true
        this.detalle = []
        await this.$store.dispatch('objetivos/get_detalle_obj_vend', vendedor.id)
          .then(res => {
            this.detalle = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false

        // ordena el array detalles por tipo
        this.detalle.sort(function(a, b) {
          if(a.tipo < b.tipo) { return -1 }
          if(a.tipo > b.tipo) { return 1 }
          return 0
        })
        
      } else {*/
        // caso contrario significa que se mofidicaron los vendedores asignados y debe recalcular los valores
        this.detalle = []
        for (let index = 0; index < this.obj_ptovta.length; index++) {
          const objetivo_local = this.obj_ptovta[index]
          
          // recalcula el objetivo del vendedor en base a al objetivo 'Q x Vend' del local y la dedic. horaria del vendedor
          this.detalle.push({
            tipo: objetivo_local.tipo_nom,
            objetivo: parseFloat(objetivo_local.q_vend * vendedor.objetivo).toFixed(2)
          })
        }

      //}
    },
    recalcular_objetivos_ptovta () {
      this.bloquear = false
      this.detalle = []
      let cant_vend = 0

      // obtengo la nueva cantidad de vendedores y cambio el icono a "pendiente"
      for (let index = 0; index < this.vendedores_asignados.length; index++) {
        let vendedor = this.vendedores_asignados[index]
        vendedor.icono = 'far fa-clock'
        vendedor.color = ''
        vendedor.title = 'Estado: Pendiente' 
        cant_vend += vendedor.objetivo
      }
      cant_vend = parseFloat(cant_vend).toFixed(2)

      // recalculo los objetivos en base a la cantidad de nuevos vendedores
      for (let index = 0; index < this.obj_ptovta.length; index++) {
        let ptovta = this.obj_ptovta[index]
        ptovta.cant_vend = cant_vend
        ptovta.q_vend = parseFloat(ptovta.cant / cant_vend).toFixed(2)
      }

    },
    async get_objetivos () {
      this.bloquear = false

      // verifica si el periodo es menor o mayor que el del los meses permitidos para modificar
      this.permitir_modif = moment(this.periodo).isAfter(get_last_periodo(this.meses_modif + 1))

      // obtiene los vendedores asignadoas al local
      if (this.periodo && this.local) {
        this.$store.state.loading = true
        this.vendedores_asignados = []
        this.selected = []
        await this.$store.dispatch('objetivos/get_vend_local', {
          periodo: this.periodo,
          local: this.local,
        })
          .then(res => {
            res.data.forEach(item => {
              let vendedor = item
              vendedor.icono = 'fas fa-check'
              vendedor.color = 'success'
              vendedor.title = 'Estado: Asignado'
              this.vendedores_asignados.push(vendedor)
            })
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }

      // obtiene los objetivos del local
      if (this.periodo && this.sucursal && this.local && this.familia) {
        this.$store.state.loading = true
        this.obj_ptovta = []
        await this.$store.dispatch('objetivos/get_obj_x_local', {
          periodo: this.periodo,
          familia: this.familia,
          sucursal: this.sucursal,
          local: this.local,
        })
          .then(res => {
            this.obj_ptovta = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false

        // ordena el array obj_ptovta por tipo
        this.obj_ptovta.sort(function(a, b) {
          if(a.tipo_nom < b.tipo_nom) { return -1 }
          if(a.tipo_nom > b.tipo_nom) { return 1 }
          return 0
        })
      }
    },
    async get_locales () {
      // obtiene los locales de la sucursal x familia
      const familia = this.familias.find(fam => fam.id == this.familia)
      let categoria = null
      if (familia) categoria = familia.categoria
      this.locales = this.locales_user.filter(local => local.sucursal === this.sucursal && local.empresa === 2 && local.categoria == categoria)
      this.local = null
      this.obj_ptovta = []
      this.vendedores_asignados = []
      this.detalle = []
      await this.get_vendedores()
    },
    async get_vendedores () {
      this.vendedores_sin_asignar = []
      // obtiene los vendedores sin asignar de la sucursal
      if (this.periodo && this.sucursal) {
        this.$store.state.loading = true
        this.vendedores_sin_asignar = []
        this.selected = []
        await this.$store.dispatch('objetivos/get_vend_sin_asignar', {
          periodo: this.periodo,
          sucursal: this.sucursal,
          familia: this.familia
        })
          .then(res => {
            this.vendedores_sin_asignar = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }
    },
    async get_data () {
      this.$store.state.loading = true
      this.familias = []
      await this.$store.dispatch('objetivos/get_familias')
        .then(res => {
          this.familias = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('objetivos/get_meses')
        .then(res => {
          this.meses_modif = parseInt(res)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async mover (lista_agregar, lista_sacar) {
      await this.selected.forEach(async (item) => {
        if (lista_sacar.find(i => i.codigo === item.codigo)) {
          const index = lista_sacar.indexOf(item)
          await lista_sacar.splice(index, 1)
          await lista_agregar.push(item)
        }
      })
      this.selected = []
    },
    async mover_all (lista_agregar, lista_sacar) {
      await lista_sacar.forEach(async (item) => {
        item.icono = ''
        item.color = ''
        item.title = ''
        await lista_agregar.push(item)
      })
      await lista_sacar.splice(0, lista_sacar.length)
      this.selected = []
    },
    select_all (items) {
      if (this.selected.length === 0) {
        items.forEach(item => {
          this.toggleSelected(items, item)
        })
      } else {
        this.selected = []
      }
    },
    // metodos del dnd
    selection(item) {
      return this.selected.length > 0 ? this.selected : item
    },
    onInsert(event, listName = "vendedores_sin_asignar") {
      if (event.data.length > 0) {
        event.data.forEach((e, idx) => {
          if (!this[listName].find(item => item.codigo === e.codigo)) {
            this[listName].splice(event.index + idx, 0, e)
          }
        })
      } else {
        if (!this[listName].find(item => item.codigo === event.data.codigo)) {
          this[listName].splice(event.index, 0, event.data)
        }
      }
      this.selected = []
    },
    remove(array, value) {
      if (this.selected.length > 0) {
        this.selected.forEach(e => {
          if (array.find(item => item.codigo === e.codigo)) {
            let index = array.indexOf(e)
            array[index].icono = ''
            array[index].color = ''
            array[index].title = ''
            array.splice(index, 1)
          }
        })
      } else {
        let index = array.indexOf(value)
        array[index].icono = ''
        array[index].color = ''
        array[index].title = ''
        array.splice(index, 1)
      }
    },
    toggleSelected(listName, item, soloUno) {
      // esta condicion limita a que solo seleccione uno en vendedores asignados
      if (soloUno) {
        // busca el detalle del vendedor seleccionado
        this.get_detalle_vendedor(item)
        
        // si ya hay in vendedor seleccionado, lo borra y selecciona el nuevo
        if (this.selected.length == 1 && this.selected[this.selected.indexOf(item)] != item) {
          this.selected = []
          this.toggleSelected(listName, item)
          return
        }
        // si el seleccionado es el mismo que ya estaba seleccionado, limpia el detalle
        else if (this.selected[this.selected.indexOf(item)] == item) {
          this.detalle = []
        }
      }
      if (listName !== this.selectedList) {
        this.selected = []
        this.selectedList = listName
      }
      const index = this.selected.indexOf(item)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    }
  }
}
</script>

<style>
.list {
  padding: 1px;
}
.item {
  padding: 2px 6px;
  margin: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.selected {
  background-color:  var(--v-primary-base);
  color: #ffff;
}
.feedback {
  border: 1px dashed var(--v-primary-base);
}
.drag-image {
  background-color: rgb(220, 255, 220);
  transform: translate(-50%, -50%);
}
</style>