<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nueva Devolución"
              @click="nuevaDevolucion()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <!-- Sucursal y pto de vta -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="setLocales()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Pto de Venta
                    <v-autocomplete
                      v-model="selectedPtoVta"
                      item-text="pto_vta_nombre"
                      :items="ptosVta"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="getVendedores()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Cliente y Estados -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Estados
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Cliente Código
                    <v-row class="mt-0">
                      <v-text-field
                        v-model="clienteCodigo"
                        outlined
                        dense
                        type="number"
                        @blur="getCliente()"
                      ></v-text-field>
                      <v-btn icon small title="Buscar Cliente" @click="buscarCliente()" class="ml-2"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Cliente Nombre
                    <v-text-field
                      v-model="clienteNombre"
                      outlined
                      dense
                      type="text"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Fecha y Botones -->
                <v-row :no-gutters="$vuetify.breakpoint.xs" style="margin-top: -20px">
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Devoluciones -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="devoluciones"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="verDevolucion(item)" v-on="on">
                  <v-icon small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_codigo == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="editarDevolucion(item)" v-on="on">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.estado_codigo == 1 && tienePermiso == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="aceptarDevolucion(item)" v-on="on">
                  <v-icon small>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>Aceptar Remito</span>
            </v-tooltip>
            <v-tooltip bottom v-if="(item.estado_codigo == 2 || item.estado_codigo == 3) && tienePermisoAudit == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="accent" @click="auditar(item)" v-on="on">
                  <v-icon small>fas fa-check-double</v-icon>
                </v-btn>
              </template>
              <span>Auditar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Buscar Clientes Mayoristas -->
    <BuscarClienteMayorista 
      :datos="dialogClientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setBuscarClienteM"
    />
    <!-- Modal Ver -->
    <ModalVerDevolucion 
      :datos="dialogVerDevolucion"
      @setearVerDevolucion="setVerDevolucion"
    />
    <!-- Modal Auditar Devolución -->
    <ModalAuditarDev 
      :datos="dialogAuditar"
      @setearModalAuditar="setModalAuditar"
    />
  </div>
</template>

<script>
import FechaPickerRango from '../../../components/util/FechaPickerRango.vue'
import BtnFiltro from '../../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../../components/util/SearchDataTable.vue'
import BuscarClienteMayorista from '../../../components/generales/clientes/BuscarClienteMayorista.vue'
import ModalVerDevolucion from '../../../components/generales/remitos-devolucion/ModalVerDevolucion.vue'
import ModalAuditarDev from '../../../components/generales/remitos-devolucion/ModalAuditarDev.vue'
import moment from 'moment'
export default {
  data() {
    return {
      panel: 0,
      selectedSucursal: {},
      sucursales: [],
      ptosVta: [],
      ptosVtaAux: [],
      selectedPtoVta: {},
      estados: [],
      selectedEstado: {},
      fechaRango: [null, null],
      limpiarFecha: false,
      clienteCodigo: '',
      clienteNombre: '',
      load: false,
      headers: [
        { text: 'Código', align: 'center', value: 'devolucion_codigo' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Remito', align: 'center', value: 'remito' },
        { text: 'Referencia', align: 'center', value: 'referencia' },
        { text: 'Cliente', align: 'center', value: 'cliente' },
        { text: 'Bodega Destino', align: 'center', value: 'deposito_nombre' },
        { text: 'Usuario Grabación', align: 'center', value: 'usuario_grabacion' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      devoluciones: [],
      search: '',
      vendedores: [],
      dialogClientes: {
        activo: false,
        ejecutivo_codigo: '',
        ruta: 'clientes/getClienteMayorista'
      },
      dialogVerDevolucion: {
        activo: false,
        encabezado: {}
      },
      tienePermiso: 0,
      tienePermisoAudit: 0,
      dialogAuditar: {
        activo: false,
        devolucion_codigo: ''
      },
      ejecutivoCodigo: 0
    }
  },
  components: {
    FechaPickerRango,
    BtnFiltro,
    SearchDataTable,
    BuscarClienteMayorista,
    ModalVerDevolucion,
    ModalAuditarDev
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('remitosDevolucion/initIndexForm')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.ptosVtaAux = initPeticion.ptosVta
        this.estados = initPeticion.estados
        this.tienePermiso = initPeticion.permisoAcept
        this.tienePermisoAudit = initPeticion.permisoAudit
        if (initPeticion.ejecutivo.length > 0) this.ejecutivoCodigo = initPeticion.ejecutivo[0].ejecutivo_codigo
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscar(){
      this.load = true
      this.devoluciones = []
      // sucursal
      let sucursal = 0
      if (Object.keys(this.selectedSucursal).length > 0) sucursal = this.selectedSucursal.id
      // pto vta
      let local = 0
      if (Object.keys(this.selectedPtoVta).length > 0) local = this.selectedPtoVta.pto_vta_codigo
      // estado
      let estado = 0
      if (Object.keys(this.selectedEstado).length > 0) estado = this.selectedEstado.estado_codigo
      let cliente = 0
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0) cliente = this.clienteCodigo
      // fechas
      let fechaDesde = moment(this.fechaRango[0]).format("DD/MM/YYYY")
      let fechaHasta = ''
      if (this.fechaRango[1] != null && this.fechaRango[1] != undefined && this.fechaRango[1].toString().length > 0) fechaHasta = moment(this.fechaRango[1]).format("DD/MM/YYYY")
      let datos = {
        sucursal: sucursal,
        ptoVta: local,
        estado: estado,
        cliente: cliente,
        fecha_desde: fechaDesde,
        fecha_hasta: fechaHasta,
        codigo: 0
      }
      let devolucionesPeticion = await this.$store.dispatch('remitosDevolucion/getRemitosDevolucion', datos)
      this.load = false
      if (devolucionesPeticion.resultado == 1){
        this.devoluciones = devolucionesPeticion.devoluciones
      }else{
        this.$store.dispatch('show_snackbar', {
          text: devolucionesPeticion.msj,
          color: 'error',
        })
      }
    },
    setLocales(){
      this.ptosVta = []
      if (Object.keys(this.selectedSucursal).length > 0){
        this.ptosVta = this.ptosVtaAux.filter(element => element.sucursal_codigo == this.selectedSucursal.id)
      }
    },
    buscarCliente(){
      // asigno el ejecutivo
      this.dialogClientes.ejecutivo_codigo = this.ejecutivoCodigo
      // abro el modal
      this.dialogClientes.activo = true
    },
    async getVendedores(){
      this.vendedores = []
      if (Object.keys(this.selectedPtoVta).length > 0){
        this.$store.state.loading = true
        let vendedoresPeticion = await this.$store.dispatch('remitosDevolucion/getVendedoresMayoristasXPtoVta', {pto_vta: this.selectedPtoVta.pto_vta_codigo})
        this.$store.state.loading = false
        if (vendedoresPeticion.resultado == 1){
          this.vendedores = vendedoresPeticion.vendedores
        }else{
          this.$store.dispatch('show_snackbar', {
            text: vendedoresPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    async getCliente(){
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0){
        this.$store.state.loading = true
        let codigo = 0
        if (this.clienteCodigo.toString().length > 0) codigo = this.clienteCodigo
        let clientePeticion = await this.$store.dispatch('clientes/getClienteMayorista', {ejecutivo_codigo: this.ejecutivoCodigo, codigo: codigo, nombre: '', nroDocumento: '0'})
        this.$store.state.loading = false
        if (clientePeticion.resultado == 1){
          // encontre el cliente?
          if (clientePeticion.clientes.length > 0){
            if (clientePeticion.clientes.length == 1){
              this.clienteNombre = clientePeticion.clientes[0].vendedor_nombre
            }else{
              // abro el modal
              this.dialogClientes.ejecutivo_codigo = this.ejecutivoCodigo
              this.dialogClientes.activo = true
            }
          }else{
            // abro el modal para buscar al cliente
            this.dialogClientes.ejecutivo_codigo = this.ejecutivoCodigo
            this.dialogClientes.activo = true
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientePeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.clienteCodigo = ''
        this.clienteNombre = ''
      }
    },
    limpiar(){
      this.selectedSucursal = {}
      this.depositos = []
      this.selectedPtoVta = {}
      this.selectedEstado = {}
      this.limpiarFecha = true
      this.clienteCodigo = ''
      this.clienteNombre = ''
    },
    setBuscarClienteM(value){
      this.dialogClientes.activo = value
      if (this.dialogClientes.activo == false) this.dialogClientes.ejecutivo_codigo = ''
    },
    setCliente(item){
      this.clienteCodigo = item.vendedor_codigo
      this.clienteNombre = item.vendedor_nombre
    },
    async verDevolucion(item){
      // abro el modal
      this.dialogVerDevolucion.encabezado = item
      this.dialogVerDevolucion.activo = true
    },
    editarDevolucion(item){
      let path = '/editar-devolucion/' + item.devolucion_codigo
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    nuevaDevolucion(){
      let path = '/nueva-devolucion'
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    setVerDevolucion(value){
      this.dialogVerDevolucion.activo = value
      if (this.dialogVerDevolucion.activo == false) this.dialogVerDevolucion.encabezado = {}
    },
    aceptarDevolucion(item){
      let path = '/aceptar-devolucion/' + item.devolucion_codigo
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    auditar(item){
      this.dialogAuditar.devolucion_codigo = item.devolucion_codigo
      this.dialogAuditar.activo = true
    },
    setModalAuditar(value){
      this.dialogAuditar.activo = value
      if (this.dialogAuditar.activo == false) this.dialogAuditar.devolucion_codigo = ''
    }
  },
  watch: {
    devoluciones: function(){
      if (this.devoluciones.length > 0){
        for (let id in this.devoluciones){
          if (this.devoluciones[id].fecha != null && this.devoluciones[id].fecha != undefined && this.devoluciones[id].fecha.toString().length > 0) this.devoluciones[id].fecha = moment(this.devoluciones[id].fecha).format("DD/MM/YYYY")
          if (this.devoluciones[id].fecha_audit != null && this.devoluciones[id].fecha_audit != undefined && this.devoluciones[id].fecha_audit.toString().length > 0) this.devoluciones[id].fecha_audit = moment(this.devoluciones[id].fecha_audit).format("DD/MM/YYYY")
          if (this.devoluciones[id].fecha_estado != null && this.devoluciones[id].fecha_estado != undefined && this.devoluciones[id].fecha_estado.toString().length > 0) this.devoluciones[id].fecha_estado = moment(this.devoluciones[id].fecha_estado).format("DD/MM/YYYY")
          if (this.devoluciones[id].fecha_grabacion != null && this.devoluciones[id].fecha_grabacion != undefined && this.devoluciones[id].fecha_grabacion.toString().length > 0) this.devoluciones[id].fecha_grabacion = moment(this.devoluciones[id].fecha_grabacion).format("DD/MM/YYYY")
          // cliente
          this.devoluciones[id].cliente = this.devoluciones[id].cliente_nombre
          this.devoluciones[id].remito = this.devoluciones[id].codigo_emision.toString().padStart(4, '0') + '-' + this.devoluciones[id].numero_comprobante.toString().padStart(8, '0')
        }
      }
    }
  },
}
</script>

<style>

</style>