<template>
  <div>
    <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
      <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
        <div v-if="nuevaDevolucion == 0">Remito Devolución N° {{ devolucionCodigo }}</div>
        <div v-else>Nuevo Remito Devolución</div>
        <v-spacer></v-spacer>
      </v-col>
      <!-- Contenido -->
      <v-col cols="12">
        <v-card
          flat
          width="auto"
        >
          <v-card-text>
            <!-- Sucursal, Pto Vta, Vendedor Código y Nombre -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-autocomplete
                  v-model="selectedSucursal"
                  item-text="nombre"
                  :items="sucursales"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="setLocales(0)"
                  :disabled="nuevaDevolucion == 0"
                  label="Sucursal"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-0">
                <v-autocomplete
                  v-model="selectedPtoVta"
                  item-text="pto_vta_nombre"
                  :items="ptosVta"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="getVendedores()"
                  :disabled="nuevaDevolucion == 0"
                  label="Punto de Venta"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="vendedorCodigo"
                  outlined
                  dense
                  type="number"
                  :readonly="lockVendedor || nuevaDevolucion == 0"
                  @blur="setVendedor()"
                  label="Vendedor Código"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="vendedorNombre"
                  outlined
                  dense
                  type="text"
                  readonly
                  label="Vendedor Nombre"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Emisión, Número y Referencia -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="emision"
                  outlined
                  dense
                  type="text"
                  :readonly="nuevaDevolucion == 0"
                  label="Emisión"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="numero"
                  outlined
                  dense
                  type="text"
                  :readonly="nuevaDevolucion == 0"
                  label="Número"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" class="py-0">
                <v-text-field
                  v-model="referencia"
                  outlined
                  dense
                  type="text"
                  label="Referencia"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Cliente -->
            <ClienteMayorista 
              :datos="clienteParam"
              @setSelectCliente="setearCliente"
              @setearClearCliente="setClearCliente"
            />
            <!-- Artículos -->
            <ArticulosDevolucion 
              :datos="artsParam"
              @setListadoArticulos="setArticulosDevolucion"
              @setearClearArts="setClearArts"
            />
          </v-card-text>
          <!-- Botones -->
          <v-card-actions class="d-flex justify-end mx-2">
            <v-btn color="warning" @click="volverIndex()" class="ma-2">Volver</v-btn>
            <BtnConfirmar
              @action="grabar()"
              color="success"
              icono="fas fa-check"
              :texto="`¿Esta seguro de realizar la devolución?`"
              nombre="Guardar"
              clase="ma-2"
              v-if="nuevaDevolucion == 1"
            />
            <BtnConfirmar
              @action="modificar()"
              color="success"
              icono="fas fa-save"
              :texto="`¿Esta seguro de modificar la devolución?`"
              nombre="Modificar"
              v-if="nuevaDevolucion == 0 && tienePermiso == 0"
              clase="ma-2"
            />
            <BtnConfirmar
              @action="aceptarDevolucion()"
              color="success"
              icono="fas fa-check"
              :texto="`¿Esta seguro de aceptar la devolución?`"
              nombre="Aceptar Devolución"
              clase="ma-2"
              v-if="nuevaDevolucion == 0 && tienePermiso == 1"
            />
            <BtnConfirmar
              @action="auditar()"
              color="success"
              icono="fas fa-check-double"
              :texto="`¿Esta seguro de marcar como Auditada a la Devolución?`"
              nombre="Auditar"
              clase="ma-2"
              v-if="nuevaDevolucion == 0 && tienePermiso == 1 && (encabezado.estado_codigo == 2 || encabezado.estado_codigo == 3)"
            />
            <BtnConfirmar
              @action="rechazarDevolucion()"
              color="accent"
              icono="fas fa-times"
              :texto="`¿Esta seguro de rechazar la devolución?`"
              nombre="Rechazar Devolución"
              clase="ma-2"
              v-if="nuevaDevolucion == 0 && tienePermiso == 1"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar Vendedor -->
    <ModalSelectVendedor 
      :datos="dialogVendedores"
      @setearModalSelectVend="setModalSelectVend"
      @setearVendedorSelect="setVendedorSelect"
    />
    <!-- Modal Auditar Devolución -->
    <ModalAuditarDev 
      :datos="dialogAuditar"
      @setearModalAuditar="setModalAuditar"
    />
  </div>
</template>

<script>
import ClienteMayorista from '../../../components/generales/clientes/ClienteMayorista.vue'
import ArticulosDevolucion from '../../../components/generales/remitos-devolucion/ArticulosDevolucion.vue'
import BtnConfirmar from '../../../components/util/BtnConfirmar.vue'
import ModalSelectVendedor from '../../../components/generales/vendedores/ModalSelectVendedor.vue'
import ModalAuditarDev from '../../../components/generales/remitos-devolucion/ModalAuditarDev.vue'
import router from '../../../router'
export default {
  data() {
    return {
      nuevaDevolucion: 0,
      devolucionCodigo: 0,
      selectedSucursal: {},
      sucursales: [],
      selectedPtoVta: {},
      ptosVta: [],
      ptosVtaAux: [],
      emision: '',
      numero: '',
      vendedorCodigo: '',
      vendedorNombre: '',
      lockVendedor: false,
      cliente: {},
      clienteParam: {
        tienePermiso: 0, // indica si puede o no crear nuevos clientes mayoristas
        ejecutivo_codigo: 0, // codigo del ejecutivo, sirve para buscar sus clientes
        pto_vta: 0, // pto de venta asociado al ejecutivo mayorista, no al cliente
        lockCliente: false, // permite o no ingresar otro codigo de cliente
        modifica: 0, // indica si se está editando o no, dejarlo en 0 si no se esta editando
        cliente: 0, // en caso de editar, pasar este campo ya que indica el codigo del cliente, sino dejarlo en 0,
        limpiar_seccion: false, // indica si tenemos que limpiar los campos de clientes
        datosCli: {}, // lleva los datos de un cliente que ya existe, dejar en vacío si es nuevo
        nuevo: true, //indica que si es nuevo, false indica que ya existe
        miRuta: 'clientes/getClienteMayorista', //ruta para donde mandar el buscar
      },
      tienePermiso: 0,
      vendedores: [],
      artsParam: {
        lista_precios: '',
        cliente_codigo: '',
        articulos: [], // en caso de editar, pasar la lista de articulos para que se pueda rearmar por dentro
        aceptar: 0, // indica si estan por aceptar o no un remito
        limpiar_seccion: false // indica si tenemos que limpiar los campos de Artículos
      },
      articulos: [],
      referencia: '',
      dialogVendedores: {
        activo: false,
        listado_vendedores: []
      },
      encabezado: {
        estado_codigo: 0
      },
      dialogAuditar: {
        activo: false,
        devolucion_codigo: ''
      }
    }
  },
  components: {
    ClienteMayorista,
    ArticulosDevolucion,
    BtnConfirmar,
    ModalSelectVendedor,
    ModalAuditarDev
  },
  created () {
    if (Object.keys(this.$route.params).length == 0){
      this.nuevaDevolucion = 1
    }else{
      this.nuevaDevolucion = 0
      this.devolucionCodigo = this.$route.params.devolucion_codigo
    }
    if (this.$route.fullPath.indexOf('aceptar-devolucion') != -1) this.artsParam.aceptar = 1
    this.initForm()
  },
  methods: {
    async initForm(){
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.$store.state.loading = true
      let initPeticion
      if (this.nuevaDevolucion == 1) initPeticion = await this.$store.dispatch('remitosDevolucion/initNuevaDevolucionForm')
      else initPeticion = await this.$store.dispatch('remitosDevolucion/initEditDevolucion', {codigo: this.devolucionCodigo})
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.ptosVtaAux = initPeticion.ptosVta
        this.clienteParam.tienePermiso = initPeticion.permiso
        // estan editando?
        if (this.nuevaDevolucion == 0){
          // es editar
          if (initPeticion.encDev.length > 0 && initPeticion.detalles.length > 0){
            // esta pendiente?
            if (initPeticion.encDev[0].estado_codigo == 1) this.setEditForm(initPeticion.encDev[0], initPeticion.detalles, initPeticion.series)
            else{
              this.$store.dispatch('show_snackbar', {
                text: 'Solamente se puede editar devoluciones Pendientes de Aprobación.',
                color: 'info',
              })
              setTimeout(() => [this.volverIndex()], 2000)
              return
            }
          }
          else{
            this.$store.dispatch('show_snackbar', {
              text: 'Devolución no encontrada o sin permisos para ver la devolución.',
              color: 'info',
            })
            setTimeout(() => [this.volverIndex()], 2000)
            return
          }
        }
        // es para aceptar la devolución?
        if (this.artsParam.aceptar == 1){
          if (initPeticion.permisoAcept != 1){
            this.$store.dispatch('show_snackbar', {
              text: 'No posee los permisos suficientes para aceptar la devolución.',
              color: 'info',
            })
            setTimeout(() => [this.volverIndex()], 2000)
            return
          }
          this.tienePermiso = initPeticion.permisoAcept
          if (initPeticion.encDev.length > 0){
            if (initPeticion.encDev[0].estado_codigo == 2 || initPeticion.encDev[0].estado_codigo == 3){
              this.$store.dispatch('show_snackbar', {
                text: 'La devolución ya se encuentra aceptada.',
                color: 'info',
              })
              setTimeout(() => [this.volverIndex()], 2000)
              return
            }
          }
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    setLocales(pto_vta){
      this.ptosVta = []
      if (Object.keys(this.selectedSucursal).length > 0){
        this.ptosVta = this.ptosVtaAux.filter(element => element.sucursal_codigo == this.selectedSucursal.id)
      }
      // es edit?
      if (this.nuevaDevolucion == 0){
        // coloco el pto de vta
        this.selectedPtoVta = this.ptosVta.filter(element => element.pto_vta_codigo == pto_vta)[0]
        this.getVendedores()
      }
    },
    async getVendedores(){
      this.vendedores = []
      this.vendedorCodigo = ''
      this.vendedorNombre = ''
      this.clienteParam.pto_vta = 0
      this.clienteParam.ejecutivo_codigo = 0
      this.clienteParam.limpiar_seccion = true
      if (Object.keys(this.selectedPtoVta).length > 0){
        this.clienteParam.pto_vta = this.selectedPtoVta.pto_vta_codigo
        this.$store.state.loading = true
        let vendedoresPeticion = await this.$store.dispatch('remitosDevolucion/getVendedoresMayoristasXPtoVta', {pto_vta: this.selectedPtoVta.pto_vta_codigo})
        this.$store.state.loading = false
        if (vendedoresPeticion.resultado == 1){
          this.vendedores = vendedoresPeticion.vendedores
        }else{
          this.$store.dispatch('show_snackbar', {
            text: vendedoresPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    setVendedorSelect(item){
      this.vendedorCodigo = item.vendedor_codigo
      this.setVendedor()
    },
    setVendedor(){
      this.clienteParam.limpiar_seccion = true
      if (this.vendedorCodigo == null || this.vendedorCodigo == undefined || this.vendedorCodigo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese un código válido.',
          color: 'info',
        })
        return
      }
      if (Object.keys(this.selectedPtoVta).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Seleccione un Punto de Venta para ingresar al vendedor.',
          color: 'info',
        })
        return
      }
      // busco el vendedor que me pasan
      let unVendedor = this.vendedores.filter(element => element.vendedor_codigo == this.vendedorCodigo)
      if (unVendedor.length > 0 && this.lockVendedor == false){
        this.vendedorCodigo = unVendedor[0].vendedor_codigo
        this.vendedorNombre = unVendedor[0].vendedor_nombre
        this.clienteParam.ejecutivo_codigo = unVendedor[0].ejecutivo_codigo
        this.lockVendedor = true
      }else{
        if (this.lockVendedor == false){
          this.vendedorCodigo = ''
          this.$store.dispatch('show_snackbar', {
            text: 'El vendedor ingresado no pertenece al Punto de Venta seleccionado.',
            color: 'info',
          })
          // abro el modal de vendedores
          this.dialogVendedores.listado_vendedores = this.vendedores
          this.dialogVendedores.activo = true
        }
      }
    },
    setearCliente(item){
      this.cliente = {}
      this.cliente = item
    },
    setArticulosDevolucion(listado){
      this.articulos = listado
    },
    volverIndex(){
      router.push({path: '/remitos-devolucion'})
    },
    validarForm(){
      if (Object.keys(this.selectedSucursal).length == 0) return {valido: false, msj: 'Debe ingresar la Sucursal.'}
      if (Object.keys(this.selectedPtoVta).length == 0) return {valido: false, msj: 'Debe ingresar el Pto de Venta.'}
      if (this.vendedorCodigo == null || this.vendedorCodigo == undefined || this.vendedorCodigo.toString().length == 0) return {valido: false, msj: 'Debe ingresar Vendedor.'}
      if (Object.keys(this.cliente).length == 0) return {valido: false, msj: 'Datos del Cliente incompletos.'}
      if (this.articulos.length == 0) return {valido: false, msj: 'Sin artículos para devolver.'}
      return {valido: true, msj: 'OK'}
    },
    async grabar(){
      let formValido = this.validarForm()
      if (formValido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: formValido.msj,
          color: 'info',
        })
        return
      }
      // armo el body
      let emisionParam = 0
      if (this.emision != null && this.emision != undefined && this.emision.toString().length > 0) emisionParam = this.emision
      let numeroParam = 0
      if (this.numero != null && this.numero != undefined && this.numero.toString().length > 0) numeroParam = this.numero
      let body = {
        sucursal: this.selectedSucursal.id,
        pto_vta: this.selectedPtoVta.pto_vta_codigo,
        vendedor_codigo: this.vendedorCodigo,
        cliente: this.cliente,
        articulos: this.articulos,
        emision: emisionParam,
        numero: numeroParam,
        deposito_codigo: this.selectedPtoVta.deposito_codigo,
        lista_codigo: this.articulos[0].lista,
        referencia: this.referencia
      }
      // grabar
      this.$store.state.loading = true
      let devolucionPeticion = await this.$store.dispatch('remitosDevolucion/grabarDevolucion', body)
      this.$store.state.loading = false
      if (devolucionPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Devolución grabada con Éxito.',
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: devolucionPeticion.msj,
          color: 'error',
        })
      }
    },
    async modificar(){
      let formValido = this.validarForm()
      if (formValido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: formValido.msj,
          color: 'info',
        })
        return
      }
      // armo el body
      let emisionParam = 0
      if (this.emision != null && this.emision != undefined && this.emision.toString().length > 0) emisionParam = this.emision
      let numeroParam = 0
      if (this.numero != null && this.numero != undefined && this.numero.toString().length > 0) numeroParam = this.numero
      let body = {
        sucursal: this.selectedSucursal.id,
        pto_vta: this.selectedPtoVta.pto_vta_codigo,
        vendedor_codigo: this.vendedorCodigo,
        cliente: this.cliente,
        articulos: this.articulos,
        emision: emisionParam,
        numero: numeroParam,
        deposito_codigo: this.selectedPtoVta.deposito_codigo,
        lista_codigo: this.articulos[0].lista,
        referencia: this.referencia,
        codigo: this.devolucionCodigo
      }
      // modificar
      this.$store.state.loading = true
      let modificarPeticion = await this.$store.dispatch('remitosDevolucion/modificarDevolucion', body)
      this.$store.state.loading = false
      if (modificarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Devolución modificada con Éxito.',
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: modificarPeticion.msj,
          color: 'error',
        })
      }
    },
    setEditForm(enc, detalles, series){
      // guardo el encabezado
      this.encabezado = enc
      // sucursal
      this.selectedSucursal = this.sucursales.filter(element => element.id == enc.sucursal_codigo)[0]
      // pto vta y vendedor
      this.setLocales(enc.pto_vta_codigo)
      // emision y numero
      this.emision = enc.codigo_emision
      this.numero = enc.numero_comprobante
      // referencia
      this.referencia = enc.referencia
      // componente Clientes Mayoristas, no dejo modificar el cliente
      this.clienteParam.modifica = 1
      this.clienteParam.cliente = enc.cliente_vend_codigo
      // componente de Artículos
      if (series.length > 0){
        for(let id in detalles){
          let unasSeries = series.filter(element => element.articulo_codigo == detalles[id].articulo_codigo)
          if (unasSeries.length > 0){
            detalles[id].series_seleccionadas = unasSeries
            detalles[id].tipo = 0
          }
        }
      }
      this.artsParam.articulos = detalles
    },
    async aceptarDevolucion(){
      let formValido = this.validarForm()
      if (formValido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: formValido.msj,
          color: 'info',
        })
        return
      }
      // seleccionaron articulos?
      let conSeleccion = this.articulos.filter(element => element.remitido_value == true)
      if (conSeleccion.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Sin artículos seleccionados.',
          color: 'info',
        })
        return
      }
      // armo el body
      let emisionParam = 0
      if (this.emision != null && this.emision != undefined && this.emision.toString().length > 0) emisionParam = this.emision
      let numeroParam = 0
      if (this.numero != null && this.numero != undefined && this.numero.toString().length > 0) numeroParam = this.numero
      let body = {
        sucursal: this.selectedSucursal.id,
        pto_vta: this.selectedPtoVta.pto_vta_codigo,
        vendedor_codigo: this.vendedorCodigo,
        cliente: this.cliente,
        articulos: this.articulos,
        emision: emisionParam,
        numero: numeroParam,
        deposito_codigo: this.selectedPtoVta.deposito_codigo,
        lista_codigo: this.articulos[0].lista,
        referencia: this.referencia,
        codigo: this.devolucionCodigo
      }
      // aceptar
      this.$store.state.loading = true
      let aceptarPeticion = await this.$store.dispatch('remitosDevolucion/aceptarDevolucion', body)
      this.$store.state.loading = false
      if (aceptarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La devolución fue aceptada con éxito.',
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: aceptarPeticion.msj,
          color: 'error',
        })
      }
    },
    async rechazarDevolucion(){
      // rechazar
      this.$store.state.loading = true
      let rechazoPeticion = await this.$store.dispatch('remitosDevolucion/rechazarDevolucion', {codigo: this.devolucionCodigo})
      this.$store.state.loading = false
      if (rechazoPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Devolución fué marcada como Rechazada con Éxito.',
          color: 'success',
        })
        setTimeout(() => [this.volverIndex()], 2000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: rechazoPeticion.msj,
          color: 'error',
        })
      }
    },
    setModalSelectVend(value){
      this.dialogVendedores.activo = value
      if (this.dialogVendedores.activo == false) this.dialogVendedores.listado_vendedores = []
    },
    auditar(){
      this.dialogAuditar.devolucion_codigo = this.devolucionCodigo
      this.dialogAuditar.activo = true
    },
    setModalAuditar(value){
      this.dialogAuditar.activo = value
      if (this.dialogAuditar.activo == false) this.dialogAuditar.devolucion_codigo = ''
    },
    setClearCliente(value){
      this.clienteParam.limpiar_seccion = value
      if (this.clienteParam.limpiar_seccion == true) this.clienteParam.limpiar_seccion = false
    },
    setClearArts(value){
      this.artsParam.limpiar_seccion = value
      if (this.artsParam.limpiar_seccion == true) this.artsParam.limpiar_seccion = false
    }
  },
  watch: {
    vendedores: function(){
      this.lockVendedor = false
      if (this.vendedores.length == 1){
        this.vendedorCodigo = this.vendedores[0].vendedor_codigo
        this.vendedorNombre = this.vendedores[0].vendedor_nombre
        this.clienteParam.ejecutivo_codigo = this.vendedores[0].ejecutivo_codigo
        this.lockVendedor = true
      }
      if (this.vendedores.length > 1 && this.nuevaDevolucion == 0 && this.vendedorCodigo.toString().length == 0){
        this.vendedorCodigo = this.encabezado.vendedor_codigo
        this.setVendedor()
      }
    },
    selectedPtoVta: function(){
      if (Object.keys(this.selectedPtoVta).length > 0) this.artsParam.lista_precios = this.selectedPtoVta.lista_precios
      else this.artsParam.lista_precios = ''
    },
    cliente: function(){
      if (Object.keys(this.cliente).length > 0) this.artsParam.cliente_codigo = this.cliente.cliente_codigo
      else this.artsParam.cliente_codigo = ''
    },
    articulos: function(){
      if (this.articulos.length > 0) this.clienteParam.lockCliente = true
      else this.clienteParam.lockCliente = false
    }
  },
}
</script>

<style>

</style>