var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.activo),callback:function ($$v) {_vm.activo=$$v},expression:"activo"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Saldos - Cliente "+_vm._s(_vm.cliente_codigo)+" "+_vm._s(_vm.cliente_nombre)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Cerrar"},on:{"click":function($event){_vm.activo = false}}},[_vm._v("fas fa-times")])],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs,"justify":"space-around"}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('text-field-money',{attrs:{"label":"Cta. Corriente","properties":{
              prefix: '$',
              readonly: true,
              filled: true,
              'hide-details': true
            }},model:{value:(_vm.totalCtaCte),callback:function ($$v) {_vm.totalCtaCte=$$v},expression:"totalCtaCte"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('text-field-money',{attrs:{"label":"A Favor","properties":{
              prefix: '$',
              readonly: true,
              filled: true,
              'hide-details': true
            }},model:{value:(_vm.totalSaldos),callback:function ($$v) {_vm.totalSaldos=$$v},expression:"totalSaldos"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('text-field-money',{attrs:{"label":"Lim. Crédito","properties":{
              prefix: '$',
              readonly: true,
              filled: true,
              'hide-details': true
            }},model:{value:(_vm.limiteCredito),callback:function ($$v) {_vm.limiteCredito=$$v},expression:"limiteCredito"}})],1),_c('v-col',{staticClass:"d-flex py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-tooltip',{attrs:{"color":"primary","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info","small":""}},'v-icon',attrs,false),on),[_vm._v(" fas fa-info-circle ")])]}}])},[_c('div',[_vm._v(" Disp. Venta = Cta. Corriente + A Favor + Lim. Crédito ")])]),_c('text-field-money',{attrs:{"label":"Disp. Venta","properties":{
              prefix: '$',
              readonly: true,
              filled: true,
              'hide-details': true
            }},model:{value:(_vm.totalDispVenta),callback:function ($$v) {_vm.totalDispVenta=$$v},expression:"totalDispVenta"}})],1)],1),_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"cebra elevation-2 mt-2",attrs:{"headers":_vm.headers,"items":_vm.saldos,"loading":_vm.load,"search":_vm.search,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"d-flex justify-left body-1 pt-1 px-2",attrs:{"no-gutters":""}},[_vm._v(" Detalle de Saldos a Favor ")])]},proxy:true},_vm._l((_vm.headers.filter((header) => header.hasOwnProperty('formatter'))),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mx-auto mt-4",attrs:{"type":"warning","border":"left","dense":"","text":""}},[_vm._v(" Sin saldos para mostrar. ")])]},proxy:true}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }