<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Titulo Filtros -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Filtros y Botón generar -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="generarArchivo">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Fecha Desde
                  <FechaPicker
                    v-model="fechaDesde"
                  />
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Fecha Hasta
                  <FechaPicker
                    v-model="fechaHasta"
                  />
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  Sucursales
                  <v-autocomplete
                    v-model="selectedSucursal"
                    item-text="nombre"
                    :items="sucursales"
                    hide-details
                    outlined
                    dense
                    return-object
                    @change="setLocales()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="8" md="8" class="py-1">
                  Locales
                  <v-autocomplete
                    v-model="selectedLocal"
                    item-text="nombre"
                    :items="locales"
                    hide-details
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="py-1">
                  <BtnDescargarArchivo
                    :loading="load"
                    @clear="limpiar()"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnDescargarArchivo from '../../components/util/BtnDescargarArchivo.vue'
import { saveAs } from 'file-saver'
import moment from 'moment'
export default {
  data() {
    return {
      panel: 0,
      fechaDesde: moment(new Date()).format('DD/MM/YYYY'),
      fechaHasta: moment(new Date()).format('DD/MM/YYYY'),
      selectedSucursal: {},
      sucursales: [],
      selectedLocal: {},
      locales: [],
      localesAux: [],
      load: false,
    }
  },
  components: {
    FechaPicker,
    BtnDescargarArchivo
  },
  created () {
    this.initForm()
  },
  methods: {
    initForm(){
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.localesAux = JSON.parse(localStorage.getItem('locales'))
    },
    setLocales(){
      // restablecer el local seleccionado
      this.selectedLocal = {}
      this.locales = []
      if (Object.keys(this.selectedSucursal).length > 0){
        this.locales = this.localesAux.filter(element => element.sucursal == this.selectedSucursal.id)
      }
    },
    filtrosValidos(){
      if (Object.keys(this.selectedSucursal).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la Sucursal.',
          color: 'warning',
        })
        return false
      }
      if (Object.keys(this.selectedLocal).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar el Local.',
          color: 'warning',
        })
        return false
      }
      return true
    },
    async generarArchivo(){
      if (!this.filtrosValidos()){
        return
      }
      let datos = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        sucursal: this.selectedSucursal.id,
        local: this.selectedLocal.local_accesorios
      }
      this.$store.state.loading = true
      let filePeticion = await this.$store.dispatch('genericosAcc/getShoppingFile', datos)
      this.$store.state.loading = false
      if (filePeticion.resultado == 1){
        let blob = new Blob(filePeticion.lineas, {type: "text/plain;charset=utf-8"})
        saveAs(blob, "prueba.txt")
      }else{
        this.$store.dispatch('show_snackbar', {
          text: filePeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.fechaDesde = moment(new Date()).format('DD/MM/YYYY')
      this.fechaHasta = moment(new Date()).format('DD/MM/YYYY')
      this.selectedSucursal = {}
      this.selectedLocal = {}
      this.locales = []
    }
  },
}
</script>

<style>

</style>