<template>
<v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_dialog(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Periodo
                  <PeriodoPicker
                    v-model="filtro.periodo"
                    :clearable="true"
                  />
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="estados_penal"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Número
                  <v-text-field
                    v-model.trim="filtro.numero"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Código BB
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Descripción
                  <v-text-field
                    v-model.trim="filtro.descripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="numero"
        :headers="headers"
        :items="penalizaciones"
        :loading="load"
        :search="search"
        sort-desc
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="permiso == 1 && item.modifica == 1 && item.estado != 1"
            color="indigo"
            title="Editar"
            class="mr-2"
            small
            @click="open_dialog(item)"
          >
            fas fa-pen
          </v-icon>
          <BtnConfirmar
            v-if="permiso == 1 && item.estado != 1"
            icono="fas fa-ban"
            color="error"
            title="Anular"
            :texto="`¿Desea <strong>anular</strong> la penalización Nº <strong>${item.numero}</strong> correspondiente al vendedor <strong>${item.vendedor_nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="anular(item)"
          />
          <BtnConfirmar
            v-if="permiso == 1 && item.estado == 1"
            icono="fas fa-undo"
            color="orange"
            title="Deshacer anular"
            :texto="`¿Desea <strong>deshacer la anulación</strong> de la penalización Nº <strong>${item.numero}</strong> correspondiente al vendedor <strong>${item.vendedor_nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="desanular(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <!-- COMPONENTES -->
    <Penalizacion
      v-model="dialog"
      :p_penalizacion="penalizacion"
      @editar="modificar"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { format_money, get_nombre_obj_arr} from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import PeriodoPicker from '../../components/util/PeriodoPicker'
import Penalizacion from '../../components/objetivos/Penalizacion'

export default {
  data () {
    return {
      panel: 0,
      permiso: 0,
      search: '',
      load: false,
      dialog: false,
      penalizaciones: [],
      headers: [
        { text: 'Número', value: 'numero', align: 'end' },
        { text: 'Periodo', value: 'periodo' },
        { text: 'Código', value: 'vendedor_codigo', align: 'end' },
        { text: 'Vendedor', value: 'vendedor_nombre' },
        { text: 'Importe', value: 'importe', align: 'end', formatter: format_money },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false, filterable: false }
      ],
      penalizacion: {},
      filtro: {
        empresa: null,
        sucursal: null,
        periodo: null,
        estado: null,
        numero: null,
        codigo: null,
        nombre: null,
        descripcion: null
      }
    }
  },
  async created () {
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
    this.$store.state.loading = true
    await this.$store.dispatch('penalizaciones/get_estados')
    this.permiso = await this.$store.dispatch('penalizaciones/permiso')
    this.$store.state.loading = false
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales']),
    ...mapState('penalizaciones', ['estados_penal'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    PeriodoPicker,
    Penalizacion
  },
  methods: {
    modificar (penalizacion) {
      let penal = this.penalizaciones.find(p => p.numero == penalizacion.numero)
      Object.assign(penal, penalizacion)
    },
    async desanular (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('penalizaciones/desanular', item.numero)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // actualiza el estado en el array
          item.estado = 0
          item.estado_nombre = get_nombre_obj_arr(this.estados_penal, 'codigo', item.estado)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async anular (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('penalizaciones/anular', item.numero)
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
          // actualiza el estado en el array
          item.estado = 1
          item.estado_nombre = get_nombre_obj_arr(this.estados_penal, 'codigo', item.estado)
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      if (!this.filtro.empresa) {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'warning'
        })
        return
      }
      this.load = true
      this.search = ''
      this.penalizaciones = []
      await this.$store.dispatch('penalizaciones/get_penalizaciones', this.filtro)
        .then((res) => {
          this.penalizaciones = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    },
    open_dialog (item) {
      this.penalizacion = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        sucursal: null,
        periodo: null,
        estado: null,
        numero: null,
        codigo: null,
        nombre: null,
        descripcion: null
      }
    }
  }
}
</script>