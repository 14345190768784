<template>
  <v-dialog
    v-model="activo"
    max-width="40%"
  >
    <v-card
      flat
      :loading="load"
    >
      <v-card-title>
        <v-icon small class="ma-2">fas fa-search</v-icon> Buscar Localidad
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pt-2">
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" class="py-1">
						<v-autocomplete
							v-model="selectedProvincia"
							:items="provincias"
							return-object
							outlined dense
              hide-details
							label="Provincia"
							item-text="provincia_nombre"
							placeholder="Seleccione una Provincia"
							@change="buscarLocalidades()"
              :disabled="lockProvincia == true"
						></v-autocomplete>
					</v-col>
          <v-col cols="12" class="pt-4">
						<v-autocomplete
							v-model="selectedLocalidad"
							:items="localidades"
							return-object
							outlined dense
              hide-details
							label="Ciudad"
							item-text="localidad_nombre"
							placeholder="Seleccione su Ciudad"
						></v-autocomplete>
					</v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mx-2">
        <v-btn color="success" @click="setLocalidad()">
          Seleccionar Localidad
        </v-btn>
        <v-btn color="warning" @click="activo = false">
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalBusLocalidad', value)
      }
    },
  },
  data() {
    return {
      selectedProvincia: {},
      provincias: [],
      selectedLocalidad: {},
      localidades: [],
      load: false,
      lockProvincia: true
    }
  },
  methods: {
    async initForm(){
      this.load = true
      let provinciasPeticion = await this.$store.dispatch('provinciasStore/getProvincias')
      this.load = false
      if (provinciasPeticion.resultado == 1){
        this.provincias = provinciasPeticion.provincias
        this.lockProvincia = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: provinciasPeticion.msj,
          color: 'error'
        })
      }
    },
    async buscarLocalidades(){
      if (Object.keys(this.selectedProvincia).length > 0){
        this.load = true
        let localidadesPeticion = await this.$store.dispatch('provinciasStore/getLocalidadesXProvincia', {provincia_id: this.selectedProvincia.provincia_id})
        this.load = false
        if (localidadesPeticion.resultado == 1){
          this.localidades = localidadesPeticion.localidades
        }else{
          this.$store.dispatch('show_snackbar', {
            text: localidadesPeticion.msj,
            color: 'error'
          })
        }
      }
    },
    limpiarForm(){
      this.selectedProvincia = {}
      this.provincias = []
      this.selectedLocalidad = {}
      this.localidades = []
      this.load = false
      this.lockProvincia = true
    },
    setLocalidad(){
      if (Object.keys(this.selectedProvincia).length == 0 || Object.keys(this.selectedLocalidad).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la provincia y la localidad para poder confirmar.',
          color: 'warning'
        })
        return
      }
      let loc = this.selectedLocalidad
      // emito
      this.$emit('setSelectedLocalidad', loc)
      // cierro el modal
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    }
  },
}
</script>

<style>

</style>