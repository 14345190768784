<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="40%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Seleccione el Artículo a Asociar
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="articulos"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Artículos para mostrar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="confirmarAsociar(item)" v-on="on">
                        <v-icon small>fas fa-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Asociar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="dialogConfAsociar"
      :texto="`¿Está seguro de Asociar el Código ${selectedItem.articulo_codigo} al Código de Barras ${item.articulo_cod_barras} para el Proveedor ${proveedor_nombre}?`"
      @action="asociarArticulo()"
      @setearModalConfAccion="setModalAsociar"
    />
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import ModalConfirmar from '../util/ModalConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    ModalConfirmar
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalAsocArt', value)
      }
    },
    articulos: {
      get(){
        return this.datos.articulos
      }
    },
    item: {
      get(){
        return this.datos.item
      }
    },
    proveedor_codigo: {
      get(){
        return this.datos.proveedor_codigo
      }
    },
    proveedor_nombre: {
      get(){
        return this.datos.proveedor_nombre
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Código Artículo', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'left', value: 'articulo_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      load: false,
      search: '',
      selectedItem: {},
      dialogConfAsociar: false
    }
  },
  methods: {
    limpiarModal(){
      this.search = ''
      this.selectedItem = {}
      this.dialogConfAsociar = false
    },
    setModalAsociar(value){
      this.dialogConfAsociar = value
    },
    confirmarAsociar(item){
      this.selectedItem = item
      this.dialogConfAsociar = true
    },
    async asociarArticulo(){
      this.$store.state.loading = true
      let asociarPeticion = await this.$store.dispatch('ordenesCompra/asociarArticuloProveedor', {articulo_codigo: this.selectedItem.articulo_codigo, articulo_nombre: this.item.articulo_nombre, articulo_cod_barras: this.item.articulo_cod_barras, proveedor_codigo: this.proveedor_codigo})
      this.$store.state.loading = false
      if (asociarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Asociación realizada con éxito.',
          color: 'success',
        })
        this.$emit('setearAsociacion', {articulo_codigo: this.selectedItem.articulo_codigo, item: this.item})
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: asociarPeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
    } 
  }
}
</script>

<style>

</style>