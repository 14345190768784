<template>
  <v-dialog
    v-model="activo"
    max-width="65%"
    persistent
  >
    <v-card
      flat
    >
      <v-card-title>
        <v-row>
          <v-col cols="12">
            Series para el Artículo {{ articuloCodigo }} - {{ articuloNombre }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center" v-if="parseInt(selectedPendiente) >= 0">
            Cantidad a Mover: {{ cantidad }} - Cantidad Seleccionada: {{ detalleSelect.length }} - Pendiente Seleccionar: {{ selectedPendiente }}
          </v-col>
          <v-col cols="12" class="text-center" v-if="parseInt(selectedPendiente) < 0">
            Cantidad a Mover: {{ cantidad }} - Cantidad Seleccionada: {{ detalleSelect.length }} - Debe quitar {{ sobrantePendiente }} series
          </v-col>
        </v-row>
      </v-card-title>
      <br/>
      <v-card-text>
        <v-row>
          <!-- Tabla de Series Disponibles -->
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headersDisp"
              :items="detalleDisp"
              :loading="loadDisp"
              dense
              :search="searchDisp"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-form @submit.prevent="agregarSerie({})">
                  <v-row class="d-flex justify-left pa-2" no-gutters>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="serie"
                        ref="serieRef"
                        @blur="agregarSerie({})"
                        outlined
                        dense
                        type="text"
                        hide-details
                        label="Serie"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="6">
                      <SearchDataTable
                        v-model="searchDisp"
                      />
                    </v-col> -->
                  </v-row>
                </v-form>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon small title="Agregar" @click="agregarSerie(item)"><v-icon small color="success">fas fa-check</v-icon></v-btn>
              </template>
              <!-- Msj sin series Disponibles -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin series Disponibles para este Producto.
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <!-- Tabla de Series Seleccionadas -->
          <v-col cols="12" sm="6" md="6" class="py-0">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headersSelect"
              :items="detalleSelect"
              :loading="loadSelect"
              dense
              :search="searchSelect"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="12" sm="6">
                    <SearchDataTable
                      v-model="searchSelect"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn icon small title="Quitar" @click="quitarSerie(item)"><v-icon small color="error">fas fa-times</v-icon></v-btn>
              </template>
              <!-- Msj sin series Disponibles -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin series seleccionadas.
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" class="mr-2" @click="activo = false" v-if="cantidad == detalleSelect.length && mostrarVolver == 1">Volver</v-btn>
        <v-btn color="success" class="mr-2" @click="confirmarSeries()" v-if="cantidad == detalleSelect.length">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import SearchDataTable from '../util/SearchDataTable.vue'
export default {
  components: {
    SearchDataTable,
  },
  props: {
    datosSeries: {
      type: Object,
      default: {}
    },
    showVolver: {
      type: Number,
      default: 0
    }
  },
  computed: {
    activo: {
      get() {
        return this.datosSeries.dialogSerie
      },
      set(value) {
        this.$emit('setearModalCSeries', value)
      }
    },
    articuloCodigo: {
      get(){
        return this.datosSeries.articulo_codigo
      }
    },
    articuloNombre: {
      get(){
        return this.datosSeries.articulo_nombre
      }
    },
    detalleDisp: {
      get(){
        return this.datosSeries.series_disponibles
      }
    },
    detalleSelect: {
      get(){
        return this.datosSeries.series_seleccionadas
      }
    },
    cantidad: {
      get(){
        return this.datosSeries.cantidad
      }
    },
    selectedPendiente: {
      get(){
        return parseInt(this.datosSeries.cantidad) - this.detalleSelect.length
      }
    },
    mostrarVolver: {
      get(){
        return this.showVolver
      }
    }
  },
  data() {
    return {
      headersDisp: [
        { text: 'Series', align: 'center', value: 'serie' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      loadDisp: false,
      searchDisp: '',
      headersSelect: [
        { text: 'Series', align: 'center', value: 'serie' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      loadSelect: false,
      searchSelect: '',
      sobrantePendiente: 0,
      serie: ''
    }
  },
  methods: {
    agregarSerie(item){
      // lo seleccionaron o lo pistolearon?
      if (Object.keys(item).length > 0){
        // controlo si ya existe en el detalle
        let unaSerie = this.detalleSelect.filter(element => element.serie == item.serie)
        if (unaSerie.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La serie seleccionada ya se encuentra en el listado de seleccionados.',
            color: 'warning',
          })
          this.$refs.serieRef.focus()
          return
        }
        // no está repetida, ya se llegó a la cantidad deseada?
        if (parseInt(this.cantidad) == parseInt(this.detalleSelect.length)){
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se seleccionaron las series suficientes.',
            color: 'info',
          })
          this.$refs.serieRef.focus()
          return
        }
        // paso a agregar al listado de seleccionados, al mismo tiempo, lo quito de los disponibles
        this.detalleSelect.push(item)
        let pos = this.detalleDisp.indexOf(item)
        this.detalleDisp.splice(pos, 1)
        // foco en la serie
        this.$refs.serieRef.focus()
      }else{
        if (this.serie == null || this.serie == undefined || this.serie.toString().length == 0){
          this.serie = ''
          return
        }
        // escanearon, busco el item en las series disponibles
        let unDet = this.detalleDisp.filter(element => element.serie.toString().toUpperCase() == this.serie.toString().toUpperCase())
        if (unDet.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La serie ingresada no se encuentra disponible para remitir.',
            color: 'warning',
          })
          this.serie = ''
          this.$refs.serieRef.focus()
          return
        }
        // la encontre, hago las validaciones necesarias, controlo si ya existe en el detalle
        let unaSerie = this.detalleSelect.filter(element => element.serie == unDet[0].serie)
        if (unaSerie.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La serie ingresada ya se encuentra en el listado de seleccionados.',
            color: 'warning',
          })
          this.serie = ''
          this.$refs.serieRef.focus()
          return
        }
        // no está repetida, ya se llegó a la cantidad deseada?
        if (parseInt(this.cantidad) == parseInt(this.detalleSelect.length)){
          this.$store.dispatch('show_snackbar', {
            text: 'Ya se seleccionaron las series suficientes.',
            color: 'info',
          })
          this.serie = ''
          this.$refs.serieRef.focus()
          return
        }
        // paso a agregar al listado de seleccionados, al mismo tiempo, lo quito de los disponibles
        this.detalleSelect.push(unDet[0])
        let pos = this.detalleDisp.indexOf(unDet[0])
        this.detalleDisp.splice(pos, 1)
        // limpio la serie y hago foco en ella
        this.serie = ''
        this.$refs.serieRef.focus()
      }
    },
    quitarSerie(item){
      let pos = this.detalleSelect.indexOf(item)
      this.detalleSelect.splice(pos, 1)
      this.detalleDisp.push(item)
    },
    confirmarSeries(){
      this.$emit('confirmanSeries')
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.searchDisp = ''
        this.searchSelect = ''
      }
    },
    selectedPendiente: function(){
      this.sobrantePendiente = Math.abs(this.selectedPendiente)
    }
  },
}
</script>

<style>

</style>