<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Ver Facturas de Compra para la Orden de Compra N° {{ orden_id }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="facturas"
                :loading="load"
                dense
                :search="search"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Facturas para mostrar.
                  </v-alert>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="verFacturaCompra(item)" v-on="on">
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import { format_money } from '../../util/utils'
import moment from 'moment'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalVerFactComp', value)
      }
    },
    orden_id: {
      get(){
        return this.datos.orden_id
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Id', align: 'center', value: 'id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Factura', align: 'center', value: 'factura' },
        { text: 'Total', align: 'center', value: 'total', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      facturas: [],
      load: false,
      search: ''
    }
  },
  methods: {
    async initModal(){
      this.load = true
      let facturasPeticion = await this.$store.dispatch('ordenesCompra/getFacturasCompra', {orden_id: this.orden_id})
      this.load = false
      if (facturasPeticion.resultado == 1){
        this.facturas = facturasPeticion.facturas
      }else{
        this.$store.dispatch('show_snackbar', {
          text: facturasPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarModal(){
      this.facturas = []
      this.load = false
      this.search = ''
    },
    verFacturaCompra(item){
      let path = '/factura-compra/' + item.id
      let routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
      else this.initModal()
    },
    facturas: function(){
      if (this.facturas.length > 0){
        for (let id in this.facturas){
          this.facturas[id].fecha = moment(this.facturas[id].fecha).format("DD/MM/YYYY")
        }
      }
    }
  },
}
</script>

<style>

</style>