<template>
  <v-dialog
    v-model="activo"
    max-width="60%"
  >
    <v-card flat>
      <v-card-title>
        Saldos - Cliente {{ cliente_codigo }} {{ cliente_nombre }}
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="mt-4">
        <!-- Encabezado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" justify="space-around">
          <v-col cols="12" sm="3" md="3" class="py-1">
            <text-field-money
              v-model="totalCtaCte"
              label="Cta. Corriente"
              v-bind:properties="{
                prefix: '$',
                readonly: true,
                filled: true,
                'hide-details': true
              }"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <text-field-money
              v-model="totalSaldos"
              label="A Favor"
              v-bind:properties="{
                prefix: '$',
                readonly: true,
                filled: true,
                'hide-details': true
              }"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            <text-field-money
              v-model="limiteCredito"
              label="Lim. Crédito"
              v-bind:properties="{
                prefix: '$',
                readonly: true,
                filled: true,
                'hide-details': true
              }"
            />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="d-flex py-1">
            <v-tooltip color="primary" left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="info"
                  class="mr-2"
                  style="cursor: pointer"
                  small
                >
                  fas fa-info-circle
                </v-icon>
              </template>
              <div>
                Disp. Venta = Cta. Corriente + A Favor + Lim. Crédito
              </div>
            </v-tooltip>
            <text-field-money
              v-model="totalDispVenta"
              label="Disp. Venta"
              v-bind:properties="{
                prefix: '$',
                readonly: true,
                filled: true,
                'hide-details': true
              }"
            />
          </v-col>
        </v-row>
        <!-- Listado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="saldos"
              :loading="load"
              :search="search"
              :items-per-page="-1"
              hide-default-footer
              dense
            >
              <template v-slot:top>
                <v-row class="d-flex justify-left body-1 pt-1 px-2" no-gutters>
                  Detalle de Saldos a Favor
                </v-row>
              </template>
              <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
              <template
                v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin saldos para mostrar.
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { format_money, convertDecimals } from '../../util/utils'
import TextFieldMoney from '../util/TextFieldMoney.vue'

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalSaldos', value)
      }
    },
    cliente_codigo: {
      get(){
        return this.datos.cliente_codigo
      }
    },
    cliente_nombre: {
      get(){
        return this.datos.cliente_nombre
      }
    }
  },
  components: {
    TextFieldMoney
  },
  data() {
    return {
      totalCtaCte: 0,
      totalSaldos: 0,
      limiteCredito: 0,
      totalDispVenta: 0,
      headers: [
        { text: 'Origen', align: 'center', value: 'nombre' },
        { text: 'Saldo', align: 'center', value: 'saldo', formatter: format_money }
      ],
      saldos: [],
      load: false,
      search: ''
    }
  },
  methods: {
    async getSaldosAFavor(){
      this.load = true
      let saldosPeticion = await this.$store.dispatch('clientes/getSaldosAFavor', {
        cliente_codigo: this.cliente_codigo
      })
      this.load = false
      this.totalCtaCte = 0
      this.limiteCredito = 0
      this.totalSaldos = 0
      if (saldosPeticion.resultado == 1){
        this.saldos = saldosPeticion.saldosFavor
        if (saldosPeticion.ctaCte.length > 0){
          if (saldosPeticion.ctaCte[0].saldo != null && saldosPeticion.ctaCte[0].saldo != undefined && saldosPeticion.ctaCte[0].saldo.toString().length > 0) this.totalCtaCte = (-1)*convertDecimals(saldosPeticion.ctaCte[0].saldo)
          if (saldosPeticion.ctaCte[0].limite_credito != null && saldosPeticion.ctaCte[0].limite_credito != undefined && saldosPeticion.ctaCte[0].limite_credito.toString().length > 0) this.limiteCredito = convertDecimals(saldosPeticion.ctaCte[0].limite_credito)
          this.getTotalDispVenta()
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: saldosPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarModal(){
      this.totalCtaCte = 0
      this.totalSaldos = 0
      this.limiteCredito = 0
      this.totalDispVenta = 0
      this.saldos = []
      this.load = false
      this.search = ''
    },
    getTotalDispVenta(){
      this.totalDispVenta = 0
      if (this.totalCtaCte != null && this.totalCtaCte != undefined && this.totalCtaCte.toString().length > 0 &&
      this.limiteCredito != null && this.limiteCredito != undefined && this.limiteCredito.toString().length > 0 && 
      this.totalSaldos != null && this.totalSaldos != undefined && this.totalSaldos.toString().length > 0){
        this.totalDispVenta = this.totalDispVenta + convertDecimals(this.totalCtaCte) + convertDecimals(this.limiteCredito) + convertDecimals(this.totalSaldos)
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.getSaldosAFavor()
      else this.limpiarModal()
    },
    saldos: function(){
      if (this.saldos.length > 0){
        this.totalSaldos = 0
        for(let id in this.saldos){
          this.totalSaldos = this.totalSaldos + convertDecimals(this.saldos[id].saldo)
        }
        this.getTotalDispVenta()
      }
    }
  }
}
</script>