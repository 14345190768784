<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo y boton agregar -->
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Parámetro"
              v-if="tienePermiso == 1"
              @click="nuevoParametro()"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros y Buscar -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar()">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Operación
                    <v-autocomplete
                      v-model="selectedOperacion"
                      item-text="operacion_nombre"
                      :items="operaciones"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="d-flex justify-end align-end">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                      clase="mt-0"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="operacionesParam"
          :loading="load"
          dense
          :search="search"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados.
            </v-alert>
          </template>
          <!-- Valor -->
          <template v-slot:[`item.parametro_valor`]="{ item }">
            <v-text-field v-model="item.parametro_valor" type="text" outlined dense hide-details style="width: auto" :readonly="item.editar == 0"></v-text-field>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom v-if="item.usuario_actualiza == 1 && item.editar == 0">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" @click="editarParametro(item)" v-on="on">
                  <v-icon small>fas fa-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.editar == 1 && item.usuario_actualiza == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="confirmarModificar(item)" v-on="on">
                  <v-icon small>far fa-check-circle</v-icon>
                </v-btn>
              </template>
              <span>Guardar Cambios</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.editar == 1 && item.usuario_actualiza == 1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="warning" @click="cancelarModificar(item)" v-on="on">
                  <v-icon small>far fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Nuevo Parametro -->
    <ModalNuevoParam 
      :datos="dialogNuevoParam"
      @setearModalNewParam="setModalNewParam"
    />
    <!-- Modal Confirmar Accion -->
    <ModalConfirmar 
      :activoDialog="modalConfAccion"
      texto="¿Está seguro de modificar el parámetro?"
      @action="guardarCambios()"
      @setearModalConfAccion="setModalConfAccion"
    />
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import ModalNuevoParam from '../../components/generales/parametros/ModalNuevoParam.vue'
export default {
  data() {
    return {
      panel: 0,
      tienePermiso: 0,
      selectedOperacion: {},
      operaciones: [],
      selectedEstado: {},
      estados: [{estado_codigo: 0, estado_nombre: 'Habilitado'}, {estado_codigo: 1, estado_nombre: 'Inhabilitado'}],
      headers: [
        { text: 'Parámetro', align: 'left', value: 'parametro_codigo' },
        { text: 'Descripción', align: 'left', value: 'parametro_descripcion' },
        { text: 'Valor', align: 'center', value: 'parametro_valor' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      operacionesParam: [],
      opParamAux: [],
      load: false,
      search: '',
      valorOriginal: '',
      dialogNuevoParam: {
        activo: false,
        operaciones: []
      },
      modalConfAccion: false,
      selectedItem: {}
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    ModalNuevoParam,
    ModalConfirmar
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('parametros/initFormParametros')
      this.$store.state.loading = false
      if (initPeticion.resultado == 1){
        this.operaciones = initPeticion.operaciones
        this.dialogNuevoParam.operaciones = initPeticion.operaciones
        this.tienePermiso = initPeticion.permiso
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedOperacion = {}
      this.selectedEstado = {}
    },
    async buscar(){
      if (Object.keys(this.selectedOperacion).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una operación para realizar la búsqueda.',
          color: 'warning',
        })
        return
      }
      let estado = -1
      if (Object.keys(this.selectedEstado).length > 0) estado = this.selectedEstado.estado_codigo
      this.operacionesParam = []
      this.opParamAux = []
      this.load = true
      let detallesPeticion = await this.$store.dispatch('parametros/getOperacionesParametros', {operacion_codigo: this.selectedOperacion.operacion_codigo, estado_codigo: estado})
      this.load = false
      if (detallesPeticion.resultado == 1){
        this.operacionesParam = detallesPeticion.parametros
        this.opParamAux = detallesPeticion.parametros
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
      }
    },
    editarParametro(item){
      // guardo el valor original
      this.valorOriginal = item.parametro_valor
      let pos = this.operacionesParam.indexOf(item)
      this.operacionesParam[pos].editar = 1
    },
    cancelarModificar(item){
      let pos = this.operacionesParam.indexOf(item)
      this.operacionesParam[pos].editar = 0
      // vuelvo el valor que tenía originalmente
      this.operacionesParam[pos].parametro_valor = this.valorOriginal
      // reseteo el valor original
      this.valorOriginal = ''
    },
    confirmarModificar(item){
      this.selectedItem = item
      this.modalConfAccion = true
    },
    setModalConfAccion(value){
      this.modalConfAccion = value
      if (this.modalConfAccion == false) this.selectedItem = {}
    },
    async guardarCambios(){
      this.$store.state.loading = true
      let updatePeticion = await this.$store.dispatch('parametros/updateParametro', {
        operaciones_codigo: this.selectedItem.operaciones_codigo,
        parametro_codigo: this.selectedItem.parametro_codigo,
        parametro_valor: this.selectedItem.parametro_valor
      })
      this.$store.state.loading = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El parámetro fue actualizado con éxito.',
          color: 'success',
        })
        this.buscar()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error',
        })
      }
    },
    nuevoParametro(){
      this.dialogNuevoParam.activo = true
    },
    setModalNewParam(value){
      this.dialogNuevoParam.activo = value
    }
  },
}
</script>

<style>

</style>