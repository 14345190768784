<template>
  <div>
    <v-row :no-gutters="$vuetify.breakpoint.xs">
      <v-col cols="12">
        <v-card
          elevation="2"
          outlined
        >
          <v-card-title>
            Artículos
            <v-btn icon @click="buscarArticulo()" :disabled="aceptar == 1" title="Buscar Artículos"><v-icon color="info" small>fas fa-search</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <!-- Agregar un Articulo al Detalle -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="1" md="1" class="py-0">
                <v-text-field
                  v-model="listaPrecios"
                  outlined
                  dense
                  type="number"
                  :readonly="lockLista == true || aceptar == 1"
                  label="Lista"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="cantidad"
                  outlined
                  dense
                  type="number"
                  @blur="getArticulo()"
                  :readonly="aceptar == 1"
                  label="Cantidad"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="articuloCodigo"
                  outlined
                  dense
                  type="number"
                  @blur="getArticulo()"
                  :readonly="aceptar == 1"
                  label="Cod BB."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3" class="py-0">
                <v-text-field
                  v-model="articuloNombre"
                  outlined
                  dense
                  type="text"
                  readonly
                  label="Descripción"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="py-0">
                <v-text-field
                  v-model="descuento"
                  outlined
                  dense
                  type="number"
                  readonly
                  label="Dto."
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-0">
                <v-text-field
                  v-model="precio"
                  outlined
                  dense
                  type="text"
                  readonly
                  label="Precio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" class="pt-0">
                <v-btn icon small title="Agregar" @click="getArticulo()" :disabled="aceptar == 1"><v-icon color="success">fas fa-check</v-icon></v-btn>
              </v-col>
            </v-row>
            <!-- Detalle de Artículos para Nueva Devolución o para Modificación -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="aceptar == 0">
              <v-col cols="12" class="pt-0">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headers"
                  :items="articulos"
                  :loading="load"
                  dense
                  :search="search"
                >
                  <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                  <template
                    v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pt-0" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      Sin artículos ingresados.
                    </v-alert>
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom v-if="item.series.length > 0">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="success" @click="verSeries(item)" v-on="on">
                          <v-icon small>fas fa-list</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver Series</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="error" @click="quitarArticulo(item)" v-on="on">
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </template>
                      <span>Quitar Artículo</span>
                    </v-tooltip>
                  </template>
                  <!-- Items -->
                  <template v-slot:[`item.cantidad`]="{ item }">
                    <v-row class="d-flex justify-center" no-gutters>
                      <v-text-field
                        v-model="item.cantidad"
                        style="width: 50px"
                        type="number"
                        hide-details
                        dense
                        outlined
                        @blur="recalcularPrecios(item)"
                      ></v-text-field>
                    </v-row>
                  </template>
                  <template v-slot:[`item.precio`]="{ item }">
                    <v-row class="d-flex justify-center" no-gutters>
                      <v-text-field
                        v-model="item.precio"
                        style="width: 50px"
                        type="number"
                        hide-details
                        dense
                        outlined
                        prefix="$"
                        @blur="recalcularPrecios(item)"
                      ></v-text-field>
                    </v-row>
                  </template>
                  <template v-slot:[`item.venta_id`]="{ item }">
                    <v-row class="d-flex justify-center" no-gutters>
                      <a :href="`http://200.45.250.4:8800/bb_web/public/venta/mayorista/show/${item.venta_id}`" target="_blank">{{ item.venta_id }}</a>
                    </v-row>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th class="text-center">Total: ${{ Intl.NumberFormat("de-DE").format(totalDevolucion) }}</th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <!-- Detalle de artículos para Aceptar Devolución -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="aceptar == 1">
              <v-col cols="12">
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headerss"
                  :items="articulos"
                  :loading="load"
                  dense
                  :search="search"
                  :single-select="false"
                >
                  <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                  <template
                    v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }"
                  >
                    {{ header.formatter(value) }}
                  </template>
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <!-- <v-row class="d-flex justify-end pa-2" no-gutters></v-row> -->
                    <v-row class="pb-6" no-gutters>
                      <v-col cols="12" sm="2" md="2" v-if="aceptar == 1">
                        <v-btn color="info" small @click="seleccionarTodos()" class="ma-2">Seleccionar Todos</v-btn>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" v-if="aceptar == 1">
                        <v-btn color="accent" small @click="quitarTodos()" class="ma-2">Quitar Todos</v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" sm="3" md="3">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      Sin artículos ingresados.
                    </v-alert>
                  </template>
                  <!-- Acciones -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom v-if="item.series.length > 0">
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="success" @click="verSeries(item)" v-on="on">
                          <v-icon small>fas fa-list</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver Series</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="error" @click="quitarArticulo(item)" v-on="on" :disabled="aceptar == 1">
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </template>
                      <span>Quitar Artículo</span>
                    </v-tooltip>
                  </template>
                  <!-- Items -->
                  <template v-slot:[`item.remitido_value`]="{ item }">
                    <v-row justify="center">
                      <v-checkbox
                        v-model="item.remitido_value"
                        hide-details
                        dense
                        @click="seleccionarDet()"
                      ></v-checkbox>
                    </v-row>
                  </template>
                  <template v-slot:[`item.venta_id`]="{ item }">
                    <v-row class="d-flex justify-center" no-gutters>
                      <a :href="`http://200.45.250.4:8800/bb_web/public/venta/mayorista/show/${item.venta_id}`" target="_blank">{{ item.venta_id }}</a>
                    </v-row>
                  </template>
                  <!-- ultima row para el total -->
                  <template slot="body.append">
                    <tr class="font-weight-bold">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th class="text-center">Total: ${{ Intl.NumberFormat("de-DE").format(totalDevolucion) }}</th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Modal Seleccionar Series -->
    <ControlSeries 
      :datosSeries="seriesParam"
      :showVolver="parseInt(agregarDetalle)"
      @setearModalCSeries="setModalCSeries"
      @confirmanSeries="setSeriesArticulo"
    />
    <!-- Modal Ver Series -->
    <Series 
      :dialogActivo="dialogSeries.activo"
      :articuloCodigo="dialogSeries.articulo_codigo"
      :articuloNombre="dialogSeries.articulo_nombre"
      :seriesRemito="dialogSeries.series"
      @setearModalSeries="setModalSeries"
    />
    <!-- Modal Seleccionar Artículos -->
    <ModalArtsDevolucion 
      :datos="dialogArticulos"
      @setearModalArtsDev="setModalArtsDev"
      @setearArtSelect="setArtSelect"
    />
  </div>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'
import ControlSeries from '../ControlSeries.vue'
import Series from '../Series.vue'
import ModalArtsDevolucion from './ModalArtsDevolucion.vue'
import { convertDecimals, format_money } from '../../../util/utils'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    ControlSeries,
    Series,
    ModalArtsDevolucion
  },
  computed: {
    listaPrecios: {
      get(){
        return this.datos.lista_precios
      }
    },
    clienteCodigo: {
      get(){
        return this.datos.cliente_codigo
      }
    },
    articulosDev: {
      get(){
        return this.datos.articulos
      }
    },
    aceptar: {
      get(){
        return this.datos.aceptar
      }
    },
    limpiar_seccion: {
      get(){
        return this.datos.limpiar_seccion
      },
      set(value) {
        this.$emit('setearClearArts', value)
      }
    }
  },
  data() {
    return {
      articuloCodigo: '',
      cantidad: '',
      articuloNombre: '',
      descuento: 0,
      precio: '',
      headers: [
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Cod. BB', align: 'center', value: 'articulo_codigo' },
        { text: 'Artículo', align: 'center', value: 'articulo_nombre' },
        { text: 'Descuento', align: 'center', value: 'descuento' },
        { text: 'Venta Id', align: 'center', value: 'venta_id' },
        { text: 'Precio', align: 'center', value: 'precio', formatter: format_money },
        { text: 'Importe', align: 'center', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      headerss: [
        { text: 'Remitido', align: 'center', value: 'remitido_value' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Cod. BB', align: 'center', value: 'articulo_codigo' },
        { text: 'Artículo', align: 'center', value: 'articulo_nombre' },
        { text: 'Descuento', align: 'center', value: 'descuento' },
        { text: 'Venta Id', align: 'center', value: 'venta_id' },
        { text: 'Precio', align: 'center', value: 'precio', formatter: format_money },
        { text: 'Importe', align: 'center', value: 'importe', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      articulos: [],
      load: false,
      search: '',
      seriesParam: {
        dialogSerie: false, // para abrir el modal de seleccion de series
        articulo_codigo: 0, // codigo del articulo del que voy a seleccionar las series
        articulo_cod_barras: '',
        articulo_nombre: '',  // idem al anterior
        series_disponibles: [], // TODAS LAS SERIES disponibles del artículo, se obtendría consultando las series en stock en la base de datos y quitando las series seleccionadas
        series_seleccionadas: [], // series seleccionadas por el usuario a remitir
        cantidad: 0, // cantidad de series que debe seleccionar el usuario para poder terminar de ingresar un artículo al detalle a remitir
        precio: 0,
        importe: 0,
        solicita_serie: 0,
        tipo: 0,
        showVolver: 0
      },
      agregarDetalle: 0,
      lockLista: false,
      posDetArticulo: -1,
      dialogSeries: {
        activo: false,
        articulo_codigo: 0,
        articulo_nombre: '',
        series: []
      },
      dialogArticulos: {
        activo: false,
        cliente_codigo: '',
        lista_precios: ''
      },
      totalDevolucion: 0
    }
  },
  methods: {
    buscarArticulo(){
      if (this.clienteCodigo == null || this.clienteCodigo == undefined || this.clienteCodigo.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese el Cliente para buscar Artículos por Listado o bien el Cliente ingresado no está asociado a su código de Cliente.',
          color: 'info',
        })
        return
      }
      // lista de precios
      if (this.listaPrecios == null || this.listaPrecios == undefined || this.listaPrecios.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Ingrese la lista de precios.',
          color: 'info',
        })
        return
      }
      // abro el modal
      this.dialogArticulos.cliente_codigo = this.clienteCodigo
      this.dialogArticulos.lista_precios = this.listaPrecios
      this.dialogArticulos.activo = true
    },
    async getArticulo(){
      if (this.articuloCodigo != null && this.articuloCodigo != undefined && this.articuloCodigo.toString().length > 0){
        if (this.clienteCodigo == null || this.clienteCodigo == undefined || this.clienteCodigo.toString().length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese el Cliente para buscar Artículos o bien el Cliente ingresado no está asociado a su código de Cliente.',
            color: 'info',
          })
          return
        }
        // lista de precios
        if (this.listaPrecios == null || this.listaPrecios == undefined || this.listaPrecios.toString().length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Ingrese la lista de precios.',
            color: 'info',
          })
          return
        }
        // ya existe en el detalle?
        let unArt = this.articulos.filter(element => element.articulo_codigo == this.articuloCodigo)
        if (unArt.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'El artículo ya existe en el detalle.',
            color: 'info',
          })
          return
        }
        // cantidad
        if (this.cantidad == null || this.cantidad == undefined || this.cantidad.toString().length == 0 || parseInt(this.cantidad) == 0) this.cantidad = 1
        // paso a buscar
        this.$store.state.loading = true
        let artPeticion = await this.$store.dispatch('remitosDevolucion/getArtsDevolucion', {articulo_codigo: this.articuloCodigo, cliente: this.clienteCodigo, lista: this.listaPrecios})
        this.$store.state.loading = false
        if (artPeticion.resultado == 1){
          if (artPeticion.articulos.length > 0){
            this.articuloNombre = artPeticion.articulos[0].articulo_nombre
            this.precio = convertDecimals(artPeticion.articulos[0].precio)
            // agrego al detalle
            this.addArticulo(artPeticion.articulos[0])
          }else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron ventas que incluyan el artículo ' + this.articuloCodigo + ' para el cliente ingresado.',
              color: 'info',
            })
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: artPeticion.msj,
            color: 'error',
          })
        }
      }
    },
    addArticulo(art){
      // termino de completar los campos
      art.cantidad = parseInt(this.cantidad)
      art.precio = convertDecimals(art.precio)
      art.importe = convertDecimals(convertDecimals(art.precio) * art.cantidad)
      // discrimina series?
      if (art.solicita_serie == 1){
        // indico que estoy por agregar
        this.agregarDetalle = 1
        // abro el modal de series
        this.abrirModalControlSeries(art)
        return
      }
      // si no solicita serie, lo agrego
      let artBB = this.armarArt(art)
      this.articulos.push(artBB)
      // limpio seriesParam
      this.limpiarSeriesParam()
      // limpio la seccion de articulos
      this.limpiarSeccionArticulos()
    },
    setModalCSeries(value){
      this.seriesParam.dialogSerie = value
    },
    setSeriesArticulo(){
      if (this.agregarDetalle == 1){
        // para agregar
        let art = this.armarArt(this.seriesParam)
        // lo agrego
        this.articulos.push(art)
      }else{
        // actualizar el detalle
        this.articulos[this.posDetArticulo].cantidad_ant = this.articulos[this.posDetArticulo].cantidad
        this.articulos[this.posDetArticulo].series = this.seriesParam.series_seleccionadas
      }
      // reseteo el agregar
      this.agregarDetalle = 0
      // limpio seriesParam
      this.limpiarSeriesParam()
      // limpio la seccion de articulos
      this.limpiarSeccionArticulos()
    },
    armarArt(articulo){
      let articulo_cod_barras = ''
      if (articulo.hasOwnProperty('articulo_cod_barras') && articulo.articulo_cod_barras != null && articulo.articulo_cod_barras != undefined && articulo.articulo_cod_barras.toString().length > 0) articulo_cod_barras = articulo.articulo_cod_barras.toString()
      let remitido = false
      if (articulo.hasOwnProperty('remitido_value')) remitido = articulo.remitido_value
      let ventaId = 0
      if (articulo.hasOwnProperty('venta_id')) ventaId = articulo.venta_id
      let art = {
        articulo_codigo: parseInt(articulo.articulo_codigo),
        articulo_cod_barras: articulo_cod_barras,
        articulo_nombre: articulo.articulo_nombre,
        cantidad: parseInt(articulo.cantidad),
        cantidad_ant: parseInt(articulo.cantidad),
        precio: convertDecimals(articulo.precio),
        importe: convertDecimals(articulo.importe),
        solicita_serie: articulo.solicita_serie,
        series: articulo.series_seleccionadas,
        tipo: articulo.tipo,
        descuento: 0,
        remitido_value: remitido,
        venta_id: ventaId
      }
      return art
    },
    limpiarSeriesParam(){
      this.seriesParam.articulo_codigo = 0
      this.seriesParam.articulo_nombre = ''
      this.seriesParam.articulo_cod_barras = ''
      this.seriesParam.series_disponibles = []
      this.seriesParam.series_seleccionadas = []
      this.seriesParam.cantidad = 0
      this.seriesParam.precio = 0
      this.seriesParam.importe = 0
      this.seriesParam.solicita_serie = 0
      this.seriesParam.tipo = 0
      this.seriesParam.showVolver = 0
    },
    abrirModalControlSeries(art){
      this.seriesParam.articulo_codigo = art.articulo_codigo
      this.seriesParam.articulo_nombre = art.articulo_nombre
      this.seriesParam.articulo_cod_barras = ''
      this.seriesParam.series_disponibles = art.series
      this.seriesParam.series_seleccionadas = art.series_seleccionadas
      this.seriesParam.cantidad = art.cantidad
      this.seriesParam.precio = convertDecimals(art.precio)
      this.seriesParam.importe = convertDecimals(art.importe)
      this.seriesParam.solicita_serie = art.solicita_serie
      this.seriesParam.tipo = art.tipo
      this.seriesParam.showVolver = 1
      this.seriesParam.dialogSerie = true
    },
    limpiarSeccionArticulos(){
      this.articuloCodigo = ''
      this.cantidad = ''
      this.articuloNombre = ''
      this.precio = ''
    },
    limpiarDetalles(){
      this.articulos = []
    },
    verSeries(item){
      // abrir el model de series
      this.dialogSeries.articulo_codigo = item.articulo_codigo
      this.dialogSeries.articulo_nombre = item.articulo_nombre
      this.dialogSeries.series = item.series
      this.dialogSeries.activo = true
    },
    quitarArticulo(item){
      let pos = this.articulos.indexOf(item)
      this.articulos.splice(pos, 1)
    },
    setModalSeries(value){
      this.dialogSeries.activo = value
      if (this.dialogSeries.activo == false){
        this.dialogSeries.articulo_codigo = 0
        this.dialogSeries.articulo_nombre = ''
        this.dialogSeries.series = []
      }
    },
    async recalcularPrecios(item){
      // obtengo la posicion
      let pos = this.articulos.indexOf(item)
      this.posDetArticulo = pos
      this.articulos[pos].importe = convertDecimals(convertDecimals(item.precio) * parseInt(item.cantidad))
      // recalculo el total
      this.totalDevolucion = 0
      for (let id in this.articulos){
        this.totalDevolucion = convertDecimals(this.totalDevolucion) + convertDecimals(this.articulos[id].importe)
      }
      if (parseInt(item.cantidad_ant) != parseInt(item.cantidad) && item.solicita_serie == 1){
        // paso a buscar
        this.$store.state.loading = true
        let artPeticion = await this.$store.dispatch('remitosDevolucion/getArtsDevolucion', {articulo_codigo: item.articulo_codigo, cliente: this.clienteCodigo, lista: this.listaPrecios})
        this.$store.state.loading = false
        if (artPeticion.resultado == 1){
          // reacomodo las series
          let seriesDisponibles = this.getSeriesDisponibles(artPeticion.articulos[0].series, item.series)
          // indico que voy a actualizar
          this.agregarDetalle = 0
          // asigno los valores
          artPeticion.articulos[0].series = seriesDisponibles
          artPeticion.articulos[0].series_seleccionadas = item.series
          artPeticion.articulos[0].cantidad = item.cantidad
          // abro el modal
          this.abrirModalControlSeries(artPeticion.articulos[0])
        }else{
          this.$store.dispatch('show_snackbar', {
            text: artPeticion.msj,
            color: 'error',
          })
        }
      }else{
        this.articulos[pos].cantidad_ant = this.articulos[pos].cantidad
        this.posDetArticulo = -1
      }
    },
    getSeriesDisponibles(seriesStock, seriesArt){
      let listado = []
      if (seriesStock.length > 0){
        for (let id in seriesStock){
          let cont = 0
          for (let jd in seriesArt){
            if (seriesStock[id].serie.toUpperCase() == seriesArt[jd].serie.toUpperCase()){
              cont = cont + 1
            }
          }
          if (parseInt(cont) == 0){
            listado.push(seriesStock[id])
          }
        }
      }
      return listado
    },
    setModalArtsDev(value){
      this.dialogArticulos.activo = value
      if (this.dialogArticulos.activo == false){
        this.dialogArticulos.cliente_codigo = ''
        this.dialogArticulos.lista_precios = ''
      }
    },
    setArtSelect(item){
      this.cantidad = item.cantidad
      this.articuloCodigo = item.articulo_codigo
      this.articuloNombre = item.articulo_nombre
      this.getArticulo()
    },
    seleccionarTodos(){
      for(let id in this.articulos){
        this.articulos[id].remitido_value = true
      }
      this.$emit('setListadoArticulos', this.articulos)
    },
    quitarTodos(){
      for(let id in this.articulos){
        this.articulos[id].remitido_value = false
      }
      this.$emit('setListadoArticulos', this.articulos)
    },
    seleccionarDet(){
      this.$emit('setListadoArticulos', this.articulos)
    }
  },
  watch: {
    articulos: function(){
      this.totalDevolucion = 0
      if (this.articulos.length > 0){
        this.lockLista = true
        for (let id in this.articulos){
          this.articulos[id].lista = this.listaPrecios
          this.totalDevolucion = convertDecimals(this.totalDevolucion) + convertDecimals(this.articulos[id].importe)
        }
        this.$emit('setListadoArticulos', this.articulos)
      }else{
        this.lockLista = false
        this.$emit('setListadoArticulos', [])
      }
    },
    articulosDev: function(){
      if (this.articulosDev.length > 0){
        this.articulos = []
        let listadoArts = []
        for (let id in this.articulosDev){
          let art = this.armarArt(this.articulosDev[id])
          listadoArts.push(art)
        }
        this.articulos = listadoArts
      }
    },
    limpiar_seccion: function(){
      if (this.limpiar_seccion == true){
        this.limpiarSeccionArticulos()
        this.limpiarDetalles()
        this.limpiar_seccion = false
      }
    }
  },
}
</script>

<style scoped>
  a:link {
      color: green;
      background-color: transparent;
      text-decoration: none;
  }
  a:visited {
      color: deepskyblue;
      background-color: transparent;
      text-decoration: none;
  }
  a:active {
      color: blue;
      background-color: transparent;
  }
</style>