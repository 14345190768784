<template>
  <v-dialog
    v-model="visible"
    max-width="1000px"
  >
    <!-- Contenido -->
    <v-card
      flat
      max-width="1000px"
    >
      <v-card-title>
        Subir Archivos para la Factura de Compra {{ facturaId }}
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="visible = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Archivos existentes -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <v-card
              flat
            >
              <v-card-title>
                Archivos Existentes
              </v-card-title>
              <v-card-text>
                <v-data-table
                  class="cebra elevation-2 mt-2"
                  :headers="headers"
                  :items="archivosExistentes"
                  :loading="load"
                  dense
                  :search="search"
                >
                  <!-- Buscador en el datatable -->
                  <template v-slot:top>
                    <v-row class="d-flex justify-end pa-2" no-gutters>
                      <v-col cols="6" sm="3">
                        <SearchDataTable
                          v-model="search"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <!-- Acciones y modal para confirmar -->
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn icon small @click="visualizarFile(item)" v-if="item.type == '.pdf' || item.type == '.jpg' || item.type == '.jpeg' || item.type == '.png'"><v-icon small color="primary" title="Ver">fas fa-eye</v-icon></v-btn>
                    <v-btn icon small @click="downloadFile(item)" v-if="item.type != '.pdf' && item.type != '.jpg' && item.type != '.jpeg' && item.type != '.png'"><v-icon small color="primary" title="Descargar">fas fa-download</v-icon></v-btn>
                    <BtnConfirmar 
                      texto="¿Está seguro de eliminar el archivo seleccionado?"
                      icono="fas fa-trash"
                      :icon_button="true"
                      color="error"
                      :small="true"
                      title="Eliminar Archivo"
                      @action="eliminarArchivo(item)"
                    />
                  </template>
                  <!-- Msj que se mostrara si no existen resultados -->
                  <template v-slot:no-data>
                    <v-alert
                      class="mx-auto mt-4"
                      max-width="400"
                      type="warning"
                      border="left"
                      dense
                      text
                    >
                      No hay datos para los filtros seleccionados
                    </v-alert>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Subir Archivos Nuevos -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <FileDropzone
              :key="refrescarComponente"
              :multiple="false"
              :tipos="['.jpg', '.png', '.pdf']"
              :max_size="1"
              :ruta="ruta"
              :nombre="nombreArchivo"
              :alerta="true"
              :accion="true"
              @action="refrescarArchivos()"
              :raiz="0"
              :tabla="2"
              :id="parseInt(facturaId)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Visor de Imagenes -->
    <ImgViewer 
      v-model="dialogImg"
      :img="img"
      :nombre="nombreVer"
    />
    <!-- Visor de PDF -->
    <PDFViewer 
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombreVer"
      :titulo="nombreVer"
    />
  </v-dialog>
</template>

<script>
import FileDropzone from '../../util/FileDropzone.vue'
import SearchDataTable from '../../util/SearchDataTable.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import PDFViewer from '../../../util/plantillas/PDFViewer.vue'
import ImgViewer from '../../util/ImgViewer.vue'
import { tipos_archivos, getBase64 } from '../../../util/utils'
import { get_file } from '../../../util/plantillas/pdfs'
import download from 'downloadjs'
export default {
  props: {
    ruta: {
      type: String,
      default: '/facturas_compras/'
    },
    activo: {
      type: Boolean,
      default: false
    },
    facturaId: {
      type: String,
      default: '0'
    }
  },
  components: {
    FileDropzone,
    SearchDataTable,
    BtnConfirmar,
    PDFViewer,
    ImgViewer
  },
  data() {
    return {
      refrescarComponente: 0,
      //visible: false,
      search: '',
      load: false,
      headers: [
        { text: 'Nombre', align: 'center', value: 'name' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      archivosExistentes: [],
      tamArchExistentes: 0,
      nombreArchivo: 'Factura_',
      dialogPdf: false,
      dialogImg: false,
      pdf: '',
      nombreVer: '',
      img: ''
    }
  },
  computed: {
    visible: {
      get(){
        return this.activo
      },
      set(value){
        this.$emit('cerrarModalSA', value)
      }
    },
    ruta_local: {
      get(){
        return this.ruta
      }
    }
  },
  methods: {
    async getFCFiles(){
      this.load = true
      // raiz = 0 indica que en el 10.40 voy a tomar por raiz a la carpeta html
      let factPeticion= await this.$store.dispatch('facturasCompra/get_fc_files', {idFactura: this.facturaId, raiz: 0, path: this.ruta_local})
      if (factPeticion.resultado == 0){
        this.load = false
        this.$store.dispatch('show_snackbar', {
          text: 'Sin archivos asociados.',
          color: 'info',
        })
        return
      }
      // archivos exitosos
      if (factPeticion.hasOwnProperty('archivos') && factPeticion.hasOwnProperty('size') && factPeticion.archivos != null && factPeticion.archivos != undefined &&
      factPeticion.size != null && factPeticion.size != undefined){
        this.archivosExistentes = factPeticion.archivos
        this.tamArchExistentes = factPeticion.size
      }
      this.load = false
    },
    eliminarArchivo(item){
      this.load = true
      let rutaArchivo = this.ruta_local + '/' + item.name
      this.$store.dispatch('delete_file', { path: rutaArchivo, raiz: 0, id: this.facturaId, tabla: 2 })
        .then(response => {
          this.load = false
          if (response.exito == 1){
            this.$emit('refrescarTabla')
            this.$store.dispatch('show_snackbar', {
              text: response.message,
              color: 'success',
            })
            this.archivosExistentes = []
            this.getFCFiles()
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.message,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.load = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo eliminar el archivo seleccionado: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    async visualizarFile(item){
      this.nombreVer = item.name
      if (this.nombreVer.indexOf('pdf') >= 0 || this.nombreVer.indexOf('PDF') >= 0){
        this.pdf = 'data:application/pdf;base64,' + item.file
        this.dialogPdf = true
      }else if (this.nombreVer.indexOf('jpg') >= 0 || this.nombreVer.indexOf('JPG') >= 0 || this.nombreVer.indexOf('jpeg') >= 0 || this.nombreVer.indexOf('JPEG') >= 0){
        this.img = 'data:image/jpeg;base64,' + item.file
        this.dialogImg = true
      }else{
        this.img = 'data:image/png;base64,' + item.file
        this.dialogImg = true
      }
    },
    async downloadFile(item){
      // paso a descargar el archivo
      let ruta = this.ruta_local + `/${item.name}`
      let extension = ruta.substring(ruta.lastIndexOf('.'), ruta.length).toLowerCase()
      let mime = tipos_archivos.find(a => a.extension == extension)
      if (mime){
        this.$store.state.loading = true
        let file = await get_file(`${ruta}`, mime.mime)
        download(file, item.name, mime.mime)
        this.$store.state.loading = false
      }
    },
    refrescarArchivos(){
      this.$emit('refrescarTabla')
      this.archivosExistentes = []
      this.getFCFiles()
    }
  },
  watch: {
    /*activo: function() {
      this.visible = this.activo
    },*/
    visible: function(){
      if (this.visible == false){
        //this.$emit('cerrarModalSA')
        this.refrescarComponente ++
      }else{
        this.nombreArchivo = 'Factura_'
        this.archivosExistentes = []
        this.nombreArchivo = this.nombreArchivo + this.facturaId
        this.getFCFiles()
      }
    }/*,
    facturaId: function(){
      if (parseInt(this.facturaId) > 0){
        this.nombreArchivo = 'Factura_'
        this.archivosExistentes = []
        this.nombreArchivo = this.nombreArchivo + this.facturaId
        this.getFCFiles()
      }
    }*/
  },
}
</script>

<style>

</style>