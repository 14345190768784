<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <!-- Titulo -->
        <v-card-title
          class="pt-0"
          :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
        >
          <v-icon small color="success" class="ma-2">fas fa-eye</v-icon> Devolución N° {{ devolucion_codigo }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <!-- Encabezado -->
        <v-card-text>
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="encabezado.devolucion_codigo"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Código"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="encabezado.fecha"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Fecha"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="encabezado.remito"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Remito"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="encabezado.cliente_vend_codigo"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Cliente Código"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="encabezado.cliente"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Cliente"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-0">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="encabezado.deposito_nombre"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Bodega Destino"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="encabezado.usuario_grabacion"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Usu. Grabación"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="encabezado.estado_nombre"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Estado"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-0">
            <v-col cols="12">
              <v-text-field
                v-model="encabezado.referencia"
                outlined
                dense
                type="text"
                readonly
                hide-details
                label="Referencia"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Detalles -->
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-model="selected"
                class="cebra elevation-2"
                :headers="headers"
                :items="detalles"
                :loading="load"
                dense
                :search="search"
                :single-select="true"
              >
                <!-- Items -->
                <template v-slot:[`item.remitido_value`]="{ item }">
                  <v-row justify="center">
                    <v-checkbox
                      v-model="item.remitido_value"
                      hide-details
                      dense
                      disabled
                    ></v-checkbox>
                  </v-row>
                </template>
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin detalles para mostrar.
                  </v-alert>
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom v-if="item.series.length > 0">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="verSeries(item)" v-on="on">
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Series</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Ver Series -->
    <Series 
      :dialogActivo="dialogSeries.activo"
      :articuloCodigo="dialogSeries.articulo_codigo"
      :articuloNombre="dialogSeries.articulo_nombre"
      :seriesRemito="dialogSeries.series"
      @setearModalSeries="setModalSeries"
    />
  </div>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'
import Series from '../../../components/generales/Series.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
    Series
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearVerDevolucion', value)
      }
    },
    devolucion_codigo: {
      get(){
        return this.datos.encabezado.devolucion_codigo
      },
    },
    encabezado: {
      get(){
        return this.datos.encabezado
      },
    }
  },
  data() {
    return {
      load: false,
      detalles: [],
      headers: [
        { text: 'Remitido', align: 'center', value: 'remitido_value' },
        { text: 'Código', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio', align: 'center', value: 'precio' },
        { text: 'Importe', align: 'center', value: 'importe' },
        { text: 'Acciones', align: 'center', value: 'acciones' },
      ],
      search: '',
      selected: [],
      dialogSeries: {
        activo: false,
        articulo_codigo: 0,
        articulo_nombre: '',
        series: []
      }
    }
  },
  methods: {
    async getDetallesDevolucion(){
      this.detalles = []
      this.load = true
      let detallesPeticion = await this.$store.dispatch('remitosDevolucion/getDetallesDevolucion', {devolucion: this.devolucion_codigo})
      this.load = false
      if (detallesPeticion.resultado == 1){
        // vinieron series?
        if (detallesPeticion.series.length > 0){
          // asigno las series
          for (let id in detallesPeticion.detalles){
            let unasSeries = detallesPeticion.series.filter(element => element.articulo_codigo == detallesPeticion.detalles[id].articulo_codigo)
            if (unasSeries.length > 0){
              detallesPeticion.detalles[id].series = unasSeries
            }
          }
        }
        // asigno los detalles
        this.detalles = detallesPeticion.detalles
      }else{
        this.$store.dispatch('show_snackbar', {
          text: detallesPeticion.msj,
          color: 'error',
        })
      }
    },
    verSeries(item){
      // abrir el model de series
      this.dialogSeries.articulo_codigo = item.articulo_codigo
      this.dialogSeries.articulo_nombre = item.articulo_nombre
      this.dialogSeries.series = item.series
      this.dialogSeries.activo = true
    },
    setModalSeries(value){
      this.dialogSeries.activo = value
      if (this.dialogSeries.activo == false){
        this.dialogSeries.articulo_codigo = 0
        this.dialogSeries.articulo_nombre = ''
        this.dialogSeries.series = []
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.detalles = []
        this.selected = []
        this.search = ''
      }else{
        // buscar los detalles
        this.getDetallesDevolucion()
      }
    },
    detalles: function(){
      if (this.detalles.length > 0){
        for (let id in this.detalles){
          this.detalles[id].remitido_value = this.detalles[id].remitido == 0 ? false : true
        }
      }
    }
  },
}
</script>

<style>

</style>