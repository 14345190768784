<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
      >
        <v-card-title>
          Ver Ordenes de Compra para la Factura de Compra {{ factura_id }}
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="auditoria == 1 ? headers_2 : headers"
                :items="ordenesCompra"
                :loading="load"
                dense
                :search="search"
                item-key="orden_id"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Ordenes de Compra para mostrar.
                  </v-alert>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon :color="auditoria==1 ? 'info': 'success'" @click="verOrdenCompra(item)" v-on="on">
                        <v-icon small>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="auditoria==1">
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="error" @click="eliminarOrdenCompra(item)" v-on="on">
                        <v-icon small>fas fa-times-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="auditoria==1">
           <v-col cols="12" class="d-flex justify-end">
              <v-btn color="error" @click="activo = false">
                Cancelar
              </v-btn>
              <v-btn class="ml-2" color="info" @click="asociarOrdenes()" :disabled="desactivar">
                Guardar cambios
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="auditoria==1">
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers_2"
                :items="todasOrdenesCompra"
                :loading="load_2"
                dense
                :search="search_2"
                item-key="orden_id"
                order-by="orden_id"
              >
                <!-- Buscador en el datatable -->
                <template v-slot:top>
                  <v-row class="d-flex justify-end pa-2" no-gutters>
                    <v-col cols="6" sm="3">
                      <SearchDataTable
                        v-model="search_2"
                      />
                    </v-col>
                  </v-row>
                </template>
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    max-width="400"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin Ordenes de Compra para mostrar.
                  </v-alert>
                </template>
                <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                <template
                  v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <!-- Acciones -->
                <template v-slot:[`item.acciones`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn small icon color="success" @click="agregarOrdenCompra(item)" v-on="on">
                        <v-icon small>fas fa-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar Orden</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
           <v-col cols="12" class="d-flex justify-end">
              <v-btn color="info" @click="buscarOrdenesTodas">
                Buscar Ordenes
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchDataTable from '../util/SearchDataTable.vue'
import moment from 'moment'
import { roundNumber, format_money } from '../../util/utils'

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalVerOrdComp', value)
      }
    },
    factura_id: {
      get(){
        return this.datos.factura_id
      }
    },
    auditoria:{
      get(){
        return this.datos.auditoria
      }
    },
    proveedor_codigo:{
      get(){
        return this.datos.proveedor_codigo
      }
    }
  },
  data() {
    return {
      formatMoney: format_money,
      headers: [
        { text: 'Id', align: 'center', value: 'orden_id' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Total', align: 'center', value: 'total', formatter: format_money },
        { text: 'Proveedor', align: 'center', value: 'proveedor_nombre' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Usuario', align: 'center', value: 'usuario' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headers_2: [
        { text: 'Orden de compra', align: 'center', value: 'orden_id' },
        { text: 'Estado', align: 'center', value: 'estado_nombre' },
        { text: 'Total', align: 'right', value: 'total', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      ordenesCompra: [],
      todasOrdenesCompra:[],
      load: false,
      load_2: false,
      search: '',
      search_2:'',
      desactivar: false,
    }
  },
  methods: {
    async initModal(){
      this.load = true
      this.load_2 = true;
      let ordenesPeticion = await this.$store.dispatch('ordenesCompra/getOrdCompraAsoc', {factura_id: this.factura_id})
      let res;
      if(this.auditoria == 1){
        res = await this.$store.dispatch('facturasCompra/obtenerOrdenesDeCompraDisponibles',{
          proveedor_codigo: this.proveedor_codigo,
          moneda_id: 2,
          opcion: 2,
        })
      }
      this.load = false
      this.load_2 = false;
      if (ordenesPeticion.resultado == 1){
        this.ordenesCompra = ordenesPeticion.ordenes
        //obtener todas las ordenes de compra
        if(this.auditoria==1 && res.resultado == 1){
          this.todasOrdenesCompra = res.ordenes
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: ordenesPeticion.msj,
          color: 'error',
        })
      }
    },
    async buscarOrdenesTodas(){
      this.load_2 = true
      const res = await this.$store.dispatch('facturasCompra/obtenerOrdenesDeCompraDisponibles',{
        proveedor_codigo: this.proveedor_codigo,
        moneda_id: 2,
        opcion: 2,
      })
      this.load_2 = false
      if(res.resultado == 1){
        this.todasOrdenesCompra = res.ordenes
      }
    },
    limpiarModal(){
      this.todasOrdenesCompra = [];
      this.ordenesCompra = []
      this.load = false
      this.load_2 = false;
      this.search = ''
      this.search_2 = '';
    },
    async verOrdenCompra(item){
      // abro la orden de compra en otra pestaña
      let path = '/orden-compra/' + item.orden_id.toString()
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    //Médotodos para Auditoria
    eliminarOrdenCompra(item){
      const pos = this.todasOrdenesCompra.map(elem => elem.orden_id).indexOf(item.orden_id);
      if(pos == -1){
        this.todasOrdenesCompra.push(item);
      }
      this.ordenesCompra = this.ordenesCompra.filter(elem => elem.orden_id != item.orden_id);
    },
    agregarOrdenCompra(item){
      const pos = this.ordenesCompra.map(elem => elem.orden_id).indexOf(item.orden_id);
      if(pos == -1){
        if(this.desactivar && this.ordenesCompra.length == 0) this.desactivar = false;
        this.ordenesCompra.push(item);
        this.todasOrdenesCompra = this.todasOrdenesCompra.filter(elem => elem.orden_id != item.orden_id);
      }else{
        this.$swal.fire({
          icon: 'error',
          title: `Asociar Orden de Compra`,
          text: 'La Orden ya se encuentra asociada a la Factura de Compra.',
        })
      }
    },
    asociarOrdenes(){
      this.$swal.fire({
        icon: 'warning',
        title: `¿Confirma asociar las Ordenes de Compra seleccionadas a la Factura de Compra ${this.factura_id}?`,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
      }).then(async (res) => {
        if(res.isConfirmed){
          const ordenesGuardadas = this.ordenesCompra.map(elem => ({ id: elem.id, orden_id: elem.orden_id }));
          
          this.$store.state.loading = true
          await this.$store.dispatch('facturasCompra/guardarOrdenesDeCompraXfacturaCompra',{
            factura_id: this.factura_id,
            ordenes: ordenesGuardadas,
          })
            .then(async (res) => {
              if(res.resultado == 1){
                await this.$swal.fire({icon: 'success', title: `Ordenes de compra asociadas correctamente.`, timer: 4000})
                this.activo = false; //cierra el modal
              }
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      })
    },
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
      else this.initModal()
    },
    ordenesCompra: function(){
      if (this.ordenesCompra.length > 0){
        for (let id in this.ordenesCompra){
          this.ordenesCompra[id].fecha = moment(this.ordenesCompra[id].fecha).format("DD/MM/YYYY")
          this.ordenesCompra[id].total = roundNumber(this.ordenesCompra[id].total, 2)
        }
      }
    }
  },
}
</script>

<style>

</style>