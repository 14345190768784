<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="60%"
    >
      <v-card
        flat
        :loading="load"
      >
        <v-card-title>
          <div v-if="param.ver == 1"><v-icon small class="ma-2">fas fa-eye</v-icon> Ver Local {{ local_nombre }}</div>
          <div v-if="param.nuevo == 1"><v-icon small class="ma-2">fas fa-plus-circle</v-icon> Nuevo Local</div>
          <v-spacer></v-spacer>
          <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <!-- Empresa, Sucursal y Estado de Local -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="5" md="5" class="py-1">
              <v-autocomplete
                v-model="selectedEmpresa"
                item-text="nombre_corto"
                :items="empresas"
                hide-details
                outlined
                dense
                return-object
                label="Empresa"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5" md="5" class="py-1">
              <v-autocomplete
                v-model="selectedSucursal"
                item-text="nombre"
                :items="sucursales"
                hide-details
                outlined
                dense
                return-object
                label="Sucursal"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-1">
              <v-checkbox
                v-model="habilitado"
                :label="`${habilitadoNom}`"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Nombre de Local y Tipo de Local -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-autocomplete
                v-model="selectedTipoLocal"
                ref="tipoLocal"
                item-text="categoria_nombre"
                :items="param.tipos_locales"
                hide-details
                outlined
                dense
                return-object
                label="Tipo"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="9" md="9" class="py-1">
              <v-text-field
                v-model="localNombre"
                :id="locNombre"
                :ref="locNombre"
                outlined
                dense
                type="text"
                hide-details
                label="Local Nombre"
                @blur="setCajaNombre()"
                @keypress.native.enter="$refs[locNombre].blur()"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Datos de Caja -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2" v-if="selectedTipoLocal.usa_caja == 1">
            <v-col cols="12" sm="4" md="4" class="py-1">
              <v-autocomplete
                v-model="selectedCajaPadre"
                ref="cajaRef"
                item-text="caja_nombre"
                :items="cajasPadre"
                hide-details
                outlined
                dense
                return-object
                label="Caja Padre"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="8" md="8" class="py-1">
              <v-text-field
                v-model="cajaNombre"
                outlined
                dense
                type="text"
                hide-details
                label="Caja Nombre"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Codigo Postal, Localidad y Domicilio -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
            <v-col cols="12" sm="2" md="2" class="py-1">
              <v-text-field
                :id="codigoPostal"
                :ref="codigoPostal"
                v-model="codPostal"
                outlined
                dense
                type="number"
                hide-details
                label="C. Post."
                @blur="getLocalidad(1, 0)"
                @keypress.native.enter="$refs[codigoPostal].blur()"
              >
                <template v-slot:append-outer>
                  <v-tooltip color="primary" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="info"
                        style="cursor: pointer"
                        small
                        class="pt-1"
                        @click="buscarLocalidad()"
                      >
                        fas fa-search
                      </v-icon>
                    </template>
                    <div>
                      No recuerdo mi Código Postal
                    </div>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-1">
              <v-autocomplete
                v-model="selectedLocalidad"
                ref="localidadRef"
                item-text="nombre"
                :items="localidades"
                hide-details
                outlined
                dense
                return-object
                label="Localidad"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-1">
              <v-text-field
                v-model="domicilio"
                outlined
                dense
                type="text"
                hide-details
                label="Domicilio"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Superficies y Lista de Precio de Venta -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
            <v-col cols="12" :sm="param.nuevo == 1 ? 6 : 3" :md="param.nuevo == 1 ? 6 : 3" class="py-1">
              <v-text-field
                v-model="supVenta"
                outlined
                dense
                type="number"
                hide-details
                label="Sup. Venta M²"
              ></v-text-field>
            </v-col>
            <v-col cols="12" :sm="param.nuevo == 1 ? 6 : 3" :md="param.nuevo == 1 ? 6 : 3" class="py-1">
              <v-text-field
                v-model="supTotal"
                outlined
                dense
                type="number"
                hide-details
                label="Sup. Total M²"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-1" v-if="param.ver == 1">
              <v-autocomplete
                v-model="selectedListaVenta"
                item-text="lista_nombre"
                :items="listas"
                hide-details
                outlined
                dense
                return-object
                label="Lista de Venta"
                :disabled="tienePermiso == 0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Cobro Electrónico e Impresora Térmica -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-checkbox
                v-model="cobroElectronico"
                label="Cobro Electrónico"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-checkbox
                v-model="impresoraTermica"
                label="Impresora Térmica"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-checkbox
                v-model="cajaDiaria"
                label="Caja Diaria"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Lapos -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-1">
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-checkbox
                v-model="laposIntegrado"
                label="LP Integrado"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-1">
              <v-checkbox
                v-model="laposDesintegrado"
                label="LP Desintegrado"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-checkbox
                v-model="unaTerminalVenta"
                label="Una terminal por venta"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" class="py-1">
              <v-checkbox
                v-model="mayoristaSalon"
                label="Mayorista Salón"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <!-- Seccion para Locales Franquicia -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2" v-if="selectedEmpresa.terceros == 1">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Franquicia
                </v-card-title>
                <v-card-text>
                  <!-- Cliente, Vendedor y Local Dependiente -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="3" md="3" class="py-1">
                      <v-autocomplete
                        v-model="selectedCliente"
                        item-text="cliente_nombre"
                        :items="clientes"
                        hide-details
                        outlined
                        dense
                        return-object
                        label="Cliente"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-1">
                      <v-autocomplete
                        v-model="selectedEjecutivo"
                        item-text="vend_bb_nombre"
                        :items="ejecutivos"
                        hide-details
                        outlined
                        dense
                        return-object
                        label="Vendedor"
                        @change="getLocalesVendedor(1, 0)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-1">
                      <v-autocomplete
                        v-model="selectedLocalEjec"
                        item-text="nombre"
                        :items="localesEjec"
                        hide-details
                        outlined
                        dense
                        return-object
                        label="Local Vendedor"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- Lista de Precios de Compra -->
                  <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
                    <v-col cols="12" sm="6" md="6" class="py-1">
                      <v-autocomplete
                        v-model="selectedListaCompra"
                        item-text="lista_nombre"
                        :items="listasCompra"
                        hide-details
                        outlined
                        dense
                        return-object
                        label="Lista Compra"
                        :disabled="tienePermiso == 0 && param.ver == 1"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Botones -->
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="warning" class="mr-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar
            texto="¿Está seguro de dar de Alta al nuevo Local?"
            @action="altaLocal()"
            v-if="param.nuevo == 1"
          />
          <BtnConfirmar
            texto="¿Está seguro de modificar el Local?"
            @action="modificarLocal()"
            icono="fas fa-edit"
            nombre="Modificar"
            v-if="param.ver == 1"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Buscar Localidad -->
    <ModalBuscarLocalidad 
      :datos="dialogBuscarLocalidad"
      @setearModalBusLocalidad="setModalBusLocalidad"
      @setSelectedLocalidad="setLocalidadSelected"
    />
  </div>
</template>

<script>
import ModalBuscarLocalidad from '../localidades/ModalBuscarLocalidad.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  components: {
    ModalBuscarLocalidad,
    BtnConfirmar
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo 
      },
      set(value) {
        this.$emit('setearModalVerLocales', value)
      }
    },
    local_nombre: {
      get(){
        return this.datos.local.pto_vta_nombre
      }
    },
    local: {
      get(){
        return this.datos.local
      }
    },
    param: {
      get(){
        return this.datos
      }
    },
    empresas: {
      get(){
        return this.datos.empresasDialog
      }
    },
    sucursales: {
      get(){
        return this.datos.sucursalesDialog
      }
    }
  },
  data() {
    return {
      selectedEmpresa: {},
      selectedSucursal: {},
      habilitado: false,
      habilitadoNom: 'Habilitado',
      localNombre: '',
      cajaNombre: '',
      selectedTipoLocal: {},
      domicilio: '',
      codPostal: '',
      codigoPostal: 'codigoPostal',
      locNombre: 'locNombre',
      selectedLocalidad: {},
      localidades: [],
      supVenta: '',
      supTotal: '',
      selectedListaVenta: {},
      listas: [],
      dialogBuscarLocalidad: {
        activo: false
      },
      load: false,
      clientes: [],
      ejecutivos: [],
      selectedCliente: {},
      selectedLocalEjec: {},
      localesEjec: [],
      selectedEjecutivo: {},
      selectedListaCompra: {},
      listasCompra: [],
      cobroElectronico: false,
      impresoraTermica: false,
      laposIntegrado: false,
      laposDesintegrado: false,
      unaTerminalVenta: false,
      mayoristaSalon: false,
      cajaDiaria: false,
      selectedCajaPadre: {},
      cajasPadre: [],
      tienePermiso: 0
    }
  },
  methods: {
    async initForm(){
      this.load = true
      let initPeticion = await this.$store.dispatch('localesStore/initFormVerLocales')
      this.load = false
      if (initPeticion.resultado == 1){
        this.clientes = initPeticion.clientes
        this.ejecutivos = initPeticion.ejecutivos
        this.cajasPadre = initPeticion.cajas
        this.listas = initPeticion.listasVenta
        this.listasCompra = initPeticion.listasCompra
        this.tienePermiso = initPeticion.permiso
        if (this.param.ver == 1) this.initEditLocal()
      }else{
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error'
        })
      }
    },
    initEditLocal(){
      // consigo la empresa
      let unaEmpresa = this.empresas.filter(element => element.id == this.param.local.empresa_codigo)
      if (unaEmpresa.length > 0) this.selectedEmpresa = unaEmpresa[0]
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la empresa del Local.',
          color: 'warning'
        })
        this.activo = false
        return
      }
      // sucursal
      let unaSuc = this.sucursales.filter(element => element.id == this.param.local.sucursal_codigo)
      if (unaSuc.length > 0) this.selectedSucursal = unaSuc[0]
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la sucursal del Local.',
          color: 'warning'
        })
        this.activo = false
        return
      }
      // estado
      this.habilitado = this.param.local.estado_codigo == 0 ? true : false
      // tipo pto de vta
      let unTipo = this.param.tipos_locales.filter(element => element.categoria_codigo == this.param.local.categoria_codigo)
      if (unTipo.length > 0) this.selectedTipoLocal = unTipo[0]
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar el tipo de Local.',
          color: 'warning'
        })
        this.activo = false
        return
      }
      // nombre del local
      this.localNombre = this.param.local.pto_vta_nombre
      // caja padre
      let unaCaja = this.cajasPadre.filter(element => element.caja_numero == this.param.local.caja_padre_numero)
      if (unaCaja.length > 0) this.selectedCajaPadre = unaCaja[0]
      // nombre de caja
      this.cajaNombre = this.param.local.caja_nombre
      // codigo postal
      this.codPostal = this.param.local.localidad_cp
      // localidad
      this.getLocalidad(2, this.param.local.localidad_codigo)
      // domicilio
      this.domicilio = this.param.local.domicilio
      // superficie de venta
      this.supVenta = this.param.local.sup_venta
      // superficie total
      this.supTotal = this.param.local.sup_total
      // lista de precios para la venta
      let unaLista = this.listas.filter(element => element.lista_codigo == this.param.local.lista_venta_cod)
      if (unaLista.length > 0) this.selectedListaVenta = unaLista[0]
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo determinar la lista de precios para la venta porque el local no cuenta con una o cuenta con más de una lista de precios para la venta.',
          color: 'warning'
        })
      }
      // cobro electronico
      this.cobroElectronico = this.param.local.cobro_electronico == 1 ? true : false
      // impresora termica
      this.impresoraTermica = this.param.local.impresora_termica == 1 ? true : false
      // lapos integrado
      this.laposIntegrado = this.param.local.fp_integrado == 1 ? true : false
      // lapos desintegrado
      this.laposDesintegrado = this.param.local.fp_desintegrado == 1 ? true : false
      // una terminal por venta
      this.unaTerminalVenta = this.param.local.terminal_x_venta == 1 ? true : false
      // una terminal por venta
      this.cajaDiaria = this.param.local.caja_diaria == 1 ? true : false
      // mayorista salon
      this.mayoristaSalon = this.param.local.mayorista_salon == 1 ? true : false;
      // es local de terceros?
      if (this.selectedEmpresa.terceros == 1){
        // cliente
        let unCliente = this.clientes.filter(element => element.cliente_codigo == this.param.local.cliente_codigo)
        if (unCliente.length > 0) this.selectedCliente = unCliente[0]
        else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar el Cliente del Local.',
            color: 'warning'
          })
        }
        // vendedor o ejecutivo
        let unVendedor = this.ejecutivos.filter(element => element.vend_acc_codigo == this.param.local.vendedor_codigo)
        if (unVendedor.length > 0) this.selectedEjecutivo = unVendedor[0]
        // local dependiente
        this.getLocalesVendedor(2, this.param.local.local_dependiente)
        // lista de compra
        let unaListaComp = this.listasCompra.filter(element => element.lista_codigo == this.param.local.lista_compra_cod)
        if (unaListaComp.length > 0) this.selectedListaCompra = unaListaComp[0]
      }
    },
    limpiarForm(){
      this.selectedEmpresa = {}
      this.selectedSucursal = {}
      this.habilitado = false
      this.habilitadoNom = 'Habilitado'
      this.localNombre = ''
      this.cajaNombre = ''
      this.selectedTipoLocal = {}
      this.domicilio = ''
      this.codPostal = ''
      this.selectedLocalidad = {}
      this.localidades = []
      this.supVenta = ''
      this.supTotal = ''
      this.selectedListaVenta = {}
      this.listas = []
      this.dialogBuscarLocalidad.activo = false
      this.load = false
      this.clientes = []
      this.ejecutivos = []
      this.selectedCliente = {}
      this.selectedLocalEjec = {}
      this.localesEjec = []
      this.selectedEjecutivo = {}
      this.selectedListaCompra = {}
      this.listasCompra = []
      this.cobroElectronico = false
      this.impresoraTermica = false
      this.laposIntegrado = false
      this.laposDesintegrado = false
      this.unaTerminalVenta = false
      this.mayoristaSalon = false;
      this.cajaDiaria = false
      this.selectedCajaPadre = {}
      this.cajasPadre = []
    },
    async getLocalidad(opcion, loc_codigo){
      // foco en la localidad
      this.$refs.localidadRef.focus()
      if (this.codPostal != null && this.codPostal != undefined && this.codPostal.toString().length > 0){
        this.localidades = []
        this.selectedLocalidad = {}
        this.load = true
        let localidadesPeticion = await this.$store.dispatch('provinciasStore/getLocalidadXCodPostal', {cp: this.codPostal})
        this.load = false
        if (localidadesPeticion.exito == 1){
          if (localidadesPeticion.size = 0 || localidadesPeticion.localidades.length == 0){
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo determinar la Localidad perteneciente al código postal ingresado.',
              color: 'info'
            })
            return
          }
          this.localidades = localidadesPeticion.localidades
          if (opcion == 2){
            let unaLoc = this.localidades.filter(element => element.id == loc_codigo)
            if (unaLoc.length > 0) this.selectedLocalidad = unaLoc[0]
            else{
              this.$store.dispatch('show_snackbar', {
                text: 'No se pudo determinar la Localidad perteneciente punto de venta consultado.',
                color: 'info'
              })
            }
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: localidadesPeticion.error + ': ' + localidadesPeticion.message,
            color: 'error'
          })
        }
      }
    },
    buscarLocalidad(){
      this.dialogBuscarLocalidad.activo = true
    },
    setModalBusLocalidad(value){
      this.dialogBuscarLocalidad.activo = value
    },
    setLocalidadSelected(loc){
      this.localidades = []
      this.codPostal = loc.codigo_postal
      this.getLocalidad(1, 0)
    },
    async getLocalesVendedor(opcion, local_dependiente){
      if (Object.keys(this.selectedEjecutivo).length > 0){
        this.localesEjec = []
        this.load = true
        let localesPeticion = await this.$store.dispatch('localesStore/getLocalesXVendedor', {vendedor: this.selectedEjecutivo.vend_bb_codigo})
        this.load = false
        if (localesPeticion.exito == 1){
          this.localesEjec = localesPeticion.ptos_vta
          if (opcion == 2){
            let unLocal = this.localesEjec.filter(element => element.local_accesorios == local_dependiente)
            if (unLocal.length > 0) this.selectedLocalEjec = unLocal[0]
            else{
              this.$store.dispatch('show_snackbar', {
                text: 'No se pudo determinar el Local dependiente del punto de venta.',
                color: 'warning'
              })
            }
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: localesPeticion.error + ': ' + localesPeticion.message,
            color: 'error'
          })
        }
      }
    },
    setCajaNombre(){
      this.cajaNombre = ''
      if (this.localNombre != null && this.localNombre != undefined && this.localNombre.toString().length > 0) this.cajaNombre = this.localNombre
      // foco en codigo postal
      this.$refs.cajaRef.focus()
    },
    verificarObligatorios(){
      if (Object.keys(this.selectedEmpresa).length == 0) return {valido: false, msj: 'Debe seleccionar la Empresa.'}
      if (Object.keys(this.selectedSucursal).length == 0) return {valido: false, msj: 'Debe seleccionar la Sucursal.'}
      if (Object.keys(this.selectedTipoLocal).length == 0) return {valido: false, msj: 'Debe seleccionar el Tipo de Local.'}
      if (this.localNombre == null || this.localNombre == undefined || this.localNombre.toString().length == 0) return {valido: false, msj: 'Debe ingresar el nombre del Local.'}
      if (Object.keys(this.selectedCajaPadre).length == 0 && this.selectedTipoLocal.usa_caja == 1) return {valido: false, msj: 'Debe seleccionar la Caja Padre.'}
      if (this.cajaNombre == null || this.cajaNombre == undefined || this.cajaNombre.toString().length == 0 && this.selectedTipoLocal.usa_caja == 1) return {valido: false, msj: 'Debe ingresar el nombre de la Caja.'}
      if (this.codPostal == null || this.codPostal == undefined || this.codPostal.toString().length == 0) return {valido: false, msj: 'Debe ingresar el código postal.'}
      if (Object.keys(this.selectedLocalidad).length == 0) return {valido: false, msj: 'Debe seleccionar la Localidad del Local.'}
      if (this.domicilio == null || this.domicilio == undefined || this.domicilio.toString().length == 0) return {valido: false, msj: 'Debe ingresar el domicilio.'}
      if (this.supVenta == null || this.supVenta == undefined || this.supVenta.toString().length == 0) return {valido: false, msj: 'Debe ingresar la superficie de venta.'}
      if (this.supTotal == null || this.supTotal == undefined || this.supTotal.toString().length == 0) return {valido: false, msj: 'Debe ingresar la superficie total.'}
      if (Object.keys(this.selectedListaVenta).length == 0 && this.param.ver == 1) return {valido: false, msj: 'Debe seleccionar la Lista de Precios para la Venta.'}
      if (this.selectedEmpresa.terceros == 1){
        if (Object.keys(this.selectedCliente).length == 0) return {valido: false, msj: 'Debe seleccionar el Cliente.'}
        if (Object.keys(this.selectedEjecutivo).length == 0) return {valido: false, msj: 'Debe seleccionar el Vendedor.'}
        if (Object.keys(this.selectedLocalEjec).length == 0) return {valido: false, msj: 'Debe seleccionar el Local Dependiente.'}
        if (Object.keys(this.selectedListaCompra).length == 0) return {valido: false, msj: 'Debe seleccionar una de Lista de Precios para la Compra.'}
      }
      return {valido: true, msj: 'OK'}
    },
    async altaLocal(){
      let valido = this.verificarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning'
        })
        return
      }
      // a grabar
      let body = {
        empresa_codigo: this.selectedEmpresa.id,
        terceros: this.selectedEmpresa.terceros,
        sucursal_codigo: this.selectedSucursal.id,
        habilitado: this.habilitado == true ? 1 : 0,
        categoria_codigo: this.selectedTipoLocal.categoria_codigo,
        local_nombre: this.localNombre,
        caja_padre: Object.keys(this.selectedCajaPadre).length == 0 ? 0 : this.selectedCajaPadre.caja_numero,
        caja_nombre: this.cajaNombre,
        codigo_postal: this.codPostal,
        localidad_codigo: this.selectedLocalidad.id,
        domicilio: this.domicilio,
        superficie_venta: this.supVenta,
        superficie_total: this.supTotal,
        cobro_electronico: this.cobroElectronico == true ? 1 : 0,
        impresora_termica: this.impresoraTermica == true ? 1 : 0,
        fp_integrado: this.laposIntegrado == true ? 1 : 0,
        fp_desintegrado: this.laposDesintegrado == true ? 1 : 0,
        terminal_x_venta: this.unaTerminalVenta == true ? 1 : 0,
        mayorista_salon: this.mayoristaSalon ? 1 : 0,
        caja_diaria: this.cajaDiaria == true ? 1 : 0,
        lista_venta: Object.keys(this.selectedListaVenta).length == 0 ? 0 : this.selectedListaVenta.lista_codigo,
        cliente_codigo: Object.keys(this.selectedCliente).length == 0 ? 0 : this.selectedCliente.cliente_codigo,
        vendedor_codigo: Object.keys(this.selectedEjecutivo).length == 0 ? 0 : this.selectedEjecutivo.vend_acc_codigo,
        local_dep_codigo: Object.keys(this.selectedLocalEjec).length == 0 ? 0 : this.selectedLocalEjec.local_accesorios,
        lista_compra: Object.keys(this.selectedListaCompra).length == 0 ? 0 : this.selectedListaCompra.lista_codigo
      }
      this.$store.state.loading = true
      let grabarPeticion = await this.$store.dispatch('localesStore/nuevoLocal', body)
      this.$store.state.loading = false
      if (grabarPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El local fue creado con éxito',
          color: 'success'
        })
        this.$emit('executeBuscar')
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: grabarPeticion.msj,
          color: 'error'
        })
      }
    },
    async modificarLocal(){
      let valido = this.verificarObligatorios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning'
        })
        return
      }
      // a grabar
      let body = {
        empresa_codigo: this.selectedEmpresa.id,
        terceros: this.selectedEmpresa.terceros,
        sucursal_codigo: this.selectedSucursal.id,
        habilitado: this.habilitado == true ? 1 : 0,
        categoria_codigo: this.selectedTipoLocal.categoria_codigo,
        local_nombre: this.localNombre,
        caja_padre: Object.keys(this.selectedCajaPadre).length == 0 ? 0 : this.selectedCajaPadre.caja_numero,
        caja_nombre: this.cajaNombre,
        codigo_postal: this.codPostal,
        localidad_codigo: this.selectedLocalidad.id,
        domicilio: this.domicilio,
        superficie_venta: this.supVenta,
        superficie_total: this.supTotal,
        cobro_electronico: this.cobroElectronico == true ? 1 : 0,
        impresora_termica: this.impresoraTermica == true ? 1 : 0,
        fp_integrado: this.laposIntegrado == true ? 1 : 0,
        fp_desintegrado: this.laposDesintegrado == true ? 1 : 0,
        terminal_x_venta: this.unaTerminalVenta == true ? 1 : 0,
        caja_diaria: this.cajaDiaria == true ? 1 : 0,
        mayorista_salon: this.mayoristaSalon == true ? 1 : 0,
        lista_venta: Object.keys(this.selectedListaVenta).length == 0 ? 0 : this.selectedListaVenta.lista_codigo,
        cliente_codigo: Object.keys(this.selectedCliente).length == 0 ? 0 : this.selectedCliente.cliente_codigo,
        vendedor_codigo: Object.keys(this.selectedEjecutivo).length == 0 ? 0 : this.selectedEjecutivo.vend_acc_codigo,
        local_dep_codigo: Object.keys(this.selectedLocalEjec).length == 0 ? 0 : this.selectedLocalEjec.local_accesorios,
        lista_compra: Object.keys(this.selectedListaCompra).length == 0 ? 0 : this.selectedListaCompra.lista_codigo,
        pto_vta_codigo: this.param.local.pto_vta_codigo
      }
      this.load = true
      let updatePeticion = await this.$store.dispatch('localesStore/modificarLocal', body)
      this.load = false
      if (updatePeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'El local fue modificado con éxito.',
          color: 'success'
        })
        this.$emit('executeBuscar')
        this.activo = false
      }else{
        this.$store.dispatch('show_snackbar', {
          text: updatePeticion.msj,
          color: 'error'
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    },
    habilitado: function(){
      if (this.habilitado == false) this.habilitadoNom = 'Habilitado'
      else this.habilitadoNom = 'Habilitado'
    },
    localidades: function(){
      if (this.localidades.length == 1) this.selectedLocalidad = this.localidades[0]
    },
    localesEjec: function(){
      if (this.localesEjec.length == 1) this.selectedLocalEjec = this.localesEjec[0]
    }
  },
}
</script>

<style>

</style>