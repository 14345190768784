<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Cabecera -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-book</v-icon>
                Libro Iva
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Generar Archivos -->
            <v-expansion-panel-content class="pt-4">
              <v-form @submit.prevent="buscar">
                <!-- Empresa, Sucursal y Local -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresas"
                      item-text="nombre_corto"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      multiple
                      chips
                      return-object
                      @change="setLocales()"
                      clearable
                    >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click:close="quitarEmpresa(data.item)"
                      >
                        {{ data.item.nombre_corto }}
                      </v-chip>
                    </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursales"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      multiple
                      chips
                      return-object
                      @change="setLocales()"
                      clearable
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click:close="quitarSucursal(data.item)"
                        >
                          {{ data.item.nombre }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    Local
                    <v-autocomplete
                      v-model="selectedLocales"
                      item-text="nombre"
                      :items="locales"
                      hide-details
                      outlined
                      dense
                      multiple
                      chips
                      return-object
                      clearable
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click:close="quitarLocal(data.item)"
                        >
                          {{ data.item.nombre }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <!-- Fecha Desde, Hasta y Botón -->
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-6">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Fecha Desde
                    <FechaPicker
                      v-model="fechaDesde"
                    />
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Fecha Hasta
                    <FechaPicker
                      v-model="fechaHasta"
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="iva105 == true ? headers : headerss"
          :items="ventas"
          :loading="load"
          dense
          :search="search"
        >
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <!-- Buscador y Botones descargar -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="9" md="10" class="d-flex justify-start align-center">
                <v-btn
                  class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                  title="Descargar Excel"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="!$vuetify.breakpoint.xs"
                  :disabled="ventas.length == 0"
                  @click="descargarExcel()"
                >
                  Excel
                  <v-icon
                    color="green darken-3"
                    :small="$vuetify.breakpoint.xs"
                    right
                  >
                    fas fa-file-download
                  </v-icon>
                </v-btn>
                <v-btn
                  class="mr-2 my-sm-0 my-1 py-sm-0 py-3"
                  title="Descargar PDF"
                  :x-small="$vuetify.breakpoint.xs"
                  :small="!$vuetify.breakpoint.xs"
                  :disabled="ventas.length == 0"
                  @click="generarLibroIvaPdf()"
                >
                  PDF
                  <v-icon
                    color="error"
                    :small="$vuetify.breakpoint.xs"
                    right
                  >
                    fas fa-file-pdf
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  hide-details
                  single-line
                  outlined
                  dense
                >
                  <template v-slot:append>
                    <v-icon
                      class="mt-1"
                      title="Buscar"
                      small
                    >
                      fas fa-search
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              type="warning"
              border="left"
              dense
              text
            >
              No hay ventas filtradas
            </v-alert>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="descargarFactura(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar Factura</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal ver PDF -->
    <PDFViewer 
      v-model="dialogPdf"
      :pdf="pdf"
      :nombre="nombreVer"
      :titulo="nombreVer"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import { convertDecimals, format_money } from '../../util/utils'
import { libroIvaPdf } from '../../util/plantillas/pdfs'
import download from 'downloadjs'
import moment from 'moment'
import PDFViewer from '../../util/plantillas/PDFViewer.vue'
export default {
  data() {
    return {
      panel: 0,
      selectedEmpresas: [],
      empresas: [],
      selectedSucursales: [],
      sucursales: [],
      selectedLocales: [],
      locales: [],
      localesAux: [],
      fechaDesde: moment(new Date()).format('DD/MM/YYYY'),
      fechaHasta: moment(new Date()).format('DD/MM/YYYY'),
      ventas: [],
      headersExcel: {
        'Fecha': 'fecha',
        'Referencia': 'referencia',
        'Cliente': 'cliente',
        'Cuit': 'cuit',
        'Total': 'total',
        'No Grab.': 'no_grabado',
        'Iva 21': 'iva_21',
        'Neto 21': 'neto_21',
        'Retenciones': 'retenciones'
      },
      headers: [
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Referencia', align: 'center', value: 'referencia' },
        { text: 'Cliente', align: 'center', value: 'cliente' },
        { text: 'Cuit', align: 'center', value: 'cuit' },
        { text: 'Total', align: 'center', value: 'total', formatter: format_money },
        { text: 'No Grab.', align: 'center', value: 'no_grabado', formatter: format_money },
        { text: 'Iva 105', align: 'center', value: 'iva_105', formatter: format_money },
        { text: 'Neto 105', align: 'center', value: 'neto_105', formatter: format_money },
        { text: 'Iva 21', align: 'center', value: 'iva_21', formatter: format_money },
        { text: 'Neto 21', align: 'center', value: 'neto_21', formatter: format_money },
        { text: 'Retenciones', align: 'center', value: 'retenciones', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Referencia', align: 'center', value: 'referencia' },
        { text: 'Cliente', align: 'center', value: 'cliente' },
        { text: 'Cuit', align: 'center', value: 'cuit' },
        { text: 'Total', align: 'center', value: 'total', formatter: format_money },
        { text: 'No Grab.', align: 'center', value: 'no_grabado', formatter: format_money },
        { text: 'Iva 21', align: 'center', value: 'iva_21', formatter: format_money },
        { text: 'Neto 21', align: 'center', value: 'neto_21', formatter: format_money },
        { text: 'Retenciones', align: 'center', value: 'retenciones', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: '',
      iva105: false,
      pdf: '',
      dialogPdf: false,
      nombreVer: '',
      fileXls: null
    }
  },
  components: {
    FechaPicker,
    BtnConfirmar,
    BtnFiltro,
    SearchDataTable,
    PDFViewer
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      // sucursales
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      // obtengo las empresas
      let empresasPeticion = await this.$store.dispatch('ventas/getEmpresasLibroIva')
      if (empresasPeticion.resultado == 0){
        this.$store.state.loading = false
        this.$store.dispatch('show_snackbar', {
          text: empresasPeticion.msj,
          color: 'error',
        })
        return
      }
      this.empresas = empresasPeticion.empresas
      // obtengo los locales
      let localesPeticion = await this.$store.dispatch('ventas/getLocalesLibroIva')
      this.$store.state.loading = false
      if (localesPeticion.resultado == 1){
        this.localesAux = localesPeticion.locales
        this.locales = localesPeticion.locales
      }else{
        this.$store.dispatch('show_snackbar', {
          text: localesPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedEmpresas = []
      this.selectedSucursales = []
      this.selectedLocales = []
      this.locales = []
      this.fechaDesde = moment(new Date()).format('DD/MM/YYYY')
      this.fechaHasta = moment(new Date()).format('DD/MM/YYYY')
    },
    formValido(){
      if (this.selectedEmpresas.length == 0){
        return {msj: 'Debe seleccionar la Empresa.', valido: false}
      }
      if (moment(this.fechaDesde, "DD/MM/YYYY") > moment(this.fechaHasta, "DD/MM/YYYY")){
        return {msj: 'La fecha desde no puede ser mayor a la fecha hasta.', valido: false}
      }
      return {msj: 'OK', valido: true}
    },
    setLocales(){
      // limpio los locales
      this.selectedLocales = []
      this.locales = []
      if (this.selectedEmpresas.length > 0 && this.selectedSucursales.length > 0){
        let localesUsuario = []
        // primero filtro por empresas seleccionadas
        for (let id in this.selectedEmpresas){
          let localesEmp = this.localesAux.filter(element => element.empresa == this.selectedEmpresas[id].id)
          if (localesEmp.length > 0){
            for (let jd in localesEmp){
              if (localesUsuario.indexOf(localesEmp[jd]) == -1){
                localesUsuario.push(localesEmp[jd])
              }
            }
          }
        }
        // luego filtro por locales seleccionados
        if (localesUsuario.length > 0){
          for (let id in this.selectedSucursales){
            let localesSuc = localesUsuario.filter(element => element.sucursal == this.selectedSucursales[id].id)
            if (localesSuc.length > 0){
              for (let jd in localesSuc){
                if (this.locales.indexOf(localesSuc[jd]) == -1){
                  this.locales.push(localesSuc[jd])
                }
              }
            }
          }
        }
      }else{
        this.locales = this.localesAux
      }
    },
    descargarExcel(){
      download(this.fileXls, 'LibroIva.xlsx', 'application/vnd.ms-excel')
    },
    async buscar(){
      let valido = this.formValido()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.ventas = []
      this.iva105 = false
      this.load = true
      this.pdf = ''
      this.nombreVer = ''
      this.fileXls = null
      let ventasPeticion = await this.$store.dispatch('ventas/getVentasLibroIva', {
        fecha_desde: this.fechaDesde,
        fecha_hasta: this.fechaHasta,
        empresas: this.selectedEmpresas,
        sucursales: this.selectedSucursales,
        locales: this.selectedLocales
      })
      this.load = false
      if (ventasPeticion.resultado == 1){
        this.ventas = ventasPeticion.ventas
        // guardo el archivo excel listo para descargar
        this.fileXls = ventasPeticion.base64
        // llego iva y neto del 10,5?
        let neto105 = this.ventas.filter(element => convertDecimals(element.neto_105) > 0)
        if (neto105.length > 0){
          this.iva105 = true
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: ventasPeticion.msj,
          color: 'error',
        })
      }
    },
    async generarLibroIvaPdf(){
      // ordeno el listado de empresas ascendente por id de empresa
      this.$store.state.loading = true
      this.selectedEmpresas.sort(function(a,b){
        if (parseInt(a.id) > parseInt(b.id)) {
          return 1;
        }
        if (parseInt(a.id) < parseInt(b.id)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      })
      let reportePeticion = await libroIvaPdf({
        fecha_desde: this.fechaDesde,
        fecha_hasta: this.fechaHasta,
        empresa: this.selectedEmpresas[0],
        ventas: this.ventas
      })
      this.$store.state.loading = false
      if (reportePeticion.resultado == 1){
        this.nombreVer = 'Libro Iva'
        this.pdf = reportePeticion.file
        this.dialogPdf = true
      }else{
        this.$store.dispatch('show_snackbar', {
          text: reportePeticion.msj,
          color: 'error',
        })
      }
    },
    async descargarFactura(item){
      this.$store.state.loading = true
      let url = 'http://200.45.250.4:8800/bb_web/public/comprobantepdf/'
      // obtengo el id de caja
      let idCajaPeticion = await this.$store.dispatch('ventas/getComprobanteCajaId', {venta_id: item.venta_id})
      this.$store.state.loading = false
      if (idCajaPeticion.resultado == 1){
        if (idCajaPeticion.comprobante.length > 0){
          url = url + idCajaPeticion.comprobante[0].comprobante_caja_id
          window.open(url)
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontró la factura solicitada o es muy antigua.',
            color: 'warning',
          })
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: idCajaPeticion.msj,
          color: 'error',
        })
      }
    },
    quitarEmpresa(item){
      let pos = this.selectedEmpresas.indexOf(item)
      this.selectedEmpresas.splice(pos, 1)
      this.setLocales()
    },
    quitarSucursal(item){
      let pos = this.selectedSucursales.indexOf(item)
      this.selectedSucursales.splice(pos, 1)
      this.setLocales()
    },
    quitarLocal(item){
      let pos = this.selectedLocales.indexOf(item)
      this.selectedLocales.splice(pos, 1)
    }
  },
  watch: {
    ventas: function(){
      if (this.ventas.length > 0){
        for (let id in this.ventas){
          this.ventas[id].fecha = moment(this.ventas[id].fecha).format("DD/MM/YYYY")
          this.ventas[id].referencia = this.ventas[id].abreviatura + ' ' + this.ventas[id].emision + '-' + this.ventas[id].numero.toString().padStart(8, '0')
        }
      }
    }
  },
}
</script>

<style>

</style>