<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="40%"
      persistent
    >
      <v-card
        flat
      >
        <v-card-title>
          Sucursales Destino
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div v-if="parseInt(cantidad) - parseInt(asignados) >= 0"><strong><p class="text-center">Total a Asignar: {{ cantidad }} - Total Asignados: {{ asignados }} - Pendientes de Asignar: {{ Math.abs(parseInt(cantidad) - parseInt(asignados)) }}</p></strong></div>
              <div v-else><strong><p class="text-center">Total a Asignar: {{ cantidad }} - Total Asignados: {{ asignados }} - Total a Quitar: {{ Math.abs(parseInt(cantidad) - parseInt(asignados)) }}</p></strong></div>
            </v-col>
            <v-col cols="12">
              <v-data-table
                class="cebra elevation-2 mt-2"
                :headers="headers"
                :items="destinos"
                :loading="load"
                dense
                hide-default-footer
                disable-pagination
                :search="search"
                :sort-by.sync="sortBy"
              >
                <!-- Msj que se mostrara si no existen resultados -->
                <template v-slot:no-data>
                  <v-alert
                    class="mx-auto mt-4"
                    type="warning"
                    border="left"
                    dense
                    text
                  >
                    Sin destinos para mostrar.
                  </v-alert>
                </template>
                <!-- Cantidad -->
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-row class="d-flex justify-center" no-gutters>
                    <v-text-field 
                      v-model="item.cantidad" 
                      :id="cantRef"
                      :ref="cantRef"
                      type="number" 
                      outlined dense 
                      style="width: 50px" 
                      hide-details
                      :disabled="ver == 1"
                      @blur="setDestino(item)"
                      @keypress.native.enter="$refs[cantRef].blur()"></v-text-field>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end mx-2">
          <v-btn color="warning" class="mr-2" @click="activo = false" v-if="(parseInt(cantidad) - parseInt(asignados) == 0)">Volver</v-btn>
          <v-btn color="success" class="mr-2" @click="confirmarDestinos()" v-if="(parseInt(cantidad) - parseInt(asignados) == 0) && (nuevo == 1 || ver == 0)">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalIngDestinos', value)
      }
    },
    sucursales: {
      get(){
        return this.datos.sucursales
      }
    },
    cantidad: {
      get(){
        return this.datos.cantidad
      }
    },
    nuevo: {
      get(){
        return this.datos.nuevo
      }
    },
    ver: {
      get(){
        return this.datos.ver
      }
    },
    destinos_actuales: {
      get(){
        return this.datos.destinos_actuales
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Destino', align: 'left', value: 'sucursal_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' }
      ],
      destinos: [],
      load: false,
      search: '',
      sortBy: 'sucursal_nombre',
      asignados: 0,
      cantRef: 'cantRef'
    }
  },
  methods: {
    initModal(){
      // tengo destinos actuales?
      if (this.destinos_actuales.length > 0){
        this.destinos = this.destinos_actuales
        // me quedo unicamente con los detalles que tienen cantidad mayor a 0
        let mayorCero = this.destinos.filter(element => parseInt(element.cantidad) > 0)
        let acumulador = 0
        for (let id in mayorCero){
          acumulador = acumulador + parseInt(mayorCero[id].cantidad)
        }
        // asigno mi nuevo asignados
        this.asignados = acumulador
        // estan destinando más de lo que corresponde?
        if (parseInt(acumulador) > parseInt(this.cantidad)){
          this.$store.dispatch('show_snackbar', {
            text: 'No puede asignar más artículos de los que tiene disponible.',
            color: 'warning',
          })
          return
        }
      }else{
        for (let id in this.sucursales){
          let unDestino = {
            sucursal_codigo: this.sucursales[id].id,
            sucursal_nombre: this.sucursales[id].nombre,
            cantidad: 0
          }
          this.destinos.push(unDestino)
        }
      }
    },
    limpiarModal(){
      this.search = ''
      this.destinos = []
      this.asignados = 0
    },
    setDestino(item){
      // obtengo la posicion
      let pos = this.destinos.indexOf(item)
      if (pos == -1){
        this.$store.dispatch('show_snackbar', {
          text: 'No se pudo obtener la posición del registro en el listado. No se puede continuar.',
          color: 'warning',
        })
        return
      }
      // me quedo unicamente con los detalles que tienen cantidad mayor a 0
      let mayorCero = this.destinos.filter(element => parseInt(element.cantidad) > 0)
      let acumulador = 0
      for (let id in mayorCero){
        acumulador = acumulador + parseInt(mayorCero[id].cantidad)
      }
      // estan destinando más de lo que corresponde?
      if (parseInt(acumulador) > parseInt(this.cantidad)){
        this.$store.dispatch('show_snackbar', {
          text: 'No puede asignar más artículos de los que tiene disponible.',
          color: 'warning',
        })
        // pongo en 0 la cantidad del registro que tocaron
        this.destinos[pos].cantidad = 0
        return
      }
      // si esta todo ok entonces asigno mi nuevo asignados
      this.asignados = acumulador
    },
    confirmarDestinos(){
      if(this.destinos_actuales.length == 0) this.$emit('setearDestinos', this.destinos)
      else this.$emit('updateDestinos', this.destinos)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == true) this.initModal()
      else this.limpiarModal()
    }
  },
}
</script>

<style>

</style>