<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- Cabecera -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Boton Agregar nueva Relación -->
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="nuevoConceptoProveedor()"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <!-- Titulo Filtros -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtros
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Contenido Filtros -->
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="selectedEmpresa"
                    item-text="nombre_corto"
                    :items="empresas"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                    @change="getProveedores()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Proveedor
                  <v-autocomplete
                    v-model="selectedProveedor"
                    item-text="proveedor_nombre"
                    :items="proveedores"
                    hide-details
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="estado"
                    label="Habilitado"
                    class="mb-md-2"
                    tabindex="1"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Listado -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="listadoConceptos"
        :loading="load"
        dense
        :search="search"
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <!-- Acciones y modal para confirmar -->
        <template v-slot:[`item.estado`]="{ item }">
          {{ estadoConcepto(item.estado) }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <BtnConfirmar
            :icono="item.estado == 1 ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.estado == 1 ? 'success' : 'error'"
            :title="item.estado == 1 ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.estado == 0
              ? `¿Desea inhabilitar el concepto <strong>${item.concepto_nombre}</strong> para el proveedor <strong>${item.proveedor_nombre}</strong>?`
              : `¿Desea habilitar el concepto <strong>${item.concepto_nombre}</strong> para el proveedor <strong>${item.proveedor_nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="actualizarEstado(item)"
          />
        </template>
        <!-- Msj que se mostrara si no existen resultados -->
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- Modal cambio de Estado -->
      <ModalNewCxP
        :activo="modalCxP"
        :empresasN="empresas"
        @cerrarModalCxP="closeModalCxP"
      />
    </v-col>
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import ModalNewCxP from '../../components/bejerman/ModalNewConcepProveed.vue'
export default {
  data() {
    return {
      panel: 0,
      selectedEmpresa: {},
      empresas: [],
      selectedProveedor: {},
      proveedores: [],
      load: false,
      listadoConceptos: [],
      headers: [
        { text: 'Proveedor', align: 'center', value: 'proveedor_nombre' },
        { text: 'Concepto Codigo', align: 'center', value: 'concepto_codigo' },
        { text: 'Concepto Nombre', align: 'center', value: 'concepto_nombre' },
        { text: 'Estado', align: 'center', value: 'estado' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      search: '',
      estado: true,
      loadingCambioEstado: false,
      modalCxP: false
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    BtnConfirmar,
    ModalNewCxP
  },
  computed: {
    
  },
  created () {
    this.iniciarFormulario()
  },
  methods: {
    iniciarFormulario(){
      this.$store.state.loading = true
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.$store.state.loading = false
    },
    estadoConcepto(estado){
      if (parseInt(estado) == 0) return 'Activo'
      else return 'No Activo'
    },
    async getProveedores(){
      if (typeof this.selectedEmpresa == 'object' && this.selectedEmpresa != null){
        this.$store.state.loading = true
        if (Object.keys(this.selectedEmpresa).length == 0){
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Empresa.',
            color: 'warning',
          })
          return
        }
        this.selectedProveedor = {}
        this.proveedores = []
        await this.$store.dispatch('bejerman/get_proveedores_bejerman', {empresa_codigo: this.selectedEmpresa.id})
          .then(response => {
            let respuesta = response
            if (respuesta.resultado == 1){
              // peticion exitosa
              this.proveedores = respuesta.proveedoresBejerman
              this.$store.state.loading = false
            }else{
              // error
              this.$store.state.loading = false
              this.$store.dispatch('show_snackbar', {
                text: respuesta.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.state.loading = false
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un problema inesperado al correr el metodo getProveedores: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    limpiar(){
      this.selectedEmpresa = {}
      this.selectedProveedor = {}
    },
    async buscar(){
      if (typeof this.selectedEmpresa == 'object' && this.selectedEmpresa != null){
        this.search = ''
        this.load = true
        this.listadoConceptos = []
        if (Object.keys(this.selectedEmpresa).length == 0){
          this.load = false
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Empresa.',
            color: 'warning',
          })
          return
        }
        let provCodigo = 0
        if (Object.keys(this.selectedProveedor).length > 0){
          provCodigo = this.selectedProveedor.proveedor_codigo
        }
        let estConcepto = 0
        if (this.estado == false){
          estConcepto = 1
        }
        await this.$store.dispatch('bejerman/get_proveedores_conceptos', {empresa_codigo: this.selectedEmpresa.id, proveedor_codigo: provCodigo, estado: estConcepto})
          .then(response => {
            let respuesta = response
            if (respuesta.resultado == 1){
              this.listadoConceptos = respuesta.conceptosProv
              this.load = false
            }else{
              this.load = false
              this.$store.dispatch('show_snackbar', {
                text: respuesta.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.load = false
            this.$store.dispatch('show_snackbar', {
              text: 'Ocurrio un problema inesperado al correr el metodo buscar: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    async actualizarEstado(item){
      this.$store.state.loading = true
      let estado = 0
      if (item.estado == 0){
        estado = 1
      }
      await this.$store.dispatch('bejerman/update_estado_concepto_proveedor', {empresa_codigo: this.selectedEmpresa.id, proveedor_codigo: item.proveedor_codigo, concepto_codigo: item.concepto_codigo, estado: estado})
        .then(response => {
          this.$store.state.loading = false
          let respuesta = response
          if (respuesta.resultado){
            // todo ok
            let pos = this.listadoConceptos.indexOf(item)
            this.listadoConceptos[pos].estado = parseInt(estado)
            this.$store.dispatch('show_snackbar', {
              text: 'Actualización terminada con Éxito.',
              color: 'success',
            })
            return
          }else{
            this.$store.dispatch('show_snackbar', {
              text: respuesta.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'Ocurrio un problema inesperado al correr el metodo actualizarEstado: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    nuevoConceptoProveedor(){
      // abro el modal
      this.modalCxP = true
    },
    closeModalCxP(){
      this.modalCxP = false
    }
  },
  watch: {
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined){
        this.selectedEmpresa = {}
        this.proveedores = []
      }
    },
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
    }
  },
}
</script>

<style>

</style>