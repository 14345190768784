<template>
  <v-dialog
    v-model="activo"
    max-width="50%"
  >
    <v-card
      flat
    >
      <!-- Titulo -->
      <v-card-title
        class="pt-0"
        :class="$vuetify.breakpoint.smAndDown ? 'd-flex justify-center' : ''"
      >
        Seleccione un Vendedor
        <v-spacer></v-spacer>
        <v-btn icon><v-icon @click="activo = false" title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="cebra elevation-2 mt-2"
              :headers="headers"
              :items="listado_vendedores"
              :loading="load"
              dense
              :search="search"
            >
              <!-- Buscador en el datatable -->
              <template v-slot:top>
                <v-row class="d-flex justify-end pa-2" no-gutters>
                  <v-col cols="6" sm="6">
                    <SearchDataTable
                      v-model="search"
                    />
                  </v-col>
                </v-row>
              </template>
              <!-- Msj que se mostrara si no existen resultados -->
              <template v-slot:no-data>
                <v-alert
                  class="mx-auto mt-4"
                  type="warning"
                  border="left"
                  dense
                  text
                >
                  Sin Vendedores para Seleccionar.
                </v-alert>
              </template>
              <!-- Acciones -->
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon color="success" @click="selectVendedor(item)" v-on="on">
                      <v-icon small>fas fa-check</v-icon>
                    </v-btn>
                  </template>
                  <span>Seleccionar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTable from '../../util/SearchDataTable.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    }
  },
  components: {
    SearchDataTable,
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalSelectVend', value)
      }
    },
    listado_vendedores: {
      get() {
        return this.datos.listado_vendedores
      }
    }
  },
  data() {
    return {
      headers: [
        { text: 'Código', align: 'center', value: 'vendedor_codigo' },
        { text: 'Nombre', align: 'center', value: 'vendedor_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      load: false,
      search: ''
    }
  },
  methods: {
    selectVendedor(item){
      this.$emit('setearVendedorSelect', item)
      this.activo = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.load = false
        this.search = ''
      }
    }
  },
}
</script>

<style>

</style>