<template>
  <div>
    <v-dialog
      v-model="activo"
      max-width="40%"
    >
      <v-card
        flat
      >
        <v-card-title>
          <v-icon class="ma-2">fas fa-calendar-week</v-icon> Modificar Fecha de la Orden de Compra {{ orden_id }}
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12" sm="6" md="6" class="py-1">
              <v-text-field
                v-model="fecha"
                outlined
                dense
                type="text"
                hide-details
                label="Fecha Actual"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="py-1">
              <FechaPicker
                v-model="nuevaFecha"
                label="Nueva Fecha"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" class="ma-2" @click="activo = false">Volver</v-btn>
          <BtnConfirmar
            :texto="`¿Está seguro de cambiar la fecha de la Orden de Compra ${orden_id}?`"
            @action="cambiarFecha()"
            color="success"
            nombre="Cambiar Fecha"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FechaPicker from '../util/FechaPicker.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
  },
  computed: {
    activo: {
      get(){
        return this.datos.activo
      },
      set(value){
        this.$emit('setearModalModFechaOC', value)
      }
    },
    orden_id: {
      get(){
        return this.datos.orden_id
      }
    },
    fecha: {
      get(){
        return this.datos.fecha
      }
    }
  },
  components: {
    FechaPicker,
    BtnConfirmar
  },
  data() {
    return {
      nuevaFecha: ''
    }
  },
  methods: {
    limpiarModal(){
      this.nuevaFecha = ''
    },
    async cambiarFecha(){
      if (this.nuevaFecha == null || this.nuevaFecha == undefined || this.nuevaFecha.toString().length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar el valor de la nueva Fecha para continuar.',
          color: 'info',
        })
        return
      }
      if (this.fecha.toString() == this.nuevaFecha.toString().length){
        this.$store.dispatch('show_snackbar', {
          text: 'La nueva Fecha debe ser distinta a la que tiene actualmente.',
          color: 'info',
        })
        return
      }
      this.$store.state.loading = true
      let cambioPeticion = await this.$store.dispatch('ordenesCompra/cambiarFechaOC', {
        fecha: this.nuevaFecha,
        orden_id: this.orden_id
      })
      this.$store.state.loading = false
      if (cambioPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'La Fecha de la Orden de Compra fué actualizada con éxito.',
          color: 'success',
        })
        this.activo = false
        this.$emit('actionSuccess')
      }else{
        this.$store.dispatch('show_snackbar', {
          text: cambioPeticion.msj,
          color: 'error',
        })
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarModal()
    }
  },
}
</script>

<style>

</style>