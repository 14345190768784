<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <!-- Titulo -->
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-folder-minus</v-icon>
              Anular Facturas Manuales
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <!-- Campos -->
          <v-expansion-panel-content class="pt-2">
            <!-- Pto Vta manual, Num desde, Num Hasta, Fecha -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="2" md="2" class="py-1">
                Pto. Vta. Manual
                <v-text-field
                  v-model="ptoVtaManual"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Tipo Factura
                <v-autocomplete
                  v-model="selectedTipoFactura"
                  item-text="tipo_nombre"
                  :items="tiposFacturas"
                  hide-details
                  outlined
                  dense
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                Num. Fac. Desde
                <v-text-field
                  v-model="numFacDesde"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                Num. Fac. Hasta
                <v-text-field
                  v-model="numFacHasta"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                Fecha
                <FechaPicker
                  v-model="fecha"
                />
              </v-col>
            </v-row>
            <!-- Empresa, Sucursales, Local -->
            <v-row :no-gutters="$vuetify.breakpoint.xs" style="margin-top: -20px">
              <v-col cols="12" sm="4" md="4" class="py-1">
                Empresa
                <v-autocomplete
                  v-model="selectedEmpresa"
                  item-text="nombre_corto"
                  :items="empresas"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="setLocales()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Sucursales
                <v-autocomplete
                  v-model="selectedSucursal"
                  item-text="nombre"
                  :items="sucursales"
                  hide-details
                  outlined
                  dense
                  return-object
                  @change="setLocales()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="py-1">
                Local
                <v-autocomplete
                  v-model="selectedLocal"
                  item-text="nombre"
                  :items="locales"
                  hide-details
                  outlined
                  dense
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- Referencia, Botones -->
            <v-row :no-gutters="$vuetify.breakpoint.xs">
              <v-col cols="12" sm="10" md="10" class="py-1">
                Referencia
                <v-text-field
                  v-model="referencia"
                  outlined
                  dense
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="py-1">
                <BtnConfirmar
                  clase="mt-5"
                  icono="fas fa-ban"
                  color="success"
                  nombre="Anular"
                  texto="¿Está seguro de anular el punto de venta manual en el rango indicado?"
                  @action="anularFacturas()"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import moment from 'moment'
export default {
  data() {
    return {
      panel: 0,
      ptoVtaManual: '',
      numFacDesde: '',
      numFacHasta: '',
      empresas: [],
      selectedEmpresa: {},
      selectedSucursal: {},
      sucursales: [],
      selectedLocal: {},
      locales: [],
      localesAux: [],
      fecha: moment(new Date()).format('DD/MM/YYYY'),
      referencia: '',
      selectedTipoFactura: {},
      tiposFacturas: []
    }
  },
  components: {
    FechaPicker,
    BtnConfirmar
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.localesAux = JSON.parse(localStorage.getItem('locales'))
      this.$store.state.loading = true
      let tiposFactPeticion = await this.$store.dispatch('facturasCompra/get_fc_tipos_comprobantes')
      this.$store.state.loading = false
      this.tiposFacturas = []
      if (tiposFactPeticion.resultado == 1){
        for (let id in tiposFactPeticion.tiposComp){
          let unTipo = {
            tipo_id: tiposFactPeticion.tiposComp[id].tipo_id,
            tipo_nombre: tiposFactPeticion.tiposComp[id].tipo_nombre + ', LETRA ' + tiposFactPeticion.tiposComp[id].tipo_letra,
            comp_tipo_id: tiposFactPeticion.tiposComp[id].comp_tipo_id,
            tipo_letra: tiposFactPeticion.tiposComp[id].tipo_letra
          }
          this.tiposFacturas.push(unTipo)
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: tiposFactPeticion.msj,
          color: 'error',
        })
      }
    },
    setLocales(){
      this.selectedLocal = {}
      this.locales = []
      if (Object.keys(this.selectedEmpresa).length > 0 && Object.keys(this.selectedSucursal).length > 0){
        this.locales = this.localesAux.filter(element => element.empresa == this.selectedEmpresa.id && element.sucursal == this.selectedSucursal.id)
      }
    },
    verificarObligatorios(){
      if (this.ptoVtaManual == null || this.ptoVtaManual == undefined || this.ptoVtaManual.toString().length == 0){
        return {bandera: false, msj: 'Debe ingresar el punto de Venta Manual.'}
      }
      if (Object.keys(this.selectedTipoFactura).length == 0){
        return {bandera: false, msj: 'Debe ingresar el tipo de Factura a anular.'}
      }
      if (this.numFacDesde == null || this.numFacDesde == undefined || this.numFacDesde.toString().length == 0){
        return {bandera: false, msj: 'Debe ingresar desde qué Número de Factura anular.'}
      }
      if (this.numFacHasta == null || this.numFacHasta == undefined || this.numFacHasta.toString().length == 0){
        return {bandera: false, msj: 'Debe ingresar hasta qué Número de Factura anular.'}
      }
      // si llega hasta aqui, los numeros son no vacios, num desde es menor?
      if (parseInt(this.numFacDesde) > parseInt(this.numFacHasta)){
        return {bandera: false, msj: 'El campo "Num. Fac. Desde" no puede ser mayor que el campo "Num. Fac. Hasta"'}
      }
      if (Object.keys(this.selectedEmpresa).length == 0 || Object.keys(this.selectedSucursal).length == 0 || Object.keys(this.selectedLocal).length == 0){
        return {bandera: false, msj: 'Debe ingresar Empresa, Sucursal y Local para anular.'}
      }
      if (this.referencia == null || this.referencia == undefined || this.referencia.toString().length == 0){
        return {bandera: false, msj: 'Debe ingresar una referencia breve (motivo de cancelación de facturas).'}
      }
      return {bandera: true, msj: 'OK'}
    },
    async anularFacturas(){
      let obligatorios = this.verificarObligatorios()
      if (obligatorios.bandera == false){
        this.$store.dispatch('show_snackbar', {
          text: obligatorios.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let datos = {
        pto_vta_manual: this.ptoVtaManual,
        num_desde: this.numFacDesde,
        num_hasta: this.numFacHasta,
        fecha: this.fecha,
        empresa: this.selectedEmpresa.id,
        sucursal: this.selectedSucursal.id,
        local: this.selectedLocal.local_accesorios,
        referencia: this.referencia,
        tipo_comp: this.selectedTipoFactura.comp_tipo_id,
        tipo_letra: this.selectedTipoFactura.tipo_letra
      }
      let anularPeticion = await this.$store.dispatch('facturasAdmin/cancelarFacturasManuales', datos)
      this.$store.state.loading = false
      if (anularPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', {
          text: 'Las anulaciones se realizaron con éxito.',
          color: 'success',
        })
        setTimeout(() => [location.reload()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: anularPeticion.msj,
          color: 'error',
        })
      }
    }
  },
}
</script>

<style>

</style>