<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    scrollable
  >
    <v-card>
      <v-card-title>
        Terminales del local {{ local_nombre }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="">
        <v-data-table
          class="cebra elevation-2 mt-2"
          :loading="load"
          :items="terminales"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
        >
          <!-- boton de nuevo -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-btn
                color="success"
                small
                @click="nueva()"
              >
                <v-icon small left>fas fa-plus</v-icon>
                Nueva terminal
              </v-btn>
            </v-row>
          </template>
          <!-- campos editables -->
          <template v-slot:[`item.numero`]="{ item }">
            <v-text-field
              v-model.trim="item.numero"
              type="number"
              :ref="`itemNumero${item.id}`"
              hide-details
              dense
              @blur="cambiar_numero(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.alias`]="{ item }">
            <v-text-field
              v-model.trim="item.alias"
              hide-details
              dense
              @blur="cambiar_alias(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.activa`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.activa"
                hide-details
                dense
                @change="marcar_activa(item)"
              ></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.integrada`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.integrada"
                hide-details
                dense
                @change="marcar_integrada(item)"
              ></v-checkbox>
            </div>
          </template>
          <!-- acciones -->
          <template v-slot:[`item.actions`]="{ item }">
            <BtnConfirmar
              icono="fas fa-trash"
              color="error"
              title="Eliminar"
              :texto="`¿Desea eliminar la terminal Nº <strong>${item.numero}${item.alias ? ' - ' : ''}${item.alias}</strong>? (esta acción no se puede deshacer)`"
              :icon_button="true"
              :small="true"
              @action="eliminar_terminal(item)"
            />
          </template>
          <!-- no data -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              El local no tiene terminales asociadas
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="warning"
          @click="dialog = false"
        >
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../../components/util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      terminales: [],
      terminales_copy: [],
      headers: [
        { text: 'Número', value: 'numero',  align: 'right', width: '150', sortable: false, filterable: false },
        { text: 'Alias', value: 'alias', sortable: false, filterable: false },
        { text: 'Activa', value: 'activa', align: 'center', sortable: false, filterable: false },
        { text: 'Integrada', value: 'integrada', align: 'center', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ]
    }
  },
  props: {
    datos: {
      type: Object,
      default: {}
    }
  },
  computed: {
    dialog: {
      get () {
        return this.datos.activo
      },
      set (value) {
        this.$emit('setearModalTerminales', value)
      }
    },
    local_nombre: {
      get () {
        return this.datos.local.pto_vta_nombre
      }
    },
    local_codigo: {
      get () {
        return this.datos.local.pto_vta_codigo
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.terminales = []
        this.terminales_copy = []
        if (this.local_codigo) {
          this.load = true
          await this.$store.dispatch('localesStore/getTerminales', this.local_codigo)
            .then((res) => {
              this.terminales = res.data
              this.terminales_copy = JSON.parse(JSON.stringify(this.terminales))
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.load = false
        }
      }
    }
  },
  methods: {
    async nueva () {
      await this.terminales.push({
        id: 0,
        numero: null,
        activa: false,
        integrada: false,
        alias: ''
      })
      this.$refs[`itemNumero0`].focus()
    },
    async cambiar_numero (item) {
      // si el item tiene id esta editando, si no tiene esta creando
      // EDITAR
      if (item.id) {
        const copy = this.terminales_copy.find(t => t.id == item.id)
        if (!item.numero) {
          item.numero = copy.numero
          return this.$store.dispatch('show_snackbar', {
            text: 'El número no pude ser vacio',
            color: 'error'
          })
        }
        if (copy.numero != item.numero) {
          this.$store.state.loading = true
          await this.$store.dispatch('localesStore/cambiarNumeroTerminal', {
            codigo: item.id,
            numero: item.numero
          })
            .then((res) => {
              copy.numero = item.numero
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
            })
            .catch(error => {
              item.numero = copy.numero
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
          this.$store.state.loading = false
        }
      }
      // NUEVA
      else {
        const index = this.terminales.indexOf(item)
        if (!item.numero) {
          this.terminales.splice(index, 1)
          return this.$store.dispatch('show_snackbar', {
            text: 'El número no pude ser vacio',
            color: 'error'
          })
        }
        this.$store.state.loading = true
        await this.$store.dispatch('localesStore/nuevaTerminal', {
          numero: item.numero,
          pto_vta: this.local_codigo,
          inhabilitada: item.activa ? 0 : 1,
          integrada: item.integrada ? 1 : 0,
          nombre: item.alias
        })
          .then((res) => {
            item.id = res.id
            this.terminales_copy.push(item)
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            this.terminales.splice(index, 1)
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async cambiar_alias (item) {
      const copy = this.terminales_copy.find(t => t.id == item.id)
      if (copy.alias != item.alias) {
        this.$store.state.loading = true
        await this.$store.dispatch('localesStore/cambiarAliasTerminal', {
          codigo: item.id,
          nombre: item.alias
        })
          .then((res) => {
            copy.alias = item.alias
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })
          })
          .catch(error => {
            item.alias = copy.alias
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error'
            })
          })
        this.$store.state.loading = false
      }
    },
    async marcar_activa (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('localesStore/marcarEstadoTerminal', {
        codigo: item.id,
        inhabilitada: item.activa ? 0 : 1
      })
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          item.activa = !item.alias
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async marcar_integrada (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('localesStore/marcarModoTerminal', {
        codigo: item.id,
        integrada: item.integrada ? 1 : 0
      })
        .then((res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          item.integrada = !item.integrada
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    async eliminar_terminal (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('localesStore/eliminarTerminal', item.id)
        .then((res) => {
          const index = this.terminales.indexOf(item)
          this.terminales.splice(index, 1)
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }
  }
}
</script>